import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { API_CONFIG } from "../../config/config.jsx";
import Axios from 'axios';

const styles = theme => ({
  button: {
    marginLeft: 225,
    marginTop: "25px",
    marginBottom: "30px",
    backgroundColor: "#0088cc",
    color: "#ffffff",
    position: 'relative',
  },
  textField: {
    width: 300,
    marginTop: "8px",
    marginLeft: 125,
  },
  selectStyle: {
    width: 300,
    marginLeft: 125
  },
  grpDivStyle: {
    marginTop: "20px",
    marginLeft: 125
  },
});

class StoreGroupTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: '',
      groupDescription: '',
      parentGroupId: '',
      parentGroups: [],
      isClearable: true,
      open: true
    }
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  selectHandleChange = (event) => {
    if (event === null) {
      this.setState({
        parentGroupId: ""
      })
    }
    else {
      this.setState({
        parentGroupId: event.value
      })
    }
  }

  submit = event => {
    event.preventDefault();
    let storeGroupData = {
      groupName: this.state.groupName,
      groupDec: this.state.groupDescription,
    }
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    if(this.state.parentGroupId){
      storeGroupData = {
        groupName: this.state.groupName,
        groupDec: this.state.groupDescription,
        parent: {
          id: this.state.parentGroupId
        }
      }
    }


    Axios.post(`${API_CONFIG.HOST}/create/store/group`, storeGroupData, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        console.log(res);
        this.props.submitClose(res.data);
        console.log(this.state.parentGroupId);
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.container} onSubmit={this.submit.bind(this)} >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                required
                id="groupName"
                label="Group Name"
                value={this.state.groupName}
                className={classes.textField}
                margin="normal"
                onChange={this.handleChange('groupName')}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                required
                id="groupDescription"
                label="Group Description"
                value={this.state.groupDescription}
                className={classes.textField}
                margin="normal"
                onChange={this.handleChange('groupDescription')}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.grpDivStyle}>Parent Group</div>
              <Select
                options={this.props.options}
                onChange={this.selectHandleChange}
                className={classes.selectStyle}
                defaultInputValue="none"
                isClearable={this.state.isClearable}
              />
            </GridItem>
            <div>
              <Button type="submit" variant="contained" color="primary" className={classes.button}>
                SAVE
              </Button>
            </div>
          </GridContainer>
        </form>
      </div>
    );
  }
}

StoreGroupTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StoreGroupTable);