import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { render } from "react-dom";
import Axios from 'axios';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {API_CONFIG} from "../../config/config.jsx";


// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

class SalesLeadReport extends React.Component {
  constructor() {
    super();
    this.state = {
      salesLead: []
    };
  }
  render() {
    const { classes } = this.props;
    const { salesLead } = this.state;
    return (
      <div>
            <Card>
                <CardHeader color="success">
                    <h4 className={classes.cardTitleWhite}>
                        Sales Lead Report
                    </h4>
                </CardHeader>
                <CardBody>
        <br />
    
        <ReactTable
          data={salesLead}
          onFetchData={(state, instance) => {
            this.setState({ loading: true })
            // fetch your data
            const token = sessionStorage.getItem('plugoAuthKey');
            const userId = sessionStorage.getItem('plugoUserId');
            Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/sales/leads`, {
              headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
              }
            })
              .then(res => {
                let salesLead = res.data.map((row, index) => {
                  return {
                    storeName: row.storeName,
                    firstName: row.contactPerson.firstName,
                    lastName: row.contactPerson.lastName,
                    primaryPhoneNo: row.phoneNo.primaryPhoneNo,
                    secondaryPhoneNo: row.phoneNo.secondaryPhoneNo,
                    address: row.Address.address,
                    floor: row.Address.floorNo,
                    pin: row.Address.pincode,
                    email: row.email,
                    website: row.Website,
                    comment: row.comments
                  }});
                this.setState({
                  salesLead: salesLead
                });
              });
          }}
          
          columns={[
               
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',height:'40px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Store Name",
                  accessor: "storeName"
                },

                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "First Name",
                  accessor: "firstName"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Last Name",
                  accessor: "lastName"
                },
            
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Primary Phone No",
                  accessor: "primaryPhoneNo"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Secondary Phone no",
                  accessor: "secondaryPhoneNo"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Address",
                  accessor: "address"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Floor",
                  accessor: "floor"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "PIN",
                  accessor: "pin"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Email",
                  accessor: "email"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Website",
                  accessor: "website"
                },
                {
                  headerStyle: {background:'#00D06A',fontSize:'16px',paddingTop:'10px',color:'#FFFFFF'},
                  style: {textAlign: "center"},
                  Header: "Comment",
                  accessor: "comment"
                }

          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        </CardBody>
        </Card>
      </div>
    );
  }
}
SalesLeadReport.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SalesLeadReport);
