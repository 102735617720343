import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import momment from 'moment';
// import TimeInput from 'material-ui-time-picker'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from 'axios';
import {
    Button,
    Label,
    Input,
} from 'reactstrap';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    btnStyle: {
        marginLeft: '8px',
        marginTop: '15px'
    },
    storeSelectStyle: {
        width: '60%'
    },
    selectStyle: {
        marginTop: '2px',
        width: '200px'
    }
});
class OperatingHours extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            day: '',
            startTime: '',
            endTime: '',
            operatingsData: [],
            storeNames: [],
            isClearable: true,
            storeId: '',
            time: new Date(),
            operatingHours: [{ "day": 0, "startTime": new Date(), "endTime": new Date() }],
            days: [
                { key: 0, value: 'Sunday' },
                { key: 1, value: 'Monday' },
                { key: 2, value: 'Tuesday' },
                { key: 3, value: 'Wednesday' },
                { key: 4, value: 'Thursday' },
                { key: 5, value: 'Friday' },
                { key: 6, value: 'Saturday' }

            ]
        };

    }

    componentWillReceiveProps(newProps) {
        console.log(newProps.operatingHours);
        this.setState({
            operatingHours: newProps.operatingHours
        })
    }
    // componentWillUpdate(nextProps, nextState) {
    //     console.log('componentWillUpdate', nextProps, nextState);
    // }

    onTimeChange = (index, propName, time) => {
        const operatingHours = [...this.state.operatingHours];
        operatingHours[index][propName] = time;

        this.setState({ operatingHours: operatingHours });

    }
    onDaySelect = (index, newDay) => {
        const operatingHours = [...this.state.operatingHours];
        operatingHours[index]["day"] = newDay;
        this.setState({
            operatingHours: operatingHours
        })
    }

    onAddRow = () => {
        const operatingHours = [...this.state.operatingHours]
        operatingHours.push({ "day": 0, "startTime": new Date(), "endTime": new Date() });
        this.setState({
            operatingHours: operatingHours
        })

    }
    onHandleDelete = (index) => {
        const operatingHours = [...this.state.operatingHours];

        operatingHours.splice(index, 1);
        this.setState({
            operatingHours: operatingHours
        })
        // const deletedRow = operatingHours.filter(operatingData => {
        //     return operatingData.index !== index
        // })

    }
    componentDidMount() {
        console.log(this.props.operatingHours);
        const operatingHours = this.props.operatingHours
        this.setState({
            operatingHours: operatingHours
        });

    }
    selectHandleChange = (event) => {
        if (event === null) {
            this.setState({
                storeId: ""
            })
        }
        else {
            this.setState({
                storeId: event.value
            })
        }
    }
    handleSubmit = () => {
        const operatingHours = [...this.state.operatingHours];

        let newTimings = [];

        operatingHours.forEach(op => {
            if (op.startTime.getHours() > op.endTime.getHours()) {


                let time1 = Object.assign({}, op);
                let time2 = { ...op, startTime: new Date(op.startTime.toString()), endTime: new Date(op.endTime.toString()) }

                time1.endTime.setHours(23);
                time1.endTime.setMinutes(59);

                time2.startTime.setHours(0);
                time2.startTime.setMinutes(0);

                time2.day = Number(time2.day) + 1
                if (time2.day === 7) {
                    time2.day = 0
                }

                time2.startTime.setDate(time2.startTime.getDate() + 1)
                time2.endTime.setDate(time2.endTime.getDate() + 1)

                newTimings.push(time1)
                newTimings.push(time2)

            } else {
                newTimings.push(op);
            }
        });

        const reformattedArray = newTimings.map(obj => {
            const newObj = {};
            newObj["dayOfWeek"] = Number(obj.day);
            newObj["startTime"] = momment(obj.startTime).format("hh:mma");
            newObj["endTime"] = momment(obj.endTime).format("hh:mma");
            return newObj;
        });

        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');
        Axios.post(`${API_CONFIG.HOST}/stores/${this.props.storeId}/operating_hours`, reformattedArray, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {

                this.setState({
                    time: new Date()
                })

                var options = {
                    place: 'tc',
                    message: " " + "Added Successfully",
                    type: 'success',
                    icon: 'far fa-check-circle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            });
    }

    render() {
        const { classes } = this.props;
        let storeNames = this.state.storeNames.map(storeData =>
            ({
                value: storeData.id,
                label: storeData.name
            })
        );


        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <h5>{this.props.timingText}</h5>
                <br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <Label>Day</Label>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <Label>Start Time</Label>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <Label>End Time</Label>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>

                    </GridItem>
                </GridContainer>
                {/* <p>{this.props.operatingHours}</p> */}
                <GridContainer>
                    {
                        this.state.operatingHours.map((operatingHour, index) => {
                            return (<>
                                <GridItem xs={12} sm={12} md={3}>
                                    <Input type="select" name="select" onChange={(e) => this.onDaySelect(index, e.target.value)} className={classes.selectStyle} id="exampleSelect">
                                        {this.state.days.map(day => (
                                            <option value={day.key} selected={day.key === operatingHour.day}>{day.value}</option>)
                                        )}
                                    </Input>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                <div style={{ width:"200px" }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="time-picker"
                                                // label="Time picker"
                                                value={operatingHour.startTime}
                                                onChange={(time) => this.onTimeChange(index, "startTime", time)}
                                                style={{ marginTop: '1px' }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {/* <TimeInput
                                            mode='12h'
                                            value={operatingHour.startTime}
                                            onChange={(time) => this.onTimeChange(index, "startTime", time)}
                                        /> */}
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                <div style={{ width:"200px" }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="time-picker"
                                                // label="Time picker"
                                                value={operatingHour.endTime}
                                                onChange={(time) => this.onTimeChange(index, "endTime", time)}
                                                style={{ marginTop: '1px' }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {/* <TimeInput
                                            mode='12h'
                                            value={operatingHour.endTime}
                                            onChange={(time) => this.onTimeChange(index, "endTime", time)}
                                        /> */}
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <div style={{ marginTop: '1%' }}>
                                        <Button onClick={() => this.onHandleDelete(index)} style={{ backgroundColor: '#ef8157', borderColor: '#ef8157' }}>
                                            <i className='fa fa-times'></i>
                                        </Button>
                                    </div>
                                </GridItem>
                            </>)

                        })
                    }
                </GridContainer>
                <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
                    <div>
                        <Button onClick={this.onAddRow} variant="contained" className={classes.btnStyle} color="primary">Add</Button>
                        {/* <Button onClick={this.onHandleDelete} variant="contained" className={classes.btnStyle} color="danger">Delete</Button> */}
                    </div>
                </div>
                <div>
                    <Button onClick={this.handleSubmit} variant="contained" className={classes.btnStyle} color="primary">Submit</Button>
                </div>
            </div>
        );
    }
}

OperatingHours.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperatingHours);
