import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PremiumUserIcon from "assets/img/premiumUserIcon.png";
import SubscribedUser from "./../SubscribedUser/SubscribedUser.jsx"
import Secure from '../../../components/Secure.jsx';
import SearchUser from '../SearchUser/SearchUser.jsx';

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  imgStyle: {
    width: 30,
    height: 35
  }
});

class ReportTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      europeflag: false
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    })
  }

  componentDidMount() {
    if (process.env.REACT_APP_ACTIVE_REGION == "europe") {
      this.setState({
        europeflag: true
      })
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.europeflag ? (
          ''
        ) : (
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
              >
                {Secure("SUPER_ADMIN", <Tab label="Subscribed Users" icon={<img className={classes.imgStyle} src={PremiumUserIcon}></img>} />)}
              </Tabs>
            </AppBar>
          )}

        {this.state.value === "" ? Secure("READ_USER", <SearchUser />) : ''}
        {this.state.value === 0 && Secure("SUPER_ADMIN", <TabContainer><SubscribedUser /></TabContainer>)}
      </div>
    );
  }
}

ReportTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportTabs);