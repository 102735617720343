import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { Button, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CouponsTable from "./CouponsTable";
import { API_CONFIG } from "../../config/config.jsx";
import filterIcon from "assets/img/filterIcon1.png";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Axios from 'axios';
import Secure from '../../components/Secure.jsx';

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  textField: {
    width: "200px"
  },
  filterIconStyle: {
    width: 30,
    height: 30,
    // marginLeft: "85%",
    cursor: "pointer",
    // marginBottom: "2px"
  },
});

const type = [
  {
    value: 'ABSOLUTE',
    label: 'Absolute',
  },
  {
    value: 'PERCENT',
    label: 'Percent',
  },
];

class AdminCoupons extends React.Component {
  state = {
    // coupon: {
    name: '',
    description: '',
    code: '',
    type: 'ABSOLUTE',
    value: 0,
    visible: true,
    // },
    coupons: [],
    account: ""
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  onChangeVisible = () => {
    this.setState({
      visible: !this.state.visible
    })
  }
  componentDidMount() {
    this.getCoupons(0);
  }
  getCoupons = (page) => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');
    const url = this.state.account ? `${API_CONFIG.HOST}/coupons?size=2000&page=${page}&account=${this.state.account}` : `${API_CONFIG.HOST}/coupons?size=2000&page=${page}`
    Axios.get(url, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        const coupons = [...this.state.coupons, ...res.data.content]
        this.setState({
          coupons: coupons
        }, () => {
          if (res.data.last === false) {
            this.getCoupons(page + 1);
          }
        });
      });
  }
  filterAccountCoupon = (value) => {
      this.setState({
        account: value,
        coupons: []
      }, () => this.getCoupons(0));
  }
  onHandleDeleteCoupon = (couponId) => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const couponData = [...this.state.coupons];

    const filterCoupons = couponData.filter(coupon => {
      return coupon.id !== couponId
    });

    Axios.delete(`${API_CONFIG.HOST}/coupons/${couponId}`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      })
      .then(res => {
        this.setState({
          coupons: filterCoupons
        });
        var options = {
          place: 'tc',
          message: " " + "Deleted Successfully",
          type: 'success',
          icon: 'far fa-check-circle',
          autoDismiss: 4
        }
        if (this.notification && this.notification.state.notifyTR.length < 1) {
          this.notification.notificationAlert(options)
        }
      })
      .catch(error => {
        const msg = error.response.data.errors.status.includes(`ERROR: update or delete on table "coupons" violates foreign key constraint`) ? "This Coupon cannot be deleted. Please contact system admin" : "Something went wrong"
        const options = {
          place: 'tc',
          message: msg,
          type: 'danger',
          icon: 'fas fa-exclamation-triangle',
          autoDismiss: 4
        }
        if (this.notification && this.notification.state.notifyTR.length < 1) {
          this.notification.notificationAlert(options)
        }
      })
  }

  submit = event => {
    event.preventDefault();

    const couponData = {
      name: this.state.name,
      description: this.state.description,
      code: this.state.code,
      type: this.state.type,
      value: this.state.value,
      isVisible: this.state.visible
    }
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    Axios.post(`${API_CONFIG.HOST}/coupons`, couponData, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        console.log(res);
        this.getCoupons();
        this.setState({
          name: '',
          description: '',
          code: '',
          type: 'ABSOLUTE',
          value: 0,
        })
      });
  };

  render() {
    const headers = [
      { id: 'name', label: 'Name' },
      { id: 'description', label: 'Description' },
      { id: 'type', label: 'Type' },
      { id: 'value', label: 'Value' },
      { id: 'code', label: 'Code' },
      { id: 'action', label: 'Action' },
    ]
    const buttonStyle = {
      position: 'relative',
      marginLeft: '45%',
      marginTop: '5%',
      color: "#fff"
    };
    const { classes } = this.props;
    const rows = this.state.coupons
    return (
      <div>
        <div className="rna-container">
          <NotificationAlert ref={(c) => { this.notification = c }} />
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>Add Coupon</h4>
              </CardHeader>
              <CardBody>
                {Secure("CREATE_COUPONS", <form className={classes.container} onSubmit={this.submit.bind(this)}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        required
                        id="outlined-name"
                        label="Name"
                        value={this.state.name}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange('name')}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        id="outlined-description"
                        label="Description"
                        value={this.state.description}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange('description')}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        id="outlined-select-type"
                        select
                        // label="Type"
                        className={classes.textField}
                        value={this.state.type}
                        onChange={this.handleChange('type')}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        helperText="Please select your type"
                        margin="normal"
                      >
                        {type.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        required
                        id="outlined-value"
                        label="Value"
                        value={this.state.value}
                        onChange={this.handleChange('value')}
                        type="number"
                        className={classes.textField}
                        margin="normal"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        required
                        id="outlined-code"
                        label="Code"
                        value={this.state.code}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange('code')}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div style={{ marginTop: "25px" }}>
                        <label style={{ fontSize: "16px", color: "#3c4858", fontWeight: 200 }}>Visible</label>
                        <Checkbox
                          checked={this.state.visible}
                          value={this.state.visible}
                          onChange={this.onChangeVisible}
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                          style={{ color: "#3f51b5" }}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <div style={buttonStyle}>
                    <Button type="submit" variant="contained" size="small" className={classes.button}>
                      <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                      Save
                    </Button>
                  </div>
                </form>)}
                <br></br>
                {/* <div>
                  <img title="Filter Coupons" className={classes.filterIconStyle} src={filterIcon}></img>
                  <Chip
                    avatar={<Avatar>F</Avatar>}
                    label="Deletable"
                    onDelete={this.handleDelete}
                    variant="outlined"
                  />
                </div> */}
                {Secure("READ_COUPONS", <CouponsTable headers={headers} rows={rows} filterAccountCoupon={this.filterAccountCoupon} onHandleDeleteCoupon={this.onHandleDeleteCoupon} />)}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AdminCoupons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminCoupons);
