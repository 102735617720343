var Chartist = require("chartist");
var chartistPluginTooltips = require("chartist-plugin-tooltips");
var Moment = require("moment");
// import moment from 'moment';

var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;


// ##############################
// // // Daily Income
// #############################
const dailyIncomeChart = {
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    // high: 10000,
    showPoint: true,
    plugins: [
      Chartist.plugins.tooltip()
    ],
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },

  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

// ##############################
// // // New User
// #############################

const newUserChart = {
  options: {
    axisX: {
      showGrid: false
    },
    showPoint: true,
    stackBars: true,
    plugins: [
      Chartist.plugins.tooltip()
    ],
    low: 0,
    // high: 100,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0
    }
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

// ##############################
// // // Total User
// #############################
const TotalUserChart = {
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    high: 12000,
    showPoint: true,
    plugins: [
      Chartist.plugins.tooltip()
    ],
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};


  const dailyIncomeChartFn = (data) => {
    const labels = data.map(d => {
      let incomeDate = d.date;
     let date =  Moment(incomeDate).format('DD/MM');
     return date;
    })
    const series = data.map(d => Math.floor(d.amount));
    dailyIncomeChart.data =  {
      labels,
      series: [{meta:"Income", data: series }]
    }
    return  dailyIncomeChart
  }

  const totalUserChartFn = (data) => {
    const premiumUser = data.map(d => d.premiumUsers);
    const standardUser = data.map(d => d.standardUsers);
   

    // const maxTotalUser = Math.max.apply(Math, totalUserTest);
    // TotalUserChart.high = maxTotalUser;
    TotalUserChart.data =  {
      labels: ["W1", "W2", "W3", "W4", "W5", "W6", "W7"],
      series: [
      {meta:"Premium User", data: premiumUser },
      {meta:"Standard User  ", data: standardUser }]
    }
    return  TotalUserChart
  }
  const newUserChartFn = (data) => {
    const premiumUsersSeries = data.map(d => d.premiumUsers);
    const standardUsersSeries = data.map(d => d.standardUsers);
    newUserChart.data =  {
      labels: ["W1", "W2", "W3", "W4", "W5", "W6", "W7", "W8", "W9", "W10", "W11", "W12"],
      series: [{meta:"Premium User  ", data: premiumUsersSeries } ,
      {meta:"Standard User", data: standardUsersSeries }]
    }
    return newUserChart
  }

module.exports = {
  newUserChartFn,
  totalUserChartFn,
  dailyIncomeChartFn
};
