export default (state = {}, action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          mobile: action.payload.mobile,
          token: action.payload.token,
          userId: action.payload.userId,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage,
          dispatchType: action.type
        };
  
      case 'TOKEN_LOGIN': {
        return {
          token: action.payload.token
        }
      }
      case 'LOGOUT':
        return {
          token: undefined
        }
      default:
        return state;
    }
  };