import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import CouponsRules from './CouponsRules.jsx';
import CouponMap from './CouponMap.jsx';
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import filterIcon from "assets/img/filterIcon1.png";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Secure from '../../components/Secure.jsx';

const styles = theme => ({
    table: {
        minWidth: 700,
    },
    tableHeader: {
        backgroundColor: "#eee"
    },
    filterIconStyle: {
        width: 30,
        height: 30,
        cursor: "pointer",
        float: "left"
        // marginBottom: "2px"
    },
});

class CouponTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedCouponId: '',
            maxWidth: 'xl',
            couponName: '',
            openMap: false,
            openDeleteDialog: false,
            selectedDeleteId: '',
            openMenu: null,
            accounts: ["", "Sving"],
            selectedIndex: undefined
        }
    }
    handleClickOpen = (id, couponName) => {
        this.setState({
            open: true,
            selectedCouponId: id,
            couponName: couponName
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleMapOpen = (id, couponName) => {
        this.setState({
            openMap: true,
            selectedCouponId: id,
            couponName: couponName
        })
    }
    handleMapClose = () => {
        this.setState({ openMap: false })
    }
    handleDelete = (id) => {
        this.setState({
            openDeleteDialog: !this.state.openDeleteDialog,
            selectedDeleteId: id
        })
    }
    closeDeleteDialog = () => {
        this.setState({
            openDeleteDialog: false
        })
    }
    delete = () => {
        this.setState({
            openDeleteDialog: false
        }, this.props.onHandleDeleteCoupon(this.state.selectedDeleteId))
    }
    handleFilterClick = (event) => {
        this.setState({ openMenu: event.currentTarget });
        console.log(event.currentTarget);
    }
    handleMenuItemClick = (event, index) => {
        this.setState({
            selectedIndex: index,
            openMenu: null
        }, this.props.filterAccountCoupon(this.state.accounts[index]))
        console.log(index)
    }
    handleMenuClose = () => {
        this.setState({ openMenu: null })
    };
    handleReset = () => {
        this.setState({
            selectedIndex: undefined
        }, this.props.filterAccountCoupon());
    }
    render() {
        const { classes } = this.props;
        let coupons = this.props.rows.map((row, index) => {
            return {
                name: row.name,
                description: row.description,
                type: row.type,
                value: row.value,
                code: row.code,
                id: row.id
            }
        });

        return (
            <div>
                <ReactTable
                    data={coupons}
                    onFetchData={this.fetchData}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    columns={[
                        {
                            Header: <div className="komal"><img
                                title="Filter Coupons" className={classes.filterIconStyle} src={filterIcon} onClick={this.handleFilterClick} aria-controls="simple-menu" aria-haspopup="true"
                            ></img>
                                <Menu
                                    id="lock-menu"
                                    anchorEl={this.state.openMenu}
                                    keepMounted
                                    open={this.state.openMenu}
                                    onClose={this.handleMenuClose}
                                >{this.state.accounts.map((option, index) => (
                                    <MenuItem
                                        key={option}
                                        selected={index === this.state.selectedIndex}
                                        onClick={(event) => this.handleMenuItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                                </Menu>
                                {this.state.selectedIndex ?
                                    <Chip
                                        style={{ float: "left" }}
                                        // avatar={<Avatar>M</Avatar>}
                                        label={this.state.accounts[this.state.selectedIndex]}
                                        onDelete={this.handleReset}
                                    /> : ""}
                            </div>,
                            style: { textAlign: "left" },
                            columns: [
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px', fontWeight: '500' },
                                    style: { textAlign: "center" },
                                    Header: "Name",
                                    id: "name",
                                    accessor: d => d.name,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["name"] }),
                                    filterAll: true
                                },

                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                    style: { textAlign: "center" },
                                    Header: "Description",
                                    id: "description",
                                    accessor: d => d.description,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["description"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                    style: { textAlign: "center" },
                                    Header: "Type",
                                    id: "type",
                                    accessor: d => d.type,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["type"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                    style: { textAlign: "center" },
                                    Header: "Value",
                                    id: "value",
                                    accessor: d => d.value,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["value"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                    style: { textAlign: "center" },
                                    Header: "Code",
                                    id: "code",
                                    accessor: d => d.code,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["code"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                    style: { textAlign: "center" },
                                    Header: "Action",
                                    id: "action",
                                    accessor: d => d.action,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["action"] }),
                                    filterAll: true,
                                    Cell: (rowInfo) => {
                                        return (
                                            <div>
                                                {Secure("READ_COUPON_RULES", <button onClick={() => { this.handleClickOpen(rowInfo.original.id, rowInfo.original.name) }} >Rules</button>)}
                                                {Secure("READ_COUPON_MAPPINGS", <button onClick={() => { this.handleMapOpen(rowInfo.original.id, rowInfo.original.name) }} style={{ marginLeft: '4px' }} >Map</button>)}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                    style: { textAlign: "center" },
                                    Header: "Delete",
                                    id: "delete",
                                    accessor: d => d.delete,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["delete"] }),
                                    filterAll: true,
                                    Cell: (rowInfo) => {
                                        return (
                                            <div>
                                                {Secure("DELETE_COUPONS", <Button onClick={() => this.handleDelete(rowInfo.original.id)} style={{ minWidth: "10px", backgroundColor: '#ef8157', borderColor: '#ef8157' }}>
                                                    <i className='fa fa-times'></i>
                                                </Button>)}
                                            </div>
                                        );
                                    },
                                },
                            ]
                        }
                    ]}
                    // defaultPageSize={5}
                    className="-striped -highlight"
                />
                <Dialog
                    maxWidth={this.state.maxWidth}
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <CouponsRules selectedCouponId={this.state.selectedCouponId} couponName={this.state.couponName} />
                </Dialog>
                <Dialog
                    // maxWidth={this.state.maxWidth}
                    // minWidth='700px'
                    fullWidth={true}
                    open={this.state.openMap}
                    onClose={this.handleMapClose}
                >
                    <CouponMap selectedCouponId={this.state.selectedCouponId} couponName={this.state.couponName} />
                </Dialog>
                <Dialog open={this.state.openDeleteDialog} onClose={this.closeDeleteDialog} maxWidth="md">
                    <DialogTitle id="simple-dialog-title" className="text-center">Are you sure you want to delete coupon</DialogTitle>
                    <div className="d-flex justify-content-center row col-xl-12 p-4">
                        <button className="btn btn-success col-xl-4" onClick={this.delete}>Yes</button>
                        <button className="btn btn-danger col-xl-4 ml-4" onClick={this.closeDeleteDialog}>No</button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

CouponTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CouponTable);

