import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from 'axios';
import Background from './../../../assets/img/RentGif.gif';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Secure from '../../../components/Secure.jsx';

var Moment = require("moment");

var sectionStyle = {
    width: "100%",
    // height: "400px",
    backgroundPosition: "center",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover"
};
const styles = theme => ({
    divStyle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-end",
        fontSize: "12px"
    },
});
class UserActiveRental extends React.Component {
    state = {
        showRental: false,
        showText: true,
        confirmflag: false,
        pickUpStoreName: '',
        PowerbankDeviceId: ''
    };

    chargeUser = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/lostPowerbank/${this.props.userId}/chargeRequest`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    confirmflag: !this.state.confirmflag
                })
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
                alert("Internal Error")
            })
    }

    drawerFunction = () => {
        this.setState({
            confirmflag: !this.state.confirmflag
        })
    }

    componentDidMount() {

        const rentStoreId = this.props.userActiveData[0].rentStoreId;
        const token = sessionStorage.getItem('plugoAuthKey');
        console.log(token)
        Axios.get(`${API_CONFIG.HOST}/stores/${rentStoreId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    pickUpStoreName: res.data.name
                });
            });
        this.getPBDeviceId();
    }
    getPBDeviceId = () => {
        const powerBankId = this.props.userActiveData[0].powerbankId;
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/powerbanks/deviceid/${powerBankId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                console.log(res);
                this.setState({
                    PowerbankDeviceId: res.data.deviceId
                });
            });
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.userActiveData[0].userId !== prevProps.userActiveData[0].userId) {
            const token = sessionStorage.getItem('plugoAuthKey');
            const rentStoreId = this.props.userActiveData[0].rentStoreId;
            Axios.get(`${API_CONFIG.HOST}/stores/${rentStoreId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })
                .then(res => {
                    this.setState({
                        pickUpStoreName: res.data.name
                    });
                });
            const powerBankId = this.props.userActiveData[0].powerbankId;
            Axios.get(`${API_CONFIG.HOST}/powerbanks/deviceid/${powerBankId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })
                .then(res => {
                    console.log(res);
                    this.setState({
                        PowerbankDeviceId: res.data.deviceId
                    });
                });
        }
    }

    showRental = () => {
        this.setState({
            showRental: !this.state.showRental,
            showText: !this.state.showText
        })
    }


    timeDiff = (startTime) => {
        var startDate = new Date(startTime);
        var endDate = new Date();
        var diff = endDate - startDate;
        var hours = Math.floor(diff / 3.6e6);
        var minutes = Math.floor((diff % 3.6e6) / 6e4);
        var seconds = Math.floor((diff % 6e4) / 1000);
        return `${hours} : ${minutes} : ${seconds}`
    }

    render() {
        const { classes, userActiveData } = this.props;
        return (
            <div style={sectionStyle}>
                {userActiveData[0].startTime === 0 && userActiveData !== 0 ? "Rental is yet to start" :
                    <><div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}>
                        <div>
                            <div style={{ fontWeight: 500 }}>Active Rental</div>
                            <div>{this.timeDiff(userActiveData[0].startTime)}</div>
                            <div>Hr:Min:Sec</div>
                        </div>
                        <div>
                            <div>{Moment(userActiveData[0].startTime).format('L')}</div>
                            <button style={{ display: 'block', borderRadius: "50px", border: '1px solid #eee', backgroundColor: '#28a745', color: "white" }} onClick={this.showRental}>{this.state.showText ? 'More' : 'Less'}</button>

                        </div>
                    </div>
                        {this.state.showRental ?
                            <ul style={{ paddingLeft: "10px", listStyle: "inside" }}>
                                <li>PowerBank Id: {this.state.PowerbankDeviceId} </li>
                                <li>PickUp Store: {this.state.pickUpStoreName}</li>
                                <li>Coupon: {userActiveData[0].couponCode}</li>
                                <div className="justify-content-center d-flex col-xl-12">
                                    {Secure("LOST_POWERBANK_CHARGE", <button className="btn btn-danger rounded-pill" onClick={this.drawerFunction}>Charge for lost powerbank</button>)}
                                </div>

                            </ul>
                            : ''}
                        <div>
                            <Dialog fullWidth={'md'} open={this.state.confirmflag} onClose={this.drawerFunction} aria-labelledby="simple-dialog-title" >
                                <DialogTitle id="simple-dialog-title" className="text-center">Are You Sure To Charge User for Lost Powerbank</DialogTitle>
                                <div className="d-flex justify-content-center row col-xl-12 p-4">
                                    <button className="btn btn-success col-xl-4" onClick={this.chargeUser}>YES</button>
                                    <button className="btn btn-danger col-xl-4 ml-4" onClick={this.drawerFunction}>NO</button>
                                </div>
                            </Dialog>
                        </div>
                        <Divider />
                    </>
                }
            </div>
        );
    }
}

UserActiveRental.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserActiveRental);
