import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { API_CONFIG } from "./../../../../config/config.jsx";
import ReactTable from "react-table";
import Axios from "axios";
import matchSorter from "match-sorter";
import DateRangeTransaction from "./../DateRangeTransaction.jsx";
import DatePickedByPin from "./DatePickedtByPin.jsx";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  }
});
class TransactionReportByPin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionDataByPin: [],
      showReport: false,
      startDate: "",
      endDate: ""
    };
  }
  // showReport = () => {
  //     this.setState({
  //         showReport: true
  //     });
  // }
  // showDefaultReport = (showDefaultReport) => {
  //     this.setState({
  //         showReport: showDefaultReport
  //     })
  // }
  // fetchStandardReportData = (startDate, endDate, showReport) => {
  //     this.setState({
  //         startDate: startDate,
  //         endDate: endDate,
  //         showReport: showReport
  //     })
  // }
  fetchData = () => {
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");
    Axios.get(
      `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/transactions/${
        this.props.city
      }/pincode/${userId}`
    ).then(res => {
      this.setState({
        transactionDataByPin: res.data
      });
    });
  };
  componentWillReceiveProps = nextProps => {
    if (this.props.city !== nextProps.city) {
      const userId = sessionStorage.getItem("plugoUserId");
      Axios.get(
        `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/transactions/${
          nextProps.city
        }/pincode/${userId}`
      ).then(res => {
        this.setState({
          transactionDataByPin: res.data
        });
      });
    }
  };
  render() {
    const { classes } = this.props;
    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: e => {
          this.props.onSelectedPin(rowInfo.original.pin);
        }
      };
    };

    let transactionDataByPin = this.state.transactionDataByPin.map(
      (row, index) => {
        return {
          pin: row.pincode,
          dailyDispensed: row.dailyDespensed,
          dailyReturned: row.dailyReturned,
          weeklyDispensed: row.weeklyDespensed,
          weeklyReturned: row.weeklyReturned,
          monthlyDispensed: row.monthlyDespensed,
          monthlyReturned: row.monthlyReturned
        };
      }
    );
    return (
      <div>
        {/* <DateRangeTransaction showReport={this.showReport} showDefaultReport={this.showDefaultReport} fetchStandardReportData={this.fetchStandardReportData} />
                {this.state.showReport ?
                    <DatePickedByPin city={this.props.city} startDate={this.state.startDate} endDate={this.state.endDate} /> : */}
        <ReactTable
          data={transactionDataByPin}
          onFetchData={this.fetchData}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "PIN",
              columns: [
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center", cursor: "pointer" },
                  Header: "Pincode",
                  id: "pin",
                  accessor: d => d.pin,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["pin"] }),
                  filterAll: true
                }
              ]
            },
            {
              Header: "Daily",
              columns: [
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center", cursor: "pointer" },
                  Header: "Dispensed",
                  id: "dailyDispensed",
                  accessor: d => d.dailyDispensed,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["dailyDispensed"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center", cursor: "pointer" },
                  Header: "Returned",
                  id: "dailyReturned",
                  accessor: d => d.dailyReturned,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["dailyReturned"]
                    }),
                  filterAll: true
                }
              ]
            },
            {
              Header: "Weekly",
              columns: [
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center", cursor: "pointer" },
                  Header: "Dispensed",
                  id: "weeklyDispensed",
                  accessor: d => d.weeklyDispensed,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["weeklyDispensed"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center", cursor: "pointer" },
                  Header: "Returned",
                  id: "weeklyReturned",
                  accessor: d => d.weeklyReturned,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["weeklyReturned"]
                    }),
                  filterAll: true
                }
              ]
            },
            {
              Header: "Monthly",
              columns: [
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center", cursor: "pointer" },
                  Header: "Dispensed",
                  id: "monthlyDispensed",
                  accessor: d => d.monthlyDispensed,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["monthlyDispensed"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center", cursor: "pointer" },
                  Header: "Returned",
                  id: "monthlyReturned",
                  accessor: d => d.monthlyReturned,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["monthlyReturned"]
                    }),
                  filterAll: true
                }
              ]
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
          getTrProps={onRowClick}
        />
        {/* } */}
      </div>
    );
  }
}
TransactionReportByPin.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TransactionReportByPin);
