export default (state = {
  city: '',
  pincodes: []
}, action) => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        city: action.payload.city,
        pincodes: action.payload.pincodes,
      }
    }
    default:
      return state;
  }
};