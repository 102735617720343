import React, { Component } from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from "axios";
import pdficon from './../../../assets/img/pdf.png'

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "0px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
});


class InvoiceReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allinvoice: [],
            mobile: '',
            name: ''
        }
    }

    currentfile = val => {
        let link = 'https://' + val
        var elem = document.createElement("a");
        elem.setAttribute("href", link);
        elem.setAttribute("download", "download");
        elem.click();
    };

    componentDidMount() {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(
            `${
            API_CONFIG.HOST}/invoice/all`, {
            headers: {
                "Authorization": `Bearer ${token}`

            }
        }
        ).then(res => {
            this.setState({
                allinvoice: res.data
            })
        })
            .catch(err => {
                console.log(err)
                this.setState({
                    allinvoice: []
                })
            })
    }

    render() {
        const { classes } = this.props;
        let invoiceData = this.state.allinvoice.map((elem, index) => {
            let created_date = elem.createdAt.split('T')[0]
            return {
                "invoice": elem.parent,
                "mobile": elem.mobile,
                "datecreated": created_date,
                "action": <img src={pdficon} style={{ width: "30px", height: "30px" }} onClick={() => this.currentfile(elem.url)} />
            }
        });
        return (
            <div>
                <Card>
                    <CardHeader color="success">
                        <h4 className={classes.cardTitleWhite}>Invoice Report</h4>
                    </CardHeader>
                    <ReactTable
                        data={invoiceData}
                        filterable
                        columns={[
                            {
                                headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px', fontWeight: '500' },
                                style: { textAlign: "center" },
                                Header: "Invoice Number",
                                accessor: "invoice"
                            },

                            {
                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                style: { textAlign: "center" },
                                Header: "Mobile Number",
                                accessor: "mobile"
                            },
                            {
                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                style: { textAlign: "center" },
                                Header: "Date",
                                accessor: "datecreated"
                            },
                            {
                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                style: { textAlign: "center" },
                                Header: "Invoice",
                                accessor: "action"
                            },
                        ]}
                        defaultPageSize={25}
                        className="-striped -highlight"
                    />
                </Card>
            </div>
        );
    }
}

InvoiceReport.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InvoiceReport);