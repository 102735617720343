import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Checkbox } from '@material-ui/core';
import ReactTable from "react-table";


const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tableHeader: {
        backgroundColor: "#eee"
    }
});
class CouponRulesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxes: [],
        }
    }
    render() {
        const { classes } = this.props;
        const style = {
            marginLeft: '-40px'
        }
        let couponRules = this.props.rows.map((row, index) => {
            return {
                couponId: row.id,
                startDate: row.startDate,
                endDate: row.endDate,
                startTime: row.startTime,
                endTime: row.endTime,
                pincode: row.pincode,
                city: row.city,
                userName: row.userName,
                storeName: row.storeName,
                usageLimit: row.usageLimit,
                totalUsageLimit: row.totalUsageLimit,
                rentalSessionLimit: row.rentalSessionLimitInHours,
                active: row.active,
                action: row.action
            }
        });

        return (
            <ReactTable
                data={couponRules}
                onFetchData={this.fetchData}
                columns={[
                    {
                        headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Start Date",
                        accessor: "startDate"
                    },

                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "End Date",
                        accessor: "endDate"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Start Time",
                        accessor: "startTime"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "End Time",
                        accessor: "endTime"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Pincode",
                        accessor: "pincode"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "City",
                        accessor: "city"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "User Name",
                        accessor: "userName"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Store Name",
                        accessor: "storeName"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Usage Limit",
                        accessor: "usageLimit"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Total Usage Limit",
                        accessor: "totalUsageLimit"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Rental Session Limit",
                        accessor: "rentalSessionLimit"
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Active",
                        accessor: "active",
                        Cell: (rowInfo) => {
                            return (
                                <Checkbox
                                    style={{ color: "#00D06A" }}
                                    type="checkbox"
                                    className="checkbox"
                                    checked={rowInfo.original.active}
                                    onChange={(event) => { this.props.handleActive(event, rowInfo.original.couponId) }}
                                />
                            );
                        },
                    },
                    {
                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                        style: { textAlign: "center" },
                        Header: "Action",
                        accessor: "action",
                        Cell: (rowInfo) => {
                            return (
                                <Button style={{ color: "#00D06A" }} variant="contained" className={classes.button} onClick={() => { this.props.handleDelete(rowInfo.original.couponId) }}>
                                    delete
                                </Button>
                            );
                        },
                    },
                ]}
                defaultPageSize={5}
                className="-striped -highlight"
            />
        );
    }
}

CouponRulesTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CouponRulesTable);