import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import AccessTime from "@material-ui/icons/AccessTime";
import UserIcon from "assets/img/userIcon.png";
import MoneyIcon from "assets/img/moneyIcon.png";
import PremiumUserIcon from "assets/img/premiumUserIcon.png";
import PowerBankIcon from "assets/img/powerBankIcon.png";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Axios from "axios";
import { API_CONFIG } from "../../config/config.jsx";
import { connect } from "react-redux";

import {
  newUserChartFn,
  totalUserChartFn,
  dailyIncomeChartFn
} from "variables/charts.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const imgStyle = {
  width: 50,
  height: 45
};
// const totalUserBtnStyle = {
//   height: 15,
//   width: 3,
//   cursor: "auto",
//   border: "1px solid black",
//   borderRadius: "50%",
//   backgroundColor: "#d70206",
//   marginLeft: "8px"
// }
// const standardUserBtnStyle = {
//   height: 15,
//   width: 3,
//   cursor: "auto",
//   border: "1px solid black",
//   borderRadius: "50%",
//   backgroundColor: "#f4c63d",
//   marginLeft: "8px"
// }
// const premiumUserBtnStyle = {
//   height: 15,
//   width: 3,
//   cursor: "auto",
//   border: "1px solid black",
//   borderRadius: "50%",
//   backgroundColor: "#ffffff",
//   marginLeft: "8px"
// }
const headerStyle = {
  marginLeft: "3px"
};
class Dashboard extends React.Component {
  state = {
    city: "",
    pincodes: [],
    value: 0,
    totalUser: [],
    currentStandardUser: [],
    currentPremiumUser: [],
    totalRentalIncome: [],
    totalSubscriptionIncome: [],
    totalWeeklyIncome: [],
    totalDespensedPowerbanks: [],
    totalReturnedPowerBanks: [],
    newUsers: [],
    totalUsers: [],
    europeflag: false,
    isLoading: false
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    if (this.props.city) {
      this.fetchRecords();
    }
    if (process.env.REACT_APP_ACTIVE_REGION == "europe") {
      this.setState({
        europeflag: true
      });
    }
  }
  getSign = () => {
    if (process.env.REACT_APP_ACTIVE_REGION == "europe") {
      return "€";
    } else {
      return "₹";
    }
  };

  async componentDidUpdate(nextProps, prevState) {
    if (this.props.city !== nextProps.city) {
      this.fetchRecords();
    }
  }

  fetchRecords = async () => {
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");

    const promises = [
      Axios.get(
        `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/users/${userId}/${
          this.props.city
        }`
      ),
      Axios.get(
        `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/income/total/${
          this.props.city
        }/${userId}`
      ),
      Axios.get(
        `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/totalDespensedPowerbanks/${
          this.props.city
        }/${userId}`
      ),
      Axios.get(
        `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/income/weekly/${
          this.props.city
        }/${userId}`
      ),
      Axios.get(
        `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/users/weeks/${userId}/${
          this.props.city
        }`
      ),
      Axios.get(
        `${
          API_CONFIG.PLUGO_REPORTS_API_HOST
        }/report/users/totalUsers/weeks/${userId}/${this.props.city}`
      )
    ];

    const responses = await Promise.all(promises);
    this.setState({
      totalUser: responses[0].data.totalUsers,
      currentStandardUser: responses[0].data.standardUsers,
      currentPremiumUser: responses[0].data.premiumUsers,
      totalRentalIncome: Math.round(responses[1].data[0].amount),
      totalSubscriptionIncome: Math.round(responses[1].data[1].amount),
      totalDespensedPowerbanks: responses[2].data.totalPowerbankDespensed,
      totalReturnedPowerBanks: responses[2].data.totalPowerbankReturned,
      totalWeeklyIncome: responses[3].data,
      newUsers: responses[4].data,
      totalUsers: responses[5].data
    });
    this.setState({ isLoading: false });
  };
  render() {
    const { classes } = this.props;
    const dailyIncomeChartTest = dailyIncomeChartFn(
      this.state.totalWeeklyIncome
    );
    const newUserChartTest = newUserChartFn(this.state.newUsers);
    const totalUserChartTest = totalUserChartFn(this.state.totalUsers);
    if (this.state.isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader
                color="warning"
                stats
                icon
                style={{ height: "120px" }}
              >
                <CardIcon color="danger">
                  <img src={UserIcon} style={imgStyle} alt="Total User" />
                </CardIcon>
                <p className={classes.cardCategory}>Total User</p>
                <h3 className={classes.cardTitle}>{this.state.totalUser}</h3>
                {this.state.europeflag ? (
                  ""
                ) : (
                  <div>
                    <p className={classes.cardCategory}>
                      Current Standard User
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.currentStandardUser}
                    </h3>
                  </div>
                )}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          {this.state.europeflag ? (
            ""
          ) : (
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader
                  color="success"
                  stats
                  icon
                  style={{ height: "120px" }}
                >
                  <CardIcon color="success">
                    <img
                      src={PremiumUserIcon}
                      style={{ height: 55 }}
                      alt="Premium User"
                    />
                  </CardIcon>
                  <p className={classes.cardCategory}>Current Premium User </p>
                  <h3 className={classes.cardTitle}>
                    {this.state.currentPremiumUser}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Last 24 Hours
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          )}

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon style={{ height: "120px" }}>
                <CardIcon color="info" style={{ height: 80 }}>
                  <Icon>
                    <img
                      src={MoneyIcon}
                      style={{ marginBottom: "20px", height: "50px" }}
                      alt="Total Income"
                    />
                  </Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Total Rental Income</p>
                <h3 className={classes.cardTitle}>
                  {this.getSign() + this.state.totalRentalIncome}
                </h3>
                {this.state.europeflag ? (
                  ""
                ) : (
                  <div>
                    <p className={classes.cardCategory}>
                      Total Subscription Income
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.getSign() + this.state.totalSubscriptionIncome}
                    </h3>
                  </div>
                )}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <LocalOffer />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="info" stats icon style={{ height: "120px" }}>
                <CardIcon color="warning">
                  <img
                    src={PowerBankIcon}
                    style={imgStyle}
                    alt="Dispensed PowerBank"
                  />
                </CardIcon>
                <p className={classes.cardCategory}>Pick Ups</p>
                <h3 className={classes.cardTitle}>
                  {this.state.totalDespensedPowerbanks}
                </h3>
                <p className={classes.cardCategory}> Drops</p>
                <h3 className={classes.cardTitle}>
                  {this.state.totalReturnedPowerBanks}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={dailyIncomeChartTest.data}
                  type="Line"
                  options={dailyIncomeChartTest.options}
                  listener={dailyIncomeChartTest.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Daily Income</h4>
                <p className={classes.cardCategory}>Last Week Performance</p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="warning">
                <ChartistGraph
                  className="ct-chart"
                  data={newUserChartTest.data}
                  type="Bar"
                  options={newUserChartTest.options}
                  responsiveOptions={newUserChartTest.responsiveOptions}
                  listener={newUserChartTest.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>New User</h4>
                <p className={classes.cardCategory}>
                  Last 3 Months Performance
                </p>
              </CardBody>
              {this.state.europeflag ? (
                <CardFooter chart>
                  <div className={classes.stats}>
                    <button
                      type="button"
                      className={classes.userBtnStyle}
                      style={{ backgroundColor: "#f05b4f" }}
                    />
                    <h6 style={headerStyle}>User</h6>
                    {/* <button type="button" className={classes.userBtnStyle} style={{ backgroundColor: "#ffffff" }}>
                    </button>
                    <h6 style={headerStyle}>Premium User</h6> */}
                  </div>
                </CardFooter>
              ) : (
                <CardFooter chart>
                  <div className={classes.stats}>
                    <button
                      type="button"
                      className={classes.userBtnStyle}
                      style={{ backgroundColor: "#f05b4f" }}
                    />
                    <h6 style={headerStyle}>Standard User</h6>
                    <button
                      type="button"
                      className={classes.userBtnStyle}
                      style={{ backgroundColor: "#ffffff" }}
                    />
                    <h6 style={headerStyle}>Premium User</h6>
                  </div>
                </CardFooter>
              )}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="info">
                <ChartistGraph
                  className="ct-chart"
                  data={totalUserChartTest.data}
                  type="Line"
                  options={totalUserChartTest.options}
                  listener={totalUserChartTest.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Total User</h4>
                <p className={classes.cardCategory}>Last 7 Week Performance</p>
              </CardBody>
              {this.state.europeflag ? (
                <CardFooter chart>
                  <div className={classes.stats}>
                    <button
                      type="button"
                      className={classes.userBtnStyle}
                      style={{ backgroundColor: " #d70206" }}
                    />
                    <h6 style={headerStyle}>User</h6>
                    {/* <button type="button" className={classes.userBtnStyle} style={{ backgroundColor: "#ffffff" }}>
                      </button>
                      <h6 style={headerStyle}>Premium User</h6> */}
                  </div>
                </CardFooter>
              ) : (
                <CardFooter chart>
                  <div className={classes.stats}>
                    <button
                      type="button"
                      className={classes.userBtnStyle}
                      style={{ backgroundColor: " #d70206" }}
                    />
                    <h6 style={headerStyle}>Standard User</h6>
                    <button
                      type="button"
                      className={classes.userBtnStyle}
                      style={{ backgroundColor: "#ffffff" }}
                    />
                    <h6 style={headerStyle}>Premium User</h6>
                  </div>
                </CardFooter>
              )}
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  city: PropTypes.string
};

const mapStateToProps = state => ({
  city: state.city.city,
  pincodes: state.city.pincodes
});
export default connect(
  mapStateToProps,
  null,
  null,
  { pure: true }
)(withStyles(dashboardStyle)(Dashboard));
