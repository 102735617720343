import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {
    Input,
    FormGroup,
    Label,
} from 'reactstrap';

const styles = theme => ({
});
class ManagePermissions extends React.Component {
    state = {
        roles: [],
        permissions: [],
        rolePermissions: [],
        permissionId: '',
        roleId: ''
    };
    selectChange = (e) => {
        console.log(e.target.value)
        this.setState({ roleId: e.target.value })
    }
    onHandleChange = (checked, permissionId) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        if (checked) {
            const assignRolePermission = {
                permissionId: permissionId,
                roleId: this.state.roleId
            }
            Axios.post(`${API_CONFIG.HOST}/role-permission/create`, assignRolePermission, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })
                .then(res => {
                    const rolePermissions = [...this.state.rolePermissions];
                    rolePermissions.push(res.data)
                    this.setState({
                        rolePermissions: rolePermissions
                    })
                })
        }
        else {
            const rolePermission = this.state.rolePermissions.find(data => data.permissionId === permissionId && data.roleId === this.state.roleId);
            Axios.delete(`${API_CONFIG.HOST}/role-permission/delete/${rolePermission.id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })
                .then(res => {
                    const rolePermissions = this.state.rolePermissions.filter(data => data.id !== rolePermission.id)
                    this.setState({
                        rolePermissions: rolePermissions
                    })
                });
        }
    }

    componentDidMount() {
        this.getRoles();
        this.getAllPermission();
        this.getAllRolePermission();
    }
    getRoles = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/role/all`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    roles: res.data,
                    roleId: res.data[0].id
                });
            });
    }
    getAllPermission = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/permission/all`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                function compare(a, b) {
                    const bandA = a.name.toUpperCase();
                    const bandB = b.name.toUpperCase();

                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                    } else if (bandA < bandB) {
                        comparison = -1;
                    }
                    return comparison;
                }
                res.data.sort(compare);
                this.setState({
                    permissions: res.data
                });
            });
    }
    getAllRolePermission = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/role-permission/all`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    rolePermissions: res.data
                });
            });
    }
    render() {
        const { classes } = this.props;
        let permissionData = this.state.permissions.map((row, index) => {
            return {
                index: index + 1,
                permission: row.name,
                permissionId: row.id
            }
        });
        const isChecked = (permissionId) => {
            const role = this.state.rolePermissions.find((role) => role.permissionId === permissionId && role.roleId === this.state.roleId);
            return role ? true : false
        }

        const permissionsView = (permissionViewData) => {
            return (
                <List className={classes.root} style={{ fontSize: "11px", width: "300px" }}>
                    {permissionViewData.map(value => {
                        const labelId = `checkbox-list-label-${value.permission}`;

                        return (
                            <ListItem key={value.index} role={undefined} dense button >
                                <ListItemIcon style={{ minWidth: "50px" }}>
                                    <Checkbox
                                        edge="start"
                                        // value={this.state.permissionId}
                                        checked={isChecked(value.permissionId)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        style={{ color: "#3f51b5" }}
                                        onChange={(event) => this.onHandleChange(event.target.checked, value.permissionId)}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.permission} style={{ marginLeft: "-20px" }} />
                            </ListItem>
                        );
                    })}
                </List>
            )
        }
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <FormGroup>
                    <Label for="exampleSelect">Select Role</Label>
                    <Input type="select" name="select" id="exampleSelect" onChange={this.selectChange} style={{ width: "400px" }}>
                        {this.state.roles.map(role => (
                            <option value={role.id} key={role.id}>{role.name}</option>
                        ))}
                    </Input>
                </FormGroup>
                <div style={{ display: "flex", }}>
                    {permissionsView(permissionData.slice(0, 40))}
                    {permissionsView(permissionData.slice(40, 80))}
                    {permissionsView(permissionData.slice(80, 120))}
                    {permissionsView(permissionData.slice(120))}
                </div>
            </div>
        );
    }
}

ManagePermissions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManagePermissions);
