import React, { Component } from 'react';
import Axios from 'axios'
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import { Checkbox } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './../../../assets/css/userDetailReport.css';
import { API_CONFIG } from "./../../../config/config.jsx";

class UpdateBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerflag: this.props.drawerflag,
      boxes: [],
      deleteflag: false,
      currentbox: "",
      box_id: "",
      snackbar: false,
      boxStatus: false,
      // openUpdateDialog: false,
      updateBoxId: '',
      loading: false,
      powerbankData: []
    }
  }
  componentWillMount() {
    this.setState({
      drawerflag: this.props.drawerflag
    })
  }

  drawerdisplay() {
    this.setState({
      drawerflag: !this.state.drawerflag
    });
  }

  componentDidMount() {
    const token = sessionStorage.getItem("plugoAuthKey");
    Axios.get(`${API_CONFIG.HOST}/stores/${this.props.storeId}/boxes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      console.log(res.data);
      this.setState({
        boxes: res.data,
        drawerflag: true,
      })
      console.log(this.state)
    })
      .catch(err => console.log(err))

  }


  deletebox(info) {
    if (info.sixCharDeviceId == null) {
      this.setState({
        currentbox: info.deviceId,
        box_id: info.id,
        deleteflag: !this.state.deleteflag
      });
    } else {
      this.setState({
        currentbox: info.sixCharDeviceId,
        box_id: info.id,
        deleteflag: !this.state.deleteflag
      });
    }
    console.log(info);
  }

  Assign_box_to_plugo() {
    // To reassign the box to plugo hub
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");

    const storeGroupData = {
      // storeId: "7267e178-6298-414b-85a5-90aae81ef684" // Staging
      storeId: "ab0d9422-3a93-4157-ab2a-2decb3f47e14"     //Production
    };

    Axios.put(`${API_CONFIG.HOST}/boxes/${this.state.box_id}`, storeGroupData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {

        this.setState({
          drawerflag: false,
          snackbar: true,
          deleteflag: false
        }, this.props.drawerdisplay);
      })
      .catch(err => console.log(err));
  }

  closesnack(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      snackbar: false
    });
  }

  closedialog() {
    this.props.drawerdisplay()
    this.setState({
      deleteflag: false
    })
  }
  updateBox = () => {
    const token = sessionStorage.getItem("plugoAuthKey");
    Axios.put(`${API_CONFIG.HOST}/box/${this.state.updateBoxId}/${!this.state.boxStatus}`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        console.log(res);
        const boxes = [...this.state.boxes];
        const updatedBox = boxes.find(box => {
          return box.id === this.state.updateBoxId;
        });
        updatedBox.status = res.data.status;
        this.setState({
          boxStatus: !this.state.boxStatus,
          boxes: [...this.state.boxes, updatedBox]
        }, this.closeStatusDialog())

      })
      .catch(err => console.log(err));
  }
  // handleChange = (boxId, boxStatus) => {
  //   this.setState({
  //     // openUpdateDialog: !this.state.openUpdateDialog,
  //     updateBoxId: boxId,
  //     boxStatus: boxStatus
  //   })
  // }
  // closeStatusDialog = () => {
  //   this.setState({
  //     openUpdateDialog: false
  //   })
  // }
  onChangeExpand = (expanded, boxId) => {
    if (expanded === false) {
      return;
    }
    this.setState({ loading: true })
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");
    Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/box_with_powerbank_stats/${userId}?key=BOX&value=${boxId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      console.log(res.data);
      this.setState({
        powerbankData: res.data[0].powerbankList,
        loading: false
      })
    })
  }
  render() {
    const { classes } = this.props;
    let boxes = (
      <div style={{ marginBottom: "40px" }}>
        <div
          className="col-xl-12 col-lg-12 row mt-3 p-1"
          style={{ width: "800px" }}
        >
          <p className="col-xl-4 col-lg-5">
            <b>DeviceId</b>
          </p>

          <p className="col-xl-3 col-lg-3">
            <b>Six Charid</b>
          </p>
          <div className="col-xl-2 col-lg-3">
            <b>Action</b>
          </div>
          <p className="col-xl-2 col-lg-3">
            <b>Box Status</b>
          </p>
        </div>
        {this.state.boxes.map((elem, index) => {
          return (
            <React.Fragment key={index}>
              <ExpansionPanel onChange={(event, expanded) => this.onChangeExpand(expanded, elem.sixCharDeviceId)}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon titleAccess="View Powerbanks" />}
                  aria-controls="panel1a-content"
                  aria-label="Expand"
                  id="panel1a-header"
                  style={{ padding: "0 20px 0 8px" }}
                >
                  <div className="col-xl-12 col-lg-10  row p-2 ">
                    <p className="col-xl-4 col-lg-5 text-left">{elem.deviceId}</p>
                    <p className="col-xl-3 col-lg-3 text-center">{elem.sixCharDeviceId}</p>
                    <div className="col-xl-2 col-lg-3 text-right">
                      <button
                        style={{ width: "30px", height: "30px", padding: "0px" }}
                        className="btn btn-danger rounded-circle"
                        onClick={() => this.deletebox(elem)}
                      > X </button>
                    </div>
                    <div className="col-xl-2 col-lg-3 text-right">
                      <Switch
                        checked={elem.status}
                        // value={this.state.boxStatus}
                        // disabled
                        // onChange={() => this.handleChange(elem.id, elem.status)}
                        color="primary"
                        name="checkedB"
                        // onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                  </div>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  {this.state.loading ? <div className='loading-spinner' style={{ position: 'absolute' }}></div> : null}
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Powerbank Id</TableCell>
                        <TableCell align="right">Healthy</TableCell>
                        <TableCell align="right">Available</TableCell>
                        <TableCell align="right">Slot</TableCell>
                        <TableCell align="right">Charge</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.powerbankData.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.deviceId}
                          </TableCell>
                          <TableCell align="right"><Checkbox checked={row.healthy} color="primary" /></TableCell>
                          <TableCell align="right"><Checkbox checked={row.available} color="primary" /></TableCell>
                          <TableCell align="right">{row.slot}</TableCell>
                          <TableCell align="right">{row.charge}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </React.Fragment>
          );
        })}
      </div>
    );


    return (
      <div>
        <Dialog open={this.props.drawerflag} onClose={() => this.closedialog()} maxWidth="md">
          <div style={{ padding: "5px 25px" }}>
            <div className="text-center mt-1">
              <h4>Store Name: {this.props.storeName}</h4>
            </div>

            {this.state.deleteflag ? (
              <div className="row col-xl-12  mb-3 ">
                <div className="col-xl-12 text-center">
                  <b className="mt-2">
                    Are you sure you want to assign{" "}
                    <span className="text-primary">
                      BOXID:{this.state.currentbox}
                    </span>{" "}
                  back to PlugoHub
                </b>
                </div>
                <div className="col-xl-12 mt-2 ml-4 row justify-content-center d-flex">
                  <button
                    className="btn btn-success col-xl-3 mr-2 "
                    onClick={() => this.Assign_box_to_plugo()}
                  >
                    Yes
                </button>
                  <button
                    className="btn btn-danger col-xl-3"
                    onClick={() =>
                      this.setState({
                        deleteflag: !this.state.deleteflag
                      })
                    }
                  >
                    No
                </button>
                </div>
              </div>
            ) : (
                <React.Fragment>{boxes}</React.Fragment>
              )}
          </div>
        </Dialog>
        {/* <Dialog open={this.state.openUpdateDialog} onClose={this.closeStatusDialog} maxWidth="md">
          <DialogTitle id="simple-dialog-title" className="text-center">Are you sure you want to update box</DialogTitle>
          <div className="d-flex justify-content-center row col-xl-12 p-4">
            <button className="btn btn-success col-xl-4" onClick={this.updateBox}>Yes</button>
            <button className="btn btn-danger col-xl-4 ml-4" onClick={this.closeStatusDialog}>No</button>
          </div>
        </Dialog> */}
        <React.Fragment>
          <Snackbar
            open={this.state.snackbar}
            message="Box assigned back to Plugo"
            onClose={() => this.closesnack()}
            autoHideDuration={2000}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.closesnack}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </React.Fragment>
      </div>
    );
  }
}

export default UpdateBox;