import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import NotificationAlert from "react-notification-alert";
import { API_CONFIG } from "../../../config/config.jsx";
import { Button, Table } from 'reactstrap';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
    imgStyle: {
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '5px',
        width: '150px',

        "&:hover": {
            background: "#efefef"
        },
    }
});

class UploadImage extends React.Component {
    state = {
        excelFile: '',
        imagePreviewUrl: null,
        typeValue: false
    };
    onToggleChange = () => {
        this.setState({
            typeValue: !this.state.typeValue
        })
    }
    onChangeHandler = event => {
        console.log(event.target.files[0]);
        this.setState({
            excelFile: event.target.files[0]
        });
        let reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(event.target.files[0])
    }
    onSubmit = () => {
        console.log(this.props.typeValue)
        const data = new FormData();
        const title = this.state.excelFile.name.split('.');
        data.append('file', this.state.excelFile);
        const typeValue = this.state.typeValue
        const type = typeValue === true ? 'LOGO' : 'BANNER'
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.post(`${API_CONFIG.HOST}/uploads?title=${title[0]}&type=${type}&parent=STORE&parentId=${this.props.storeId}`, data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res);
                this.setState({
                    excelFile: ''
                })
                this.props.submitClose();
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            });
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <h5 style={{ fontWeight: "bold" }} id="simple-dialog-title">Upload Store Image</h5>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'baseline', marginLeft: '60px' }}>
                        <p style={{ marginRight: '-2px', fontWeight: 'bold' }}>Banner</p>
                        <Switch onChange={this.onToggleChange} color="primary" />
                        <p style={{fontWeight: 'bold' }}>Logo</p>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <input type="file" name="file" onChange={this.onChangeHandler} />
                    <Button onClick={this.onSubmit} variant="contained" color="primary" >Upload</Button>
                </div>
                {this.state.imagePreviewUrl ?
                    <img src={this.state.imagePreviewUrl} className={classes.imgStyle} /> : ''}
            </div>
        );
    }

}
UploadImage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadImage);