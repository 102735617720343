import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Axios from 'axios';
import { API_CONFIG } from "../../config/config.jsx";
import ReactTable from "react-table";
import editicon from "./../../assets/img/edit.png";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox } from '@material-ui/core';
import { DialogContent } from "@material-ui/core";
import ViewMembers from "./ViewMembers.jsx";
import CreateAccount from "./CreateAccount.jsx";
import UpdatePlan from "./UpdatePlan.jsx";
import { Button } from 'reactstrap';
import Secure from '../../components/Secure.jsx';

var Moment = require("moment");



const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
});

class UserGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userGroupData: [],
            open: false,
            showTextField: false,
            accountId: '',
            accountMembers: [],
            planDialog: false,
            userAccount: []
            // amount: '',
            // interval: ''

        }
    }
    handleOpen = (data) => {
        this.setState({
            open: true,
            accountId: data.original.accountId,
            accountMembers: data.original.accountMembers
        });
    }
    handleClose = (res) => {
        this.setState({
            open: false
        });
        if (res.status === 200 || res.status === 201) {
            var options = {
                place: 'tc',
                message: " " + "Upload Successfully",
                type: 'success',
                icon: 'far fa-check-circle',
                autoDismiss: 4
            }
            if (this.notification && this.notification.state.notifyTR.length < 1) {
                this.notification.notificationAlert(options)
            }
        }
    }
    handleCreateAccount = () => {
        this.setState({
            showTextField: !this.state.showTextField
        });
    }
    componentDidMount() {
        this.getAccounts();
    }
    getAccounts = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/accounts`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                console.log(res);
                this.setState({
                    userGroupData: res.data,
                });
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
    }
    handleClickOpen = (id, userAccount) => {
        this.setState({
            planDialog: true,
            accountId: id,
            userAccount: userAccount
        });
    };
    handlePlanDialog = () => { this.setState({ planDialog: false }) }

    handleCloseEditDialog = () => {
        var options = {
                    place: 'tc',
                    message: " " + "Updated Successfully",
                    type: 'success',
                    icon: 'far fa-check-circle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
        this.setState({
            planDialog: false
        });
        this.getAccounts();
    }

    render() {
        const { classes } = this.props;
        let userGroupData = this.state.userGroupData.map((row, index) => {
            return {
                accountId: row.id,
                accountMembers: row.accountMembers,
                name: row.name,
                domainName: row.domainName,
                restricted: row.restricted,
                memberLimit: row.memberLimit,
                floatingLicenceEnabled: row.floatingLicenceEnabled,
                amount: row && row.plan && row.plan.amount,
                interval: row && row.plan && row.plan.interval,

            }
        });
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <Card>
                    <CardHeader color="success" >
                        <h4 className={classes.cardTitleWhite}>User Groups</h4>
                    </CardHeader>
                    <CardBody>
                        {Secure("CREATE_ACCOUNT", <Button onClick={this.handleCreateAccount} variant="contained" color="primary" style={{ marginBottom: "5px" }}>Create Account</Button>)}
                        <br />
                        {this.state.showTextField ? <CreateAccount /> : ''}
                        <br />
                        {Secure("READ_ACCOUNTS",
                            <ReactTable
                                data={userGroupData}
                                onFetchData={this.fetchData}

                                columns={[
                                    {
                                        columns: [
                                            {
                                                headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400" },
                                                Header: "Name",
                                                accessor: "name"
                                            },
                                        ]
                                    },
                                    {
                                        columns: [
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400" },
                                                Header: "Domain Name",
                                                accessor: "domainName"
                                            },

                                        ]
                                    },
                                    {
                                        columns: [
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400", color: "blue" },
                                                Header: "View Member",
                                                Cell: row => (
                                                    <a onClick={() => this.handleOpen(row)} style={{ cursor: "pointer" }} >View</a>)
                                            },

                                        ]
                                    },
                                    {
                                        // Header: "View Member",
                                        columns: [
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400", color: "blue" },
                                                Header: "Restricted",
                                                Cell: row => (
                                                    <Checkbox checked={row.original.restricted} color="primary" />)
                                            },

                                        ]
                                    },
                                    {
                                        columns: [
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400" },
                                                Header: "Member Limit",
                                                accessor: "memberLimit"
                                            },

                                        ]
                                    },
                                    {
                                        columns: [
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400", color: "blue" },
                                                Header: "Floating Licence Enabled",
                                                Cell: row => (
                                                    <Checkbox checked={row.original.floatingLicenceEnabled} color="primary" />)
                                            },

                                        ]
                                    },
                                    {
                                        Header: "View Plan",
                                        columns: [
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400" },
                                                Header: "Amount",
                                                accessor: "amount",
                                                // Cell: this.renderEditable
                                            },
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400" },
                                                Header: "Interval",
                                                accessor: "interval",
                                            },
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '450' },
                                                style: { textAlign: "center", fontWeight: "400" },
                                                Header: "Update Plan",
                                                Cell: (rowInfo) => {
                                                    return (
                                                        <div>
                                                            <img src={editicon} onClick={() => { this.handleClickOpen(rowInfo.original.accountId, rowInfo) }} style={{ height: "15px", cursor: "pointer" }} ></img>
                                                        </div>
                                                    );
                                                },
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                ref={(r) => this.reactTable = r}
                            />)}
                    </CardBody>
                </Card>
                <Dialog onClose={this.handleClose} open={this.state.open} maxWidth="lg" aria-labelledby="simple-dialog-title">
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "-20px" }}>
                        <DialogTitle id="simple-dialog-title">View Members</DialogTitle>
                        <DialogTitle id="simple-dialog-title" style={{ marginLeft: "70%" }}><a style={{ color: "black" }} title="Download Excel Sheet" href="https://goblin-store.s3.ap-south-1.amazonaws.com/Templates/add_user_template.xlsx"><i class="fa fa-download" aria-hidden="true"></i></a></DialogTitle>
                    </div>
                    <DialogContent>
                        <ViewMembers accountMembers={this.state.accountMembers} submitClose={this.handleClose} accountId={this.state.accountId} />
                    </DialogContent>
                </Dialog>
                <Dialog
                    // minWidth='700px'
                    fullWidth={true}
                    open={this.state.planDialog}
                    onClose={this.handlePlanDialog}
                >
                    <DialogTitle id="simple-dialog-title">Update Plan</DialogTitle>
                    <DialogContent style={{marginTop: "-20px"}}>
                        <UpdatePlan accountId={this.state.accountId} userAccountData={this.state.userAccount} submitClose={this.handleCloseEditDialog}/>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

UserGroups.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserGroups)