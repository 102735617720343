import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.jsx";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DownloadIcon from "assets/img/DownloadIcon.png";
import UploadIcon from "assets/img/UploadIcon.png";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TransactionReportByPin from "./TransactionReportByPin/TransactionReportByPin.jsx";
import TransactionReportByStore from "./TransactionReportByStore/TransactionReportByStore.jsx";
import DateRangeTransaction from "./DateRangeTransaction.jsx";
import DatePickedByPin from "./TransactionReportByPin/DatePickedtByPin.jsx";
// import TransactionReportByUserType from "./TransactionReportByUserType.jsx";
import Axios from "axios";
import { API_CONFIG } from "../../../config/config.jsx";
import ReactTable from "react-table";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  form: {
    marginLeft: "40%"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

class TransactionReportByCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionDataByCity: [],
      city: "",
      pincodes: [],
      open: false,
      showReport: false,
      startDate: "",
      endDate: "",
      selectedPin: ""
    };
  }
  showReport = () => {
    this.setState({
      showReport: true
    });
  };
  showDefaultReport = showDefaultReport => {
    this.setState({
      showReport: showDefaultReport
    });
  };
  fetchStandardReportData = (startDate, endDate, showReport) => {
    this.setState({
      startDate: startDate,
      endDate: endDate,
      showReport: showReport
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  componentDidMount = () => {
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");

    Axios.get(
      `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/transactions/city/${userId}`
    ).then(res => {
      this.setState({
        transactionDataByCity: res.data
      });
    });
  };
  onSelectedPin = pin => {
    this.setState({
      selectedPin: pin
    });
  };
  render() {
    const { classes } = this.props;
    let filteredCity = this.state.transactionDataByCity.filter(
      selectCity => selectCity.city === this.props.city
    );
    let dailyDispensed;
    let dailyReturned;
    let weeklyDispensed;
    let weeklyReturned;
    let monthlyDispensed;
    let monthlyReturned;
    let transactionDataByCity = filteredCity.map((row, index) => {
      dailyDispensed = row.dailyDespensed;
      dailyReturned = row.dailyReturned;
      weeklyDispensed = row.weeklyDespensed;
      weeklyReturned = row.weeklyReturned;
      monthlyDispensed = row.monthlyDespensed;
      monthlyReturned = row.monthlyReturned;
    });
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "-35px",
            marginBottom: "-22px"
          }}
        >
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Daily
              </Typography>
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                {dailyDispensed}
              </span>
              <img title="Despensed" width={50} height={50} src={UploadIcon} />
              <img title="Returned" width={50} height={50} src={DownloadIcon} />
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                {dailyReturned}
              </span>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Weekly
              </Typography>
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                {weeklyDispensed}
              </span>
              <img title="Despensed" width={50} height={50} src={UploadIcon} />
              <img title="Returned" width={50} height={50} src={DownloadIcon} />
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                {weeklyReturned}
              </span>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Monthly
              </Typography>
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                {monthlyDispensed}
              </span>
              <img title="Despensed" width={50} height={50} src={UploadIcon} />
              <img title="Returned" width={50} height={50} src={DownloadIcon} />
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                {monthlyReturned}
              </span>
            </CardContent>
          </Card>
        </div>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>
              Power Bank Rental Report By PIN
            </h4>
          </CardHeader>
          <CardBody>
            <DateRangeTransaction
              showReport={this.showReport}
              showDefaultReport={this.showDefaultReport}
              fetchStandardReportData={this.fetchStandardReportData}
            />
            {this.state.showReport ? (
              <DatePickedByPin
                city={this.props.city}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onSelectedPin={this.onSelectedPin}
              />
            ) : (
              <TransactionReportByPin
                city={this.props.city}
                onSelectedPin={this.onSelectedPin}
              />
            )}
          </CardBody>
        </Card>
        <TransactionReportByStore
          showDateReport={this.state.showReport}
          city={this.props.city}
          pincodes={this.props.pincodes}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          selectedPin={this.state.selectedPin}
        />
      </div>
    );
  }
}
TransactionReportByCity.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  city: state.city.city,
  pincodes: state.city.pincodes
});
export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(withStyles(styles)(TransactionReportByCity));
