import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import { API_CONFIG } from "../../config/config.jsx";
import Axios from 'axios';
import Button from '@material-ui/core/Button';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

const styles = theme => ({
  saveButton: {
    marginLeft: "20%",
    marginTop: "40px",
    marginBottom: "30px",
    backgroundColor: "#0088cc"
  },
});

class AddStoreToGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      arrayValue: [],
      storesData: [],
      storeIds: []
    }
  }
  selectMultipleOption = (value) => {
    console.log(value);
    const storesIdArray = value.map(selectId => (
      selectId.id
    ))
    console.log(storesIdArray);
    this.setState({
      arrayValue: value,
      storeIds: storesIdArray
    });
  }
  componentDidMount() {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    Axios.get(`${API_CONFIG.HOST}/store/all`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        this.setState({
          storesData: res.data
        })
      });
  }
  submit = event => {
    event.preventDefault();
    const storeGrpIds = {
      groupId: this.props.groupId,
      storeIds: this.state.storeIds
    }
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    Axios.post(`${API_CONFIG.HOST}/assign/group/store`, storeGrpIds, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        console.log(res);
      });
  };
  render() {
    const { classes } = this.props;
    const selectStores = this.state.storesData.map(storesInfo => ({
      id: storesInfo.id,
      name: `${storesInfo.name}\t${storesInfo.pincode}\t${storesInfo.city}`
    }));
    return (
      <GridContainer>
        <form className={classes.container} onSubmit={this.submit.bind(this)} >
          <GridItem xs={12} sm={12} md={6}>
            <div>
              <div className="container" style={{paddingLeft:"1px"}}>
                <div>Select Stores</div>
                <div style={{maxWidth:"100%",width:"77vw"}}>
                <Picky
                  value={this.state.arrayValue}
                  options={selectStores}
                  onChange={this.selectMultipleOption}
                  open={false}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  allSelectedPlaceholder={"All item selected"}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={500}
                // render={pickRender}
                />
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <Button type="submit" variant="contained" color="primary" className={classes.saveButton}>
                SAVE
              </Button>
            </div>
          </GridItem>
        </form>
      </GridContainer>
    );
  }
}

AddStoreToGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddStoreToGroup);