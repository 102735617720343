import React, { Component } from "react";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import {
  Card, Button, CardFooter, CardBody, FormGroup, Input, Container, Col, Form, FormFeedback, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { login } from './../actions/auth';
import 'bootstrap/dist/css/bootstrap.css';
import './../assets/css/login.css';

class Login extends Component {
  state = {
    mobile: "",
    password: "",
    mobileState: "",
    passwordState: "",
    loading: false,
    countryCode: ''
  }
  componentDidMount() {
    if (process.env.REACT_APP_ACTIVE_REGION == "europe") {
      this.setState({
        countryCode: '+49'
      })
    }
    else {
      this.setState({
        countryCode: '+91'
      })
    }
  }
  selectCountryCode = (e) => {
    this.setState({
      countryCode: e.target.value
    })
  }
  componentWillReceiveProps(props) {
    if (!props.resStatus && props.resMessage) {
      this.setState({ loading: false })
      let options = {
        place: "tc",
        message: '  ' + props.resMessage,
        type: "warning",
        icon: "fas fa-exclamation-triangle",
        autoDismiss: 4
      };
      if (this.refs.notificationAlert.state.notifyTC.length < 1) {
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  verifyMobileNo = value => {
    var mobileNoRex = /^\d{10}$/;
    if (mobileNoRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyMobileNo = value => {
    var mobileNoRex = /^\d{10}$/;
    if (mobileNoRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyEuMobileNo = value => {
    var mobileNoRex = /^\d{9}$/;
    if (mobileNoRex.test(value)) {
      return true;
    }
    return false;
  }
  change = (event, stateName, type) => {
    switch (type) {
      case "mobile":
        if (this.verifyMobileNo(event.target.value) || this.verifyEuMobileNo(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }


  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.mobileState === "has-success" && this.state.passwordState === "has-success") {
      this.props.login({
        mobile: this.state.countryCode + this.state.mobile,
        password: this.state.password,
        Language: this.props.locale
      })

      this.setState({ loading: true })
    } else if (this.state.mobileState === "" && this.state.passwordState === "") {
      this.setState({ mobileState: "has-danger" });
      this.setState({ passwordState: "has-danger" });
    } else if (this.state.mobileState === "") {
      this.setState({ mobileState: "has-danger" });
    } else if (this.state.passwordState === "") {
      this.setState({ passwordState: "has-danger" });
    }
  }

  render() {
    let { mobileState, passwordState } = this.state
    return (
      <div className="content">
        {this.state.loading ? <div className='loading-spinner'></div> : ''}
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={this.handleSubmit}>
              <div className='login-form'>
                <Card className='login-card'>
                  <div>
                    <img src="./plugo-logo.png" style={{ width: "70%", display: "block", margin: "0 auto", paddingBottom: "70px" }} alt="Logo" />
                  </div>
                  <CardBody>
                    <FormGroup className={`has-label ${mobileState}`}>
                      <label>
                        Mobile *
                      </label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          {process.env.REACT_APP_ACTIVE_REGION == "europe" ?
                            <Input style={{ backgroundColor: "#E9ECEF", padding: "1px 3px", border: '1px solid #E9ECEF' }} type="select" onChange={this.selectCountryCode} name="select" id="exampleSelect">
                              <option value='+49'>+49</option>
                              <option value='+31'>+31</option>
                            </Input> :
                            <InputGroupText style={{ border: '1px solid #E9ECEF' }}>
                              <i className="fas fa-mobile-alt" />
                            </InputGroupText>
                          }
                        </InputGroupAddon>
                        <Input
                          name="mobile"

                          autoComplete="off"
                          valid={mobileState === "has-success"}
                          invalid={mobileState === "has-danger"}
                          placeholder="Enter Your Mobile No"
                          onChange={e => this.change(e, "mobile", "mobile")}
                          style={{ border: '1px solid #ffffff' }}
                          autocapitalize="off"
                        />
                      </InputGroup>
                      <FormFeedback
                        valid={mobileState === "has-success"}
                        invalid={mobileState === "has-danger"}
                      >
                        {
                          this.state.mobileState === "has-danger" ? 'Mobile No is required.' : <span></span>
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup className={`has-label ${passwordState}`}>
                      <label>
                        Password *
                      </label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ border: '1px solid #E9ECEF' }}>
                            <i className="fas fa-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="off"
                          placeholder="Enter Your Password"
                          valid={passwordState === "has-success"}
                          invalid={passwordState === "has-danger"}
                          onChange={e =>
                            this.change(e, "password", "password")
                          }
                          style={{ border: '1px solid #ffffff' }}
                        />
                      </InputGroup>
                      <FormFeedback
                        valid={passwordState === "has-success"}
                        invalid={passwordState === "has-danger"}
                      >
                        {this.state.passwordState === "has-danger" ? (
                          'Password is required.'
                        ) :
                          <span></span>
                        }
                      </FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <FormGroup>
                      <Button
                        block
                        color="success"
                        size="lg"
                        disabled={!(mobileState === "has-success" && passwordState === "has-success")}
                      >
                        Login
                      </Button>
                    </FormGroup>
                  </CardFooter>
                </Card>
              </div>
            </Form>
          </Col>
        </Container>
      </div >
    )
  }
}

const mapStateToProps = (state, props) => {
  return ({
    resMessage: state.auth.resMessage,
    resStatus: state.auth.resStatus
  })
}

export default connect(mapStateToProps, { login }, null, { pure: false })(Login);