export const changeCity = (
  city = '',
  pincodes = []) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        city,
        pincodes
      }
    })
  }
}