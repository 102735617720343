import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import storeOnboardIcon from "assets/img/storeOnboardIcon.png";
import UpdateDispenserIcon from "assets/img/UpdateDispenser.png"
import ManagePermissionIcon from "assets/img/assignRoleIcon.png";
import StoreOnBoardForm from "./../StoreOnBoard/StoreOnBoardForm.jsx";
import UpdateDispenser from "./../UpdateDispenser/UpdateDispenser.jsx";
import UpdateEditStore from "./../UpdateStore/UpdateEditStore.jsx";
import ManagePermissions from "./../ManagePermissions/ManagePermissions.jsx";
import Secure from '../../../components/Secure.jsx';

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  imgStyle: {
    width: 30,
    height: 35
  }
});

class ReportTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            {Secure("CREATE_STORE", <Tab label="Store OnBoard" icon={<img className={classes.imgStyle} src={storeOnboardIcon}></img>} />)}
            {Secure("UPDATE_BOXES", <Tab label="Update Dispenser" icon={<img className={classes.imgStyle} src={UpdateDispenserIcon}></img>} />)}
            {Secure("SUPER_ADMIN", <Tab label="Manage Permissions" icon={<img className={classes.imgStyle} src={ManagePermissionIcon}></img>} />)}
          </Tabs>
        </AppBar>
        {this.state.value === "" ? Secure("UPDATE_STORE", <UpdateEditStore />) : ''}
        {this.state.value === 0 && <TabContainer><StoreOnBoardForm /></TabContainer>}
        {this.state.value === 1 && <TabContainer><UpdateDispenser /></TabContainer>}
        {this.state.value === 2 && <TabContainer><ManagePermissions /></TabContainer>}
      </div>
    );
  }
}

ReportTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportTabs);