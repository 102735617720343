import React from 'react';
import PropTypes from 'prop-types';
import NotificationAlert from "react-notification-alert";
import { withStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import { Button, Checkbox } from '@material-ui/core';
import { API_CONFIG } from "./../../../config/config.jsx";
import Autocomplete from 'react-google-autocomplete';
import Axios from 'axios';



const styles = theme => ({
    textField: {
        width: "150px"
    }
});
const google = window.google;

class UpdateStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storeId: this.props.storeData.id,
            name: this.props.storeData.name,
            password: '',
            address: this.props.storeData.address,
            city: this.props.storeData.city,
            floor: this.props.storeData.floor,
            pincode: this.props.storeData.pincode,
            state: this.props.storeData.state,
            latitude: this.props.storeData.latitude,
            longitude: this.props.storeData.longitude,
            storeNames: [],
            isVisible: this.props.storeData.isVisible,
        };
        // this.autocompleteInput = React.createRef();
        // this.autocomplete = null;
        // this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    }
    // componentDidMount() {
    //     this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
    //       {
    //         componentRestrictions: { country: 'IN' },
    //         // types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
    //       });
    
    //     this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    //   }
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    // handlePlaceChanged() {
    //     const event = this.autocomplete.getPlace();
    //     console.log(event);
    //     this.setState({
    //         name: event['name'],
    //         address: event['formatted_address'],
    //         latitude: event['geometry']['location'].lat(),
    //         longitude: event['geometry']['location'].lng(),
    //     })
    //     event['address_components'].forEach((component) => {
    //         if (component['types'].includes('postal_code')) {
    //             this.setState({
    //                 pincode: component['long_name']
    //             })
    //         }
    //     });

    //     Axios.get(`https://api.postalpincode.in/pincode/${this.state.pincode}`)
    //         .then(res => {
    //             this.setState({
    //                 floor: 'Ground Floor',
    //                 city: res.data[0]['PostOffice'][0]['District'],
    //                 state: res.data[0]['PostOffice'][0]['State'],
    //                 business: 'Restaurant',
    //                 password: 'rudreshrd',
    //             });
    //         });
            
    // }


    submit = event => {
        event.preventDefault();
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        const createStoreData = {
            name: this.state.name,
            address: this.state.address,
            city: this.state.city,
            floor: this.state.floor,
            pincode: this.state.pincode,
            state: this.state.state,
            lat: this.state.latitude,
            lng: this.state.longitude,
            isVisible: this.state.isVisible
        }

        Axios.put(`${API_CONFIG.HOST}/stores/${this.state.storeId}/update`, createStoreData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                this.setState({
                    name: '',
                    password: '',
                    address: '',
                    city: '',
                    floor: '',
                    pincode: '',
                    state: '',
                    latitude: '',
                    longitude: '',
                })
                // const storeId = res.data.id
                // const filterStores = this.state.storeNames.filter(storeData => {
                //     return storeData.id !== storeId
                // });
                // filterStores.push(res.data);
                // this.setState({
                //     storeNames: filterStores
                // })
                this.props.submitClose();
                var options = {
                    place: 'tc',
                    message: " " + "Updated Successfully",
                    type: 'success',
                    icon: 'far fa-check-circle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
    };

    render() {
        const { classes } = this.props;
        console.log(this.props.storeData)
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                {/* <div style={{ width: "95%", border: "1px #A9A9A9 solid", height: "35px", paddingLeft: "8px" }}>
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input ref={this.autocompleteInput} id="autocomplete" style={{ width: "95%", border: "none", marginTop: "4px", marginLeft: "4px" }} placeholder="Search Store for autocomplete..."
                        type="text">
                    </input>
                </div> */}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <form className={classes.container} onSubmit={this.submit}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        required
                                        id="outlined-name"
                                        label="Name"
                                        value={this.state.name}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('name')}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="outlined-address"
                                        label="Address"
                                        value={this.state.address}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('address')}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="outlined-floor"
                                        label="Floor"
                                        value={this.state.floor}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('floor')}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="outlined-city"
                                        label="City"
                                        value={this.state.city}
                                        onChange={this.handleChange('city')}
                                        className={classes.textField}
                                        margin="normal"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="outlined-pincode"
                                        label="Pincode"
                                        value={this.state.pincode}
                                        onChange={this.handleChange('pincode')}
                                        className={classes.textField}
                                        margin="normal"
                                        type="number"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="outlined-state"
                                        label="State"
                                        value={this.state.state}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('state')}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="outlined-pincode"
                                        label="Latitude"
                                        value={this.state.latitude}
                                        onChange={this.handleChange('latitude')}
                                        className={classes.textField}
                                        margin="normal"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="outlined-state"
                                        label="Longitude"
                                        value={this.state.longitude}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('longitude')}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                </GridItem>
                            </GridContainer>
                            <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                                <Button type="submit" variant="contained" color="primary" onClick={this.handleNext} >
                                    Update
                                    </Button>
                            </div>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

UpdateStore.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpdateStore);
