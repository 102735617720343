import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import axios from 'axios';

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    textField: {
        width: "200px"
    }
});
class AddSalesLead extends React.Component {
    state = {
        storeName: '',
        firstName: '',
        lastName: '',
        primaryPhoneNo: '',
        secondaryPhoneNo: '',
        address: '',
        floor: '',
        pin: '',
        email: '',
        website: '',
        comments: '',
        errorText: ''
    };
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    numberHandleChange =  name => event => {
        let phoneRegex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/  
        if (event.target.value.match(phoneRegex)) {
            this.setState({ errorText: '' })
          } else {
            this.setState({ errorText: 'Invalid format' })
          }
        this.setState({
            [name]: event.target.value,
        });
    };

    submit = event => {
        event.preventDefault();

        const newSalesData = {
            contactPerson: {
                firstName: this.state.firstName,
                lastName: this.state.lastName
            },
            phoneNo: {
                primaryPhoneNo: this.state.primaryPhoneNo,
                secondaryPhoneNo: this.state.secondaryPhoneNo
            },
            Address: {
                address: this.state.address,
                floorNo: this.state.floor,
                pincode: this.state.pin
            },
            email: this.state.email,
            Website: this.state.website,
            comments: this.state.comments,
            storeName: this.state.storeName
        }

        axios.post('/sales/leads', newSalesData)
            .then(res => {
                console.log(res);
                this.setState({
                    storeName: '',
                    firstName: '',
                    lastName: '',
                    primaryPhoneNo: '',
                    secondaryPhoneNo: '',
                    address: '',
                    floor: '',
                    pin: '',
                    email: '',
                    website: '',
                    comments: ''
                })
            })

    };
    render() {
        const buttonStyle = {
            position: 'relative',
            marginLeft: '40%',
            marginTop: '5%'
        };

        const { classes } = this.props;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="success">
                                <h4 className={classes.cardTitleWhite}>Add Sales Lead</h4>
                            </CardHeader>
                            <CardBody>
                                <form className={classes.container} onSubmit={this.submit.bind(this)} >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                required
                                                id="outlined-storeName"
                                                label="Store Name"
                                                value={this.state.storeName}
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('storeName')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                required
                                                id="outlined-firstName"
                                                label="First Name"
                                                value={this.state.firstName}
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('firstName')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                required
                                                id="outlined-lastName"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('lastName')}
                                                
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                required
                                                id="outlined-primaryPhoneNo"
                                                label="Primary Phone No"
                                                value={this.state.primaryPhoneNo}
                                                type="number"
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.numberHandleChange('primaryPhoneNo')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                id="outlined-secondaryPhoneNo"
                                                label="Secondary Phone No"
                                                value={this.state.secondaryPhoneNo}
                                                type="number"
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('secondaryPhoneNo')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                required
                                                id="outlined-address"
                                                label="Address"
                                                value={this.state.address}
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('address')}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                id="outlined-floor"
                                                label="Floor"
                                                value={this.state.floor}
                                                type="number"
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('floor')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                required
                                                id="outlined-pin"
                                                label="Pin"
                                                value={this.state.pin}
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('pin')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                req
                                                id="outlined-email"
                                                label="Email"
                                                value={this.state.email}
                                                type="email"
                                                name="email"
                                                autoComplete="email"
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('email')}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextField
                                                id="outlined-website"
                                                label="Website"
                                                value={this.state.website}
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('website')}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={8}>
                                            <TextField
                                                id="outlined-comments"
                                                label="Comments"
                                                value={this.state.comments}
                                                multiline
                                                rowsMax="4"
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.handleChange('comments')}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <div style={buttonStyle}>
                                        <Button type="submit" variant="contained" size="small" className={classes.button}>
                                            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                            Save
                                        </Button>
                                    </div>

                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

}
AddSalesLead.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddSalesLead);