import axios from 'axios';
import { API_CONFIG } from "./../config/config.jsx";

export const login = ({ mobile = '', password = '' }) => {
  return async (dispatch) => {
    axios.post(`${API_CONFIG.HOST}/auth/login`, {
      mobile,
      password,
    }).then((response) => {
      console.log(response.data.data);
      sessionStorage.setItem('plugoAuthKey', response.data.data.token);
      sessionStorage.setItem('plugoUserId', response.data.data.user.id);
      sessionStorage.setItem('plugoUserMobileNo', response.data.data.user.mobile);
      sessionStorage.setItem('authoritiesList', response.data.data.user.authoritiesList);
      dispatch({
        type: 'LOGIN',
        payload: {
          mobile,
          password,
          token: response.data.data.token,
          userId: response.data.data.user.id,
          resStatus: true
        }
      })
    }).catch((error) => {
      if (error.response) {
        dispatch({
          type: 'LOGIN',
          payload: {
            mobile,
            password,
            resStatus: false,
            resMessage: error.response.data.message
          }
        });
      } else {
        dispatch({
          type: 'LOGIN',
          payload: {
            mobile,
            password,
            resStatus: false,
            resMessage: 'Server is unavailable.'
          }
        });
      }
    });
  }
}

export const logout = ({ token = '', userId = '' } = {}) => {
  return async (dispatch) => {
    if (!token) {
      token = await sessionStorage.getItem('plugoAuthKey');
    }
    if (!userId) {
      userId = await sessionStorage.getItem('plugoUserId');
    }
    await sessionStorage.removeItem('plugoAuthKey');
    await sessionStorage.removeItem('plugoUserId');
    await sessionStorage.removeItem('authoritiesList');
    axios.get(`${API_CONFIG.HOST}/users/logout`, {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    }).then(() => {
      dispatch({
        type: 'LOGOUT'
      })
    }).catch(error => {
      dispatch({
        type: 'LOGOUT'
      })
    })
  }
}
