import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from "@material-ui/core";
import AddButton from './AddButton';
import AddCouponMapping from './AddCouponMapping.jsx';
import ReactTable from "react-table";
import { API_CONFIG } from "../../config/config.jsx";
import Axios from 'axios';
import Secure from '../../components/Secure.jsx';


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
            marginLeft: "10px"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "5px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        marginLeft: "10px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};
class CouponMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            couponMapData: [],
            open: false
        }
    }
    openAddCouponMappingDailog = () => {
        this.setState({
            open: true
        });
    }
    handleClose = () => {
        this.setState({
            open: false
        });
    }
    onSubmit = (couponRes) => {
        if (couponRes === 200) {
            this.setState({
                open: false
            })
        }
        this.getCouponMapping();
    }
    componentDidMount() {
        this.getCouponMapping();
    }
    getCouponMapping = () => {
        const token = sessionStorage.getItem('plugoAuthKey');

        Axios.get(`${API_CONFIG.HOST}/coupons/${this.props.selectedCouponId}/mappings`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res.data)
                this.setState({
                    couponMapData: res.data
                });
            })
    }
    render() {
        const style = {
            float: 'right',
            position: 'relative',
            top: -53
        }
        const { classes } = this.props;
        let couponMapData = this.state.couponMapData.map((row, index) => {

            if (row.applicableType === 'SUBSCRIPTION' || row.applicableType === 'USER') {
                return {
                    applicableType: row.applicableType,
                    entityName: row.userName
                }
            }
            else {
                return {
                    applicableType: row.applicableType,
                    entityName: row.storeName
                }
            }
        });

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{ padding: 0, height: "75px" }} color="success">
                            <h4 className={classes.cardTitleWhite}>Coupon Mapping({this.props.couponName})</h4>
                            <p className={classes.cardCategoryWhite}>Manage Coupon Mapping</p>
                            {Secure("CREATE_COUPON_MAPPINGS", <div style={style}><AddButton onClick={this.openAddCouponMappingDailog} /></div>)}
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={couponMapData}
                                onFetchData={this.fetchData}
                                columns={[
                                    {
                                        headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px', fontWeight: '500' },
                                        style: { textAlign: "center" },
                                        Header: "Applicable Type",
                                        accessor: "applicableType"
                                    },

                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                                        style: { textAlign: "center" },
                                        Header: "Entity Name",
                                        accessor: "entityName"
                                    }
                                ]}
                                defaultPageSize={5}
                                className="-striped -highlight"
                            />
                            <Dialog
                                onClose={this.handleClose}
                                open={this.state.open}
                                maxWidth='md'
                                // minWidth='400px'
                                aria-labelledby="simple-dialog-title"
                            >
                                <DialogTitle id="simple-dialog-title">Add A Coupon Mapping</DialogTitle>
                                <DialogContent>
                                    <AddCouponMapping selectedCouponId={this.props.selectedCouponId} submitClose={this.onSubmit} />
                                </DialogContent>
                            </Dialog>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

CouponMap.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CouponMap);