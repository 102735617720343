import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Axios from 'axios';
import { API_CONFIG } from "../../../config/config.jsx";
import ReactTable from "react-table";
import DownloadIcon from 'assets/img/DownloadIcon.png';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import matchSorter from 'match-sorter';
import { dateFormat } from '../../../Constant.jsx';

var Moment = require("moment");

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "0px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    title: {
        fontSize: 14,
    },
    downloadStyle: {
        width: 20,
        height: 20,
        marginLeft: "85%",
        cursor: "pointer",
        marginBottom: "2px"
    },
});
const createWorkbook = (records) => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2019, 12, 19)
    };

    wb.SheetNames.push("Subscribed Users Report");
    const data = records.data.map(obj => [obj.name, obj.mobileNo, obj.plan, obj.duration, obj.startDate, obj.endDate]);
    const ws_data = [['Name', 'Mobile No', 'Plan', 'Duration', 'Start Date', 'End Date'], ...data];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Subscribed Users Report"] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    return wbout;
}
const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}

class SubscribedUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribedUserData: []
        };
        this.reactTable = React.createRef();
    }

    componentDidMount = () => {
        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/user/subscribed/`,
        )
            .then(res => {
                this.setState({
                    subscribedUserData: res.data
                });

            });
    }
    handleExcel = () => {
        const records = this.reactTable.getResolvedState();
        console.log(records);
        saveAs(new Blob([s2ab(createWorkbook(records))], { type: "application/octet-stream" }), 'test.xlsx');
    }
    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            console.log(rowInfo);
            return {
                style: {
                    backgroundColor: rowInfo.original.expiring === true ? '#ffd454 ' : '',
                }
            }
        }
        return {};
    }
    render() {
        const { classes } = this.props;
        let subscribedUserData = this.state.subscribedUserData.map((row, index) => {
            return {
                name: row.userName,
                mobileNo: row.mobile,
                plan: row.planAmount,
                duration: row.planDuration + " Month",
                startDate: row.startDate,
                endDate: row.endDate,
                expiring: row.expiring

            }
        });
        return (
            <div>
                <Card>
                    <CardHeader color="success" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h4 className={classes.cardTitleWhite}>Subscribed Users</h4>
                            <img onClick={this.handleExcel} title="Download as Excel" className={classes.downloadStyle} src={DownloadIcon}></img>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={subscribedUserData}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}

                            columns={[
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Name",
                                    id: "name",
                                    accessor: d => d.name,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["name"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Mobile No",
                                    id: "mobileNo",
                                    accessor: d => d.mobileNo,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["mobileNo"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Plan",
                                    id: "plan",
                                    accessor: d => d.plan,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["plan"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Duration",
                                    id: "duration",
                                    accessor: d => d.duration,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["duration"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Start Date",
                                    id: "startDate",
                                    accessor: d => Moment(d.startDate).format(dateFormat),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["startDate"] }),
                                    filterAll: true,
                                    sortMethod: (a, b) => {
                                        const a1 = Moment(a, dateFormat);
                                        const b1 = Moment(b, dateFormat);
                                        if (a1.isBefore(b1))
                                            return 1;
                                        else if (a1.isAfter(b1))
                                            return -1;
                                        else
                                            return 0;
                                    }
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "End Date",
                                    id: "endDate",
                                    accessor: d => Moment(d.endDate).format(dateFormat),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["endDate"] }),
                                    filterAll: true,
                                    sortMethod: (a, b) => {
                                        const a1 = Moment(a, dateFormat);
                                        const b1 = Moment(b, dateFormat);
                                        if (a1.isBefore(b1))
                                            return 1;
                                        else if (a1.isAfter(b1))
                                            return -1;
                                        else
                                            return 0;
                                    }
                                }
                            ]}
                            className="-striped -highlight"
                            getTrProps={this.getTrProps}
                            ref={(r) => this.reactTable = r}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }

}
SubscribedUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubscribedUser);