import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from 'axios';
import Select from 'react-select';
import {
    Input,
    Button,
    FormGroup,
    Label,
    InputGroup
} from 'reactstrap';

const styles = theme => ({
    inputStyle: {
        width: 400
    },
    grpDivStyle: {
        fontWeight: 300,
        marginBottom: 5
    },
    button: {
        marginTop: 30,
        marginRight: '5%',
        backgroundColor: "#0088cc"
    }
});
class UpdateDispenser extends React.Component {
    state = {
        storeId: '',
        boxId: '',
        storeNames: [],
        isClearable: true,
        sSixCharIDState: null,
        sSixCharID: null,
        validateStatus: '',
    };
    onChangehandler = async (e) => {
        this.setState({
            validateStatus: ''
        });
        if (e.target.id === 'SixCharID') {
            if (this.verifySixCharID(e.target.value)) {
                this.setState({ sSixCharID: e.target.value, sSixCharIDState: "has-success" })
            }
            else {
                this.setState({ sSixCharID: null, sSixCharIDState: "has-danger" })
            }
        }

    }
    selectHandleChange = (event) => {
        if (event === null) {
            this.setState({
                storeId: ""
            })
        }
        else {
            this.setState({
                storeId: event.value
            })
        }
    }
    verifySixCharID = (value) => {
        if (value && value !== null) {
            var sixCharIDRex = /^[a-zA-z]{6}$/;
            if (sixCharIDRex.test(value)) {
                return true;
            }
            return false;
        } else {
            return false;
        }
    }
    componentDidMount() {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.get(`${API_CONFIG.HOST}/store/all`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                const mobileNo = sessionStorage.getItem('plugoUserMobileNo');
                // if(mobileNo.startsWith('+31')){
                //     const data = res.data.filter(d => {
                //         return d.pincode === '4431 NK'
                //     })
                //     this.setState({
                //         storeNames: data
                //     })
                //     return;
                // }
                let hydData = res.data.filter(data => {
                    return data.city === "Hyderabad"
                })
                if (window.isHyd) {
                    this.setState({
                        storeNames: hydData,
                    });
                }
                else {
                    this.setState({
                        storeNames: res.data
                    });
                }
            });
    }
    handleStoreIdValidation = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.get(`${API_CONFIG.HOST}/boxes/${this.state.sSixCharID}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                console.log(res.data);
                if(res.data.storeId !== 'ab0d9422-3a93-4157-ab2a-2decb3f47e14'){        // prod storeId
                // if(res.data.storeId !== '7267e178-6298-414b-85a5-90aae81ef684'){
                    var options = {
                        place: 'tc',
                        message: " " + "This Box has Already Assigned Some Other Store  ",
                        type: 'success',
                        icon: 'far fa-check-circle',
                        autoDismiss: 4
                    }
                    if (this.notification && this.notification.state.notifyTR.length < 1) {
                        this.notification.notificationAlert(options)
                    }
                }
                this.setState({
                    validateStatus: res.status === 200 ? 'Validated Successfully' : 'Validated Failed',
                    boxId: res.data.id
                });
            })
            .catch(error => {
                const options = {
                    place: 'tc',
                    message: ' Invalid Box Id',
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
    }
    handleUpdateStore = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');
        const storeGroupData = {
            storeId: this.state.storeId
        }
        Axios.put(`${API_CONFIG.HOST}/boxes/${this.state.boxId}`, storeGroupData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                this.setState({
                });
                var options = {
                    place: 'tc',
                    message: " " + "Added Successfully",
                    type: 'success',
                    icon: 'far fa-check-circle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
    }
    render() {
        const { classes } = this.props;
        let { sSixCharIDState } = this.state
        let storeNames = this.state.storeNames.map(storeData =>
            ({
                value: storeData.id,
                label: storeData.name
            })
        );
        console.log(this.state.storeId);
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <FormGroup>
                            <Label for="examplePassword">6 Character ID</Label>
                            <InputGroup>
                                <Input
                                    onChange={this.onChangehandler}
                                    id="SixCharID"
                                    autoComplete="off"
                                    valid={sSixCharIDState === "has-success"}
                                    invalid={sSixCharIDState === "has-danger"}
                                    placeholder="Enter 6 Character ID"
                                />
                            </InputGroup>
                        </FormGroup>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button onClick={this.handleStoreIdValidation} disabled={!(sSixCharIDState === "has-success")} variant="contained" color="primary" className={classes.button}>
                                Validate
                            </Button>
                            <div style={{ marginTop: '6%' }}><Label>{this.state.validateStatus}</Label></div>
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Label for="examplePassword">Store Name</Label>
                        <Select
                            options={storeNames}
                            onChange={this.selectHandleChange}
                            className={classes.selectStyle}
                            defaultInputValue="none"
                            isClearable={this.state.isClearable}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div>
                            <Button onClick={this.handleUpdateStore} disabled={!(this.state.validateStatus)} variant="contained" color="primary" className={classes.button}>
                                Update
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

UpdateDispenser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpdateDispenser);
