import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { API_CONFIG } from "../../config/config.jsx";
import ReactTable from "react-table";
import matchSorter from 'match-sorter'

const styles = theme => ({

});

class BoxInventoryReportByPin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxes: [],
        }
    }
    fetchData = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/${this.props.city}/pincodes/${userId}`
        )
            .then(res => {
                this.setState({
                    boxes: res.data
                });
            });
    }
    componentWillReceiveProps = (nextProps) => {
        if (this.props.city !== nextProps.city) {
        const userId = sessionStorage.getItem('plugoUserId');
        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/${nextProps.city}/pincodes/${userId}`
        )
            .then(res => {
                this.setState({
                    boxes: res.data
                });
            });
        }
    }
    render() {
        const { classes } = this.props;
        const onRowClick = (state, rowInfo, column, instance) => {
            return {
                onClick: e => {
                    this.props.onSelectedPin(rowInfo.original.pin);
                }
            }
        }
        let boxData = this.state.boxes.map((row, index) => {
            return {
                pin: row.pincode,
                noOfBoxes: row.noOfBoxes,
                noOfPowerBanks: row.noOfPowerbanks,
                readyToRentPowerbanks:row.readyToRentPowerbanks,
                faultyPowerbanks:row.faultyPowerbanks
            }
        });
        return (
            <div>
                <ReactTable
                    data={boxData}
                    onFetchData={this.fetchData}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}

                    columns={[
                        {
                            headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                            style: { textAlign: "center", cursor: "pointer" },
                            Header: "PIN",
                            id: "pin",
                            accessor: d => d.pin,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["pin"] }),
                            filterAll: true
                        },

                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px' },
                            style: { textAlign: "center", cursor: "pointer" },
                            Header: "No of Boxes",
                            id: "noOfBoxes",
                            accessor: d => d.noOfBoxes,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["noOfBoxes"] }),
                            filterAll: true
                        },
                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px' },
                            style: { textAlign: "center", cursor: "pointer" },
                            Header: "No of Power Banks",
                            id: "noOfPowerBanks",
                            accessor: d => d.noOfPowerBanks,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["noOfPowerBanks"] }),
                            filterAll: true
                        },
                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px' },
                            style: { textAlign: "center", cursor: "pointer" },
                            Header: "Available",
                            id: "readyToRentPowerbanks",
                            accessor: d => d.readyToRentPowerbanks,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["readyToRentPowerbanks"] }),
                            filterAll: true
                        },
                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px' },
                            style: { textAlign: "center", cursor: "pointer" },
                            Header: "Faulty",
                            id: "faultyPowerbanks",
                            accessor: d => d.faultyPowerbanks,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["faultyPowerbanks"] }),
                            filterAll: true
                        },


                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    getTrProps={onRowClick}
                />
            </div>
        );
    }
}
BoxInventoryReportByPin.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BoxInventoryReportByPin);