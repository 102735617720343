import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import Login from '../views/Login';
import Admin from '../layouts/Admin.jsx';
import React from 'react';
import { Switch, Redirect } from "react-router-dom";

const Routes = (props) => {
  return (
    <Switch>
      <PublicRoutes
        path="/login"
        component={Login}
        exact={true}
      />
      <PrivateRoutes
        path="/admin"
        component={Admin}
      />
      <Redirect
        from="/"
        to="/login"
      />
    </Switch>
  )
}

export default Routes;