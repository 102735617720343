import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import BoxInventoryReportByPin from './BoxInventoryReportByPin';
import InventoryReportByStore from './InventoryReportByStore'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from "react-redux";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CardBox from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ComboChart from 'assets/img/ComboChart.png';
import DownloadIcon from 'assets/img/DownloadIcon.png';
import Axios from 'axios';
import { API_CONFIG } from "../../config/config.jsx";
import ReactTable from "react-table";
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  card: {
    width: 250,
  },
  cardStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  chartStyle: {
    width: 20,
    height: 15,
    marginLeft: 50,
    cursor: "pointer",
    marginBottom: "2px"
  },
  title: {
    fontSize: 14,
  },
  downloadStyle: {
    width: 20,
    height: 20,
    marginLeft: 800,
    cursor: "pointer",
    marginBottom: "2px"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  form: {
    marginLeft: "40%"
  }
});

const createWorkbook = (records) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "SheetJS Tutorial",
    Subject: "Test",
    Author: "Red Stapler",
    CreatedDate: new Date(2019, 12, 19)
  };

  wb.SheetNames.push("Test Sheet");
  const data = records.data.map(obj => [obj.city, obj.noOfBoxes, obj.noOfPowerBanks]);
  const ws_data = [['City', 'No of Boxes', 'No of Power Banks'], ...data];
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Test Sheet"] = ws;
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  return wbout;
}
const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;
}

class BoxInventoryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxes: [],
      open: false,
      showTable: false,
      pincodes: [],
      selectedPin: ''
    }
    this.reactTable = React.createRef();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  componentDidMount() {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');
    Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/cities/${userId}`,
    )
      .then(res => {
        this.setState({
          boxes: res.data
        });
      });
  }

  handleExcel = () => {
    const records = this.reactTable.getResolvedState();
    saveAs(new Blob([s2ab(createWorkbook(records))], { type: "application/octet-stream" }), 'test.xlsx');
  }
  onSelectedPin = (pin) => {
    this.setState({
        selectedPin: pin
    })
}
  render() {
    const { classes } = this.props;
    let filteredCity = this.state.boxes.filter(selectCity => selectCity.location === this.props.city);
    let noOfBoxes;
    let noOfPowerBanks;
    let readyToRentPowerbanks;
    let faultyPowerbanks;
    let boxData = filteredCity.map((row, index) => {
      noOfBoxes = row.noOfBoxes;
      noOfPowerBanks = row.noOfPowerbanks;
      readyToRentPowerbanks = row.readyToRentPowerbanks;
      faultyPowerbanks = row.faultyPowerbanks;
    });
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "-35px", marginBottom: "-22px" }}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>No of Boxes </Typography>
              <Typography variant="h5" component="h2">{noOfBoxes}</Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>No of Power Banks</Typography>
              <Typography variant="h5" component="h2">{noOfPowerBanks}</Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>Available</Typography>
              <Typography variant="h5" component="h2">{readyToRentPowerbanks}</Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>Faulty </Typography>
              <Typography variant="h5" component="h2">{faultyPowerbanks}</Typography>
            </CardContent>
          </Card>
        </div>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Inventory Report by PIN for the city</h4>
          </CardHeader>
          <CardBody>
            {/* {this.state.showTable ? */}
            <BoxInventoryReportByPin city={this.props.city} onSelectedPin={this.onSelectedPin}  />
            {/* : ""} */}
          </CardBody>
        </Card>
        <InventoryReportByStore city={this.props.city} pincodes={this.props.pincodes} selectedPin={this.state.selectedPin} />
      </div>
    );
  }
}

BoxInventoryReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ city: state.city.city, pincodes: state.city.pincodes })
export default connect(mapStateToProps, null, null, { pure: false })(withStyles(styles)(BoxInventoryReport))