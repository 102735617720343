 const hasAuthority = (authority) => {
    const authoritiesList = sessionStorage.getItem('authoritiesList');
    const authorityArray = authoritiesList.split(',');
    const indexOfauthority = authorityArray.indexOf(authority);
    if (indexOfauthority === -1) {
       return false;
    }
    return true;
 }


 export class User {
    static isSuperAdmin() {
       return hasAuthority('SUPER_ADMIN');
    }
    static isViewDashboard() {
      return hasAuthority('VIEW_DASHBOARD');
   }
    static isViewRentals() {
       return hasAuthority('VIEW_RENTALS');
    }
    static isViewInventory() {
       return hasAuthority('VIEW_INVENTORY');
    }
    static isViewStoreUser() {
       return hasAuthority('VIEW_STORE_USER');
    }
    static isViewDefaultCustomer() {
       return hasAuthority('VIEW_DEFAULT_CUSTOMER');
    }
    static isViewBoxStatus() {
       return hasAuthority('VIEW_BOX_STATUS');
    }
    static isViewRevenue() {
       return hasAuthority('VIEW_REVENUE');
    }
    static isReadUser() {
       return hasAuthority('READ_USER');
    }
    static isReadStores() {
       return hasAuthority('READ_STORES');
    }
    static isReadStore() {
      return hasAuthority('READ_STORE');
   }
   static isLostPowerbankCharge() {
      return hasAuthority('LOST_POWERBANK_CHARGE');
   }
    static isCreateCoupons() {
       return hasAuthority('CREATE_COUPONS');
    }
    static isReadCoupons() {
       return hasAuthority('READ_COUPONS');
    }
    static isDeleteCoupons() {
      return hasAuthority('DELETE_COUPONS');
   }
   static isCreateCouponRules() {
      return hasAuthority('CREATE_COUPON_RULES');
   }
   static isReadCouponRules() {
      return hasAuthority('READ_COUPON_RULES');
   }
   static isCreateCouponMapping() {
      return hasAuthority('CREATE_COUPON_MAPPINGS');
   }
   static isReadCouponMapping() {
      return hasAuthority('READ_COUPON_MAPPINGS');
   }
    static isCreateStoreGroup() {
       return hasAuthority('CREATE_STORE_GROUP');
    }
    static isCreateStore() {
       return hasAuthority('CREATE_STORE');
    }
    static isUserAdmin() {
      return hasAuthority('USER_ADMIN');
   }
    static isUpdateBoxes() {
       return hasAuthority('UPDATE_BOXES');
    }
    static isUpdateStore() {
       return hasAuthority('UPDATE_STORE');
    }
    static isCreateStoreOperatingHours() {
       return hasAuthority('CREATE_STORE_OPERATING_HOURS');
    }
    static isUploadS3() {
      return hasAuthority('UPLOAD_S3');
   }
    static isReadPartnerRequest() {
       return hasAuthority('READ_PARTNER_REQUEST');
    }
    static isCreateAccount() {
       return hasAuthority('CREATE_ACCOUNT');
    }
    static isReadAccount() {
       return hasAuthority('READ_ACCOUNTS');
    }

    static hasAuthority(authority) {
       return hasAuthority(authority);
    }
 }