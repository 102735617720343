import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from "@material-ui/core";
import AddNewGroup from "./AddNewGroup.jsx";
import AddStoreToGroup from "./AddStoreToGroup.jsx";
import Select from 'react-select';
import { API_CONFIG } from "../../config/config.jsx";
import Axios from 'axios';

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    // selectStyle: {
    //     width: 500,
    //     marginLeft: 200,
    // },
    button: {
        // marginLeft: 150,
        marginTop: "25px",
        marginBottom: "30px",
        backgroundColor: "#0088cc"
    },
    // grpDivStyle: {
    //     marginLeft: 200
    // },
    textField: {
        width: 500,
        marginTop: 0
    },
    saveButton: {
        marginLeft: 400,
        marginTop: "40px",
        marginBottom: "30px",
        backgroundColor: "#0088cc"
    },
});
const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'blue'
};
class StoreGroup extends React.Component {
    state = {
        storeIds: '',
        parentGroupId: '',
        parentGroups: [],
        open: false,
        isClearable: true,
        maxWidth: 'sm'
    };
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    selectHandleChange = (event) => {
        if (event === null) {
            this.setState({
                parentGroupId: ""
            })
        }
        else {
            this.setState({
                parentGroupId: event.value
            })
        }
    }
    
    componentDidMount() {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.get(`${API_CONFIG.HOST}/store/group/all`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                this.setState({
                    parentGroups: res.data
                })
            });
    }
    handleOpen = () => {
        this.setState({
            open: true
        });
    }
    handleClose = () => {
        this.setState({
            open: false
        });
    }
    onSubmit = (newGroup) => {
        if(newGroup) {
          this.setState({
            open: false,
            parentGroups: [newGroup, ...this.state.parentGroups]
          })
        } else {
          this.setState({
            open: false
          })
        }
        
      }


    render() {
        const { classes } = this.props;
        let parentOptions = this.state.parentGroups.map(parentObj => ({
            value: parentObj.id,
            label: parentObj.groupName
        }));
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="success">
                                <h4 className={classes.cardTitleWhite}>Store Group</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <div className={classes.grpDivStyle}>Group Name</div>
                                        <Select
                                            options={parentOptions}
                                            onChange={this.selectHandleChange}
                                            className={classes.selectStyle}
                                            defaultInputValue="none"
                                            isClearable={this.state.isClearable}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <div>
                                            <Button onClick={this.handleOpen} variant="contained" color="primary" className={classes.button}>
                                                Create New Group
                                            </Button>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <AddStoreToGroup groupId={this.state.parentGroupId} />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                <Dialog onClose={this.handleClose} open={this.state.open} aria-labelledby="simple-dialog-title">
                                    <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>Create Store Group</DialogTitle>
                                    <DialogContent>
                                        <AddNewGroup options={parentOptions} submitClose={this.onSubmit}/>
                                    </DialogContent>
                                </Dialog>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

}
StoreGroup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StoreGroup);