import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import { Button, Checkbox } from '@material-ui/core';
import Axios from 'axios';
import { API_CONFIG } from "../../../config/config.jsx";
import OperatingHours from "../OperatingHours/OperatingHours.jsx"
import Secure from '../../../components/Secure.jsx';

const styles = theme => ({
  textField: {
    width: "200px"
  }
});

const google = window.google;

class StoreOnBoardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      mobileNo: '',
      address: '',
      city: '',
      floor: '',
      pincode: '',
      state: '',
      latitude: '',
      longitude: '',
      business: '',
      website: '',
      isVisible: false,
      isReviewApplicable: false,
      isEnableMobileNumber: false,
      storeId: '',
      timing: [],
      showTime: false,
      errorText: '',
      timingText: '',
      isButtonDisabled: false
    };
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    const mobileNo = sessionStorage.getItem('plugoUserMobileNo');
    if (process.env.REACT_APP_ACTIVE_REGION == "europe") {
      if (mobileNo.startsWith('+31')) {
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
          {
            componentRestrictions: { country: 'NL' },
            // types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
          });
      }
      else {
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
          {
            componentRestrictions: { country: 'de' },
            // types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
          });
      }
    }
    else {

      this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
        {
          componentRestrictions: { country: 'IN' },
          // types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
        });
    }

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  parseTime(time) {
    if (parseInt(time) < 1200) {
      const d = new Date(70, 1, 1, parseInt(time.substr(0, 2)), time.substr(2), 0);
      return d
    } else {
      const d = new Date(70, 1, 1, parseInt(time.substr(0, 2)), time.substr(2), 0);
      return d;
    }
  }

  handlePlaceChanged() {
    const event = this.autocomplete.getPlace();
    console.log(event);
    this.setState({
      name: event['name'],
      address: event['formatted_address'],
      latitude: event['geometry']['location'].lat(),
      longitude: event['geometry']['location'].lng(),
    })

    if (process.env.REACT_APP_ACTIVE_REGION == "europe")       // This is for Europe build
    {

      event['address_components'].forEach((component) => {
        if (component['types'].includes('locality')) {
          this.setState({
            city: component['long_name']
          })
        }
        if (component['types'].includes('administrative_area_level_1')) {
          this.setState({
            state: component['long_name']
          })
        }
        if (component['types'].includes('postal_code')) {
          this.setState({
            pincode: component['long_name']
          })
        }

      });

    }
    else                                    // This is for INDIA build
    {
      event['address_components'].forEach((component) => {
        if (component['types'].includes('postal_code')) {
          this.setState({
            pincode: component['long_name']
          })
        }
      });

      Axios.get(`https://api.postalpincode.in/pincode/${this.state.pincode}`)
        .then(res => {
          this.setState({
            floor: 'Ground Floor',
            city: res.data[0]['PostOffice'][0]['District'],
            state: res.data[0]['PostOffice'][0]['State'],
            business: 'Restaurant',
            password: 'rudreshrd',
          });
        });
    }


    if (event['opening_hours']) {
      let timings = event['opening_hours']['periods'];
      console.log("timiing: ", timings);
      let newTimings = [];
      timings.forEach(timing => {
        if (timing['open'] && timing['close']) {
          let time1 = {};
          let time2 = {};
          if (timing['open']['day'] === timing['close']['day']) {
            time1['day'] = timing['open']['day'];
            time1['startTime'] = this.parseTime(timing['open']['time']);
            time1['endTime'] = this.parseTime(timing['close']['time']);
            newTimings.push(time1);
          } else {
            time1['day'] = timing['open']['day'];
            time1['startTime'] = this.parseTime(timing['open']['time']);
            time1['endTime'] = this.parseTime('2359');
            newTimings.push(time1);
            time2['day'] = timing['close']['day'];
            time2['startTime'] = this.parseTime('0000');
            time2['endTime'] = this.parseTime(timing['close']['time']);
            newTimings.push(time2);
          }
        }
      });
      this.setState({
        timing: newTimings
      })
    } else {
      this.setState({ timingText: 'Please Add Operating Hours.', })
      console.log('No Timing Details found')
    }

  }


  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  change = (event) => {
    this.setState({
      mobileNo: event.target.value,
      isEnableMobileNumber: true,
    });
  }
  onChangeVisible = () => {
    this.setState({
      isVisible: !this.state.isVisible
    })
  }
  onChangeReviewApplicable = () => {
    this.setState({
      isReviewApplicable: !this.state.isReviewApplicable
    })
  }
  submit = event => {
    event.preventDefault();
    const mobileNo = sessionStorage.getItem('plugoUserMobileNo');
    if (window.isHyd && (this.state.city !== "Hyderabad" && this.state.city !== '')) {
      this.setState({ errorText: "Only Hyderabad is allowed" });
      return;
    }
    this.setState({
      isButtonDisabled: true
    });
    let countryCode = mobileNo.startsWith('+31') ? 'NL' : 'DE'
    if (mobileNo.startsWith('+91')) {
      countryCode = 'IN'
    }
    const createStoreData = {
      name: this.state.name,
      password: this.state.password,
      mobile: this.state.mobileNo,
      address: this.state.address,
      city: this.state.city,
      floor: this.state.floor,
      pincode: this.state.pincode,
      state: this.state.state,
      countryCode: countryCode,
      lat: this.state.latitude,
      lng: this.state.longitude,
      business: this.state.business,
      website: this.state.website,
      enableMobileNumber: this.state.isEnableMobileNumber,
      isVisible: this.state.isVisible,
      reviewApplicable: this.state.isReviewApplicable
    }
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');
    Axios.post(`${API_CONFIG.HOST}/store/create`, createStoreData, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          name: '',
          password: '',
          mobileNo: '',
          address: '',
          city: '',
          floor: '',
          pincode: '',
          state: '',
          countryCode: '',
          latitude: '',
          longitude: '',
          business: '',
          website: '',
          isVisible: false,
          isReviewApplicable: false,
          isEnableMobileNumber: false,
          storeId: res.data.id,
          showTime: true
        })
        var options = {
          place: 'tc',
          message: " " + "Created Successfully",
          type: 'success',
          icon: 'far fa-check-circle',
          autoDismiss: 4
        }
        if (this.notification && this.notification.state.notifyTR.length < 1) {
          this.notification.notificationAlert(options)
        }
      })
      .catch(error => {
        this.setState({ loading: false })
        const options = {
          place: 'tc',
          message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
          type: 'danger',
          icon: 'fas fa-exclamation-triangle',
          autoDismiss: 4
        }
        if (this.notification && this.notification.state.notifyTR.length < 1) {
          this.notification.notificationAlert(options)
        }
      })

  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className="rna-container">
          <NotificationAlert ref={(c) => { this.notification = c }} />
        </div>
        <Card>
          <div style={{ width: "80%", border: "1px #A9A9A9 solid", height: "35px", paddingLeft: "8px" }}>
            <i class="fa fa-search" aria-hidden="true"></i>
            <input ref={this.autocompleteInput} id="autocomplete" style={{ width: "97%", border: "none", marginTop: "4px", marginLeft: "4px" }} placeholder="Search Store for autocomplete..."
              type="text">
            </input>
          </div>
          <CardBody>
            <form className={classes.container} onSubmit={this.submit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-name"
                    label="Name"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.name}
                    onChange={this.handleChange('name')}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-password"
                    label="Password"
                    disabled
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="outlined-mobileNo"
                    label="Mobile No(Optional)"
                    value={this.state.mobileNo}
                    onChange={this.change}
                    className={classes.textField}
                    margin="normal"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-address"
                    label="Address"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.address}
                    onChange={this.handleChange('address')}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-city"
                    label="City"
                    value={this.state.city}
                    onChange={this.handleChange('city')}
                    className={classes.textField}
                    margin="normal"
                    error={this.state.errorText.length === 0 ? false : true}
                    helperText={this.state.errorText}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-floor"
                    label="Floor"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.floor}
                    onChange={this.handleChange('floor')}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-pincode"
                    label="Pincode"
                    value={this.state.pincode}
                    onChange={this.handleChange('pincode')}
                    className={classes.textField}
                    margin="normal"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-state"
                    label="State"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.state}
                    onChange={this.handleChange('state')}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-latitude"
                    label="Latitude"
                    value={this.state.latitude}
                    onChange={this.handleChange('latitude')}
                    className={classes.textField}
                    margin="normal"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-longitude"
                    label="Longitude"
                    value={this.state.longitude}
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChange('longitude')}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="outlined-business"
                    label="Business"
                    value={this.state.business}
                    onChange={this.handleChange('business')}
                    className={classes.textField}
                    margin="normal"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="outlined-website"
                    label="Website"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.website}
                    onChange={this.handleChange('website')}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{ marginTop: "15px" }}>
                    <Checkbox
                      checked={this.state.isVisible}
                      value={this.state.isVisible}
                      onChange={this.onChangeVisible}
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                      }}
                      style={{ color: "#3f51b5" }}
                    />
                    <label style={{ fontSize: "17px" }}>Show Store On Map</label>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{ marginTop: "15px" }}>
                    <Checkbox
                      checked={this.state.isReviewApplicable}
                      value={this.state.isReviewApplicable}
                      onChange={this.onChangeReviewApplicable}
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                      }}
                      style={{ color: "#3f51b5" }}
                    />
                    <label style={{ fontSize: "17px" }}>Review Applicable</label>
                  </div>
                </GridItem>
              </GridContainer>
              <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                <Button type="submit" disabled={this.state.isButtonDisabled} variant="contained" color="primary">
                  Create
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
        {this.state.showTime ?
          Secure("CREATE_STORE_OPERATING_HOURS", <OperatingHours storeId={this.state.storeId} operatingHours={this.state.timing} timingText={this.state.timingText} />)
          : ''}
      </div>
    );
  }
}

StoreOnBoardForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StoreOnBoardForm);
