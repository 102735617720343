import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
// import Select from 'react-select';
import { API_CONFIG } from "../../config/config.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AsyncSelect from 'react-select/async';
import Axios from 'axios';
import {
    Input,
} from 'reactstrap';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '200px'
    },
    selectStyle: {
        width: 275,
    }
});

class AddCouponRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            types: [
                { key: 0, value: 'USER' },
                { key: 1, value: 'STORE' },
                { key: 2, value: 'SUBSCRIPTION' }
            ],
            type: 'USER',
            applicableTypeId: ''
        }
    }
    handleSelect = (e) => {
        this.setState({
            type: e.target.value
        })
    }
    onChangeSearchText = (inputValue) => {
        this.setState({
            searchText: inputValue
        })
    };
    userHandleChange = (event) => {
        this.setState({
            applicableTypeId: event.value
        })
    }

    getCustomersInfo = async (inputValue) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        const result = await Axios.get(`${API_CONFIG.HOST}/users/search?mobile=${inputValue}&lastName=${inputValue}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        });

        return result.data.map(data => ({
            value: data.id,
            label: data.mobile + " - " + data.firstName
        }));
    }

    userLoadOptions = (inputValue) => {
        return this.getCustomersInfo(inputValue);
    };
    storeHandleChange = (event) => {
        this.setState({
            applicableTypeId: event.value
        })
    }

    getStoresInfo = async (inputValue) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        const result = await Axios.get(`${API_CONFIG.HOST}/stores/search?mobile=${inputValue}&name=${inputValue}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        });
        console.log(result);
        this.setState({ storeId: result.data[0].id })
        return result.data.map(data => ({
            value: data.id,
            label: data.name
        }));
    }

    storeLoadOptions = (inputValue) => {
        return this.getStoresInfo(inputValue);
    };

    submit = event => {
        event.preventDefault();
        const couponMappingData = [{
            applicableType: this.state.type,
            applicableTypeId: this.state.applicableTypeId,
        }]
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.post(`${API_CONFIG.HOST}/coupons/${this.props.selectedCouponId}/mappings`, couponMappingData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                console.log(res);
                this.props.submitClose(res.status);
            })
    };

    render() {
        const buttonStyle = {
            position: 'relative',
            marginLeft: '45%',
            marginTop: '10%',
        };
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form className={classes.container} onSubmit={this.submit.bind(this)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <Input type="select" name="select" onChange={this.handleSelect} id="exampleSelect">
                                    {this.state.types.map(type => (
                                        <option value={type.value}>{type.value}</option>)
                                    )}
                                </Input>
                            </GridItem>
                            {this.state.type === 'USER' || this.state.type === 'SUBSCRIPTION' ?
                                <GridItem xs={12} sm={12} md={6}>
                                    <AsyncSelect
                                        cacheOptions
                                        loadOptions={this.userLoadOptions}
                                        onChange={this.userHandleChange}
                                        defaultOptions
                                        placeholder="Select User..."
                                        className={classes.selectStyle}
                                        onInputChange={this.onChangeSearchText}
                                    />
                                </GridItem> :
                                <GridItem xs={12} sm={12} md={6}>
                                    <AsyncSelect
                                        cacheOptions
                                        loadOptions={this.storeLoadOptions}
                                        onChange={this.storeHandleChange}
                                        defaultOptions
                                        placeholder="Select Store..."
                                        className={classes.selectStyle}
                                        onInputChange={this.onChangeSearchText}
                                    />
                                </GridItem>}
                        </GridContainer>
                        <div style={buttonStyle}>
                            <Button type="submit" variant="contained" size="small" className={classes.button}>
                                <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />Save </Button>
                        </div>
                    </form>
                </GridItem>
            </GridContainer>
        );
    }
}

AddCouponRule.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddCouponRule);
