import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
// import Select from 'react-select';
import { API_CONFIG } from "../../config/config.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AsyncSelect from 'react-select/async';
import Axios from 'axios';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '200px'
    },
 
    userSelectStyle: {
        width: 285,
        marginTop: "5%",
        marginLeft: "-30px"
    },
    storeSelectStyle: {
        width: 285,
        marginTop: "5%",
        marginLeft: "20px"
    }
});

class AddCouponRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            couponRule: {
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                pincode: '',
                city: '',
                inputValue: '',
            },
            open: false,
            users: [],
            searchText: '',
            userId: '',
            storeId: '',
            usageLimit: '',
            totalUsageLimit: '',
            rentalSessionLimit: ''
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    onChangeSearchText = (inputValue) => {
        this.setState({
            searchText: inputValue
        })
    };
    userHandleChange = (event) => {
        this.setState({
            userId: event.value
        })
    }
    userLoadOptions = (inputValue) => {
        return this.getCustomersInfo(inputValue);
    };

    getCustomersInfo = async (inputValue) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        const result = await Axios.get(`${API_CONFIG.HOST}/users/search?mobile=${inputValue}&lastName=${inputValue}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        });

        return result.data.map(data => ({
            value: data.id,
            label: data.mobile + " - " + data.firstName
        }));
    }
   
    storeHandleChange = (event) => {
        this.setState({
            storeId: event.value
        })
    }

    getStoresInfo = async (inputValue) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        const result = await Axios.get(`${API_CONFIG.HOST}/stores/search?mobile=${inputValue}&name=${inputValue}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        });
        console.log(result);
        this.setState({ storeId: result.data[0].id })
        return result.data.map(data => ({
            value: data.id,
            label: data.name
        }));
    }

    storeLoadOptions = (inputValue) => {
        return this.getStoresInfo(inputValue);
    };

    submit = event => {
        event.preventDefault();
        const couponRulesData = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            pincode: this.state.pincode,
            city: this.state.city,
            userId: this.state.userId,
            storeId: this.state.storeId,
            usageLimit: this.state.usageLimit,
            totalUsageLimit: this.state.totalUsageLimit,
            rentalSessionLimitInHours: this.state.rentalSessionLimit
        }
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.post(`${API_CONFIG.HOST}/coupons/${this.props.selectedCouponId}/rules`, couponRulesData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                this.props.submitClose(res.data);
            })
    };

    render() {
        const buttonStyle = {
            position: 'relative',
            marginLeft: '45%',
            marginTop: '5%',
        };
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form className={classes.container} onSubmit={this.submit.bind(this)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="starteDate"
                                    label="Start date"
                                    type="date"
                                    onChange={this.handleChange('startDate')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="endDate"
                                    label="End date"
                                    type="date"
                                    className={classes.textField}
                                    onChange={this.handleChange('endDate')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="startTime"
                                    label="Start time"
                                    type="time"
                                    className={classes.textField}
                                    onChange={this.handleChange('startTime')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 900,
                                    }}
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="endTime"
                                    label="End time"
                                    type="time"
                                    className={classes.textField}
                                    onChange={this.handleChange('endTime')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 900,
                                    }}
                                    variant="outlined"
                                />
                            </GridItem>

                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="outlined-pincode"
                                    label="Pincode"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    onChange={this.handleChange('pincode')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="outlined-city"
                                    label="City"
                                    onChange={this.handleChange('city')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="outlined-usageLimit"
                                    label="Usage Limit"
                                    onChange={this.handleChange('usageLimit')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    id="outlined-usageLimit"
                                    label="Total Usage Limit"
                                    onChange={this.handleChange('totalUsageLimit')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                                <TextField
                                    id="outlined-rentalSessionLimit"
                                    label="Rental Session Limit"
                                    onChange={this.handleChange('rentalSessionLimit')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={this.userLoadOptions}
                                    onChange={this.userHandleChange}
                                    defaultOptions
                                    placeholder="Select User..."
                                    className={classes.userSelectStyle}
                                    onInputChange={this.onChangeSearchText}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={this.storeLoadOptions}
                                    onChange={this.storeHandleChange}
                                    defaultOptions
                                    placeholder="Select Store..."
                                    className={classes.storeSelectStyle}
                                    onInputChange={this.onChangeSearchText}
                                />
                            </GridItem>
                        </GridContainer>
                        <div style={buttonStyle}>
                            <Button type="submit" variant="contained" size="small" className={classes.button}>
                                <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />Save </Button>
                        </div>
                    </form>
                </GridItem>
            </GridContainer>
        );
    }
}

AddCouponRule.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddCouponRule);
