import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Axios from 'axios';
import { API_CONFIG } from "../../config/config.jsx";
// import { DateRangePicker, DateRange } from "@matharumanpreet00/react-daterange-picker";
import ReactTable from "react-table";
import matchSorter from 'match-sorter';
import moment from 'moment';
// import { Input, InputGroup, InputGroupAddon } from 'reactstrap';

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "0px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
});

class RevenueReport extends React.Component {
    state = {
        revenueData: [],
        open: false,
        dateRange: {},
        startDate: moment().subtract(1, 'months').startOf('month').format('DD-MM-YYYY'),
        endDate: moment().format('DD-MM-YYYY'),
        text: ' To '
    };

    componentDidMount = () => {
        this.fetchRevenue();
    }
    fetchRevenue = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/monthly-report/startDate/${this.state.startDate}/endDate/${this.state.endDate}`,
        )
            .then(res => {
                this.setState({
                    revenueData: res.data.data || [],
                    // open: false,
                });
            });
    }
    // handleClick = () => {
    //     this.setState({
    //         open: !this.state.open
    //     });
    // }
    // handleDateChange = (range) => {
    //     this.setState({
    //         dateRange: range,
    //         startDate: moment(range.startDate, 'dd.mm.yyyy').format('DD-MM-YYYY'),
    //         endDate: moment(range.endDate, 'dd.mm.yyyy').format('DD-MM-YYYY')
    //     }, () => this.fetchRevenue())
    // }

    render() {
        const { classes } = this.props;
        let customerDefaultData = this.state.revenueData.map((row, index) => {
            return {
                month: moment(row.month, 'MM-YYYY').format('MMM-YYYY'),
                subscriptionIncome: row.subscriptionIncome,
                deposits: row.deposits,
                rentalIncome: row.rentalIncome,
                dailyReports: row.dailyReportMappers
            }
        });
        return (
            <div>
                {/* <label>Select Date Range</label>
                <Input
                    value={this.state.startDate + this.state.text + this.state.endDate}
                    onClick={this.handleClick}
                    autoComplete="off"
                    placeholder="Enter Date Range"
                    style={{ width: "300px", display: "inline", marginLeft: "2%" }}
                />
                <DateRangePicker
                    open={this.state.open}
                    onChange={this.handleDateChange}
                /> */}
                <Card>
                    <CardHeader color="success" >
                        <h4 className={classes.cardTitleWhite}>Revenue Report</h4>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={customerDefaultData}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}

                            columns={[
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Month",
                                    id: "month",
                                    accessor: d => d.month,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["month"] }),
                                    filterAll: true,
                                    asc: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Subscription Income",
                                    id: "subsIncome",
                                    accessor: d => d.subscriptionIncome,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["subsIncome"] }),
                                    filterAll: true
                                },

                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Security Deposits",
                                    id: "deposits",
                                    accessor: d => d.deposits,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["deposits"] }),
                                    filterAll: true
                                },

                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Rental Income",
                                    id: "rentalIncome",
                                    accessor: d => d.rentalIncome,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["rentalIncome"] }),
                                    filterAll: true
                                },
                                {
                                    expander: true,
                                    Header: () => <strong>More</strong>,
                                    width: 65,
                                    Expander: ({ isExpanded, ...rest }) =>
                                        <div>
                                            {isExpanded
                                                ? <span>&#x2299;</span>
                                                : <span>&#x2295;</span>}
                                        </div>,
                                    style: {
                                        cursor: "pointer",
                                        fontSize: 25,
                                        padding: "0",
                                        textAlign: "center",
                                        userSelect: "none"
                                    },

                                }
                            ]}
                            defaultSorted={[
                                {
                                    id: "month"
                                }
                            ]}
                            className="-striped -highlight"
                            SubComponent={(v) => {
                                return (
                                    <ReactTable
                                        data={customerDefaultData[v.row._index].dailyReports}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}

                                        columns={[
                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                                style: { textAlign: "center" },
                                                Header: "Date",
                                                id: "date",
                                                accessor: d => d.date,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["date"] }),
                                                filterAll: true,
                                                asc: true
                                            },
                                            {
                                                headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                                style: { textAlign: "center" },
                                                Header: "Amount",
                                                id: "ammount",
                                                accessor: d => d.ammount,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["ammount"] }),
                                                filterAll: true
                                            },

                                            {
                                                headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                                style: { textAlign: "center" },
                                                Header: "Plan",
                                                id: "plan_type",
                                                accessor: d => d.plan_type,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["plan_type"] }),
                                                filterAll: true
                                            }
                                        ]}
                                        defaultSorted={[
                                            {
                                                id: "date"
                                            }
                                        ]}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                    />)
                            }}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }

}
RevenueReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RevenueReport);