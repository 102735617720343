import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { API_CONFIG } from "../../../../config/config.jsx";
import ReactTable from "react-table";
import Axios from "axios";
import matchSorter from "match-sorter";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  }
});
class DatePickedByPin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatePickedData: []
    };
  }
  fetchData = () => {
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");

    Axios.get(
      `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/transactions/${
        this.props.city
      }/pincode/${userId}?startDate=${this.props.startDate}&endDate=${
        this.props.endDate
      }`
    ).then(res => {
      this.setState({
        DatePickedData: res.data
      });
    });
  };
  componentWillReceiveProps = nextProps => {
    if (this.props.city !== nextProps.city) {
      const userId = sessionStorage.getItem("plugoUserId");
      Axios.get(
        `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/transactions/${
          nextProps.city
        }/pincode/${userId}?startDate=${this.props.startDate}&endDate=${
          this.props.endDate
        }`
      ).then(res => {
        this.setState({
          DatePickedData: res.data
        });
      });
    }
  };
  render() {
    const { classes } = this.props;
    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: e => {
          this.props.onSelectedPin(rowInfo.original.pin);
        }
      };
    };
    let DatePickedReportData = this.state.DatePickedData.map((row, index) => {
      return {
        pin: row.pincode,
        dailyDispensed: row.dailyDespensed,
        dailyReturned: row.dailyReturned
      };
    });
    return (
      <ReactTable
        data={DatePickedReportData}
        onFetchData={this.fetchData}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            headerStyle: {
              fontSize: "16px",
              height: "40px",
              paddingTop: "10px"
            },
            style: { textAlign: "center", cursor: "pointer" },
            Header: "Pincode",
            id: "pin",
            accessor: d => d.pin,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["pin"] })
          },
          {
            headerStyle: {
              fontSize: "16px",
              height: "40px",
              paddingTop: "10px"
            },
            style: { textAlign: "center", cursor: "pointer" },
            Header: "Dispensed",
            id: "dispensed",
            accessor: d => d.dailyDispensed,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["dailyDispensed"] }),
            filterAll: true
          },
          {
            headerStyle: {
              fontSize: "16px",
              height: "40px",
              paddingTop: "10px"
            },
            style: { textAlign: "center", cursor: "pointer" },
            Header: "Returned",
            id: "returned",
            accessor: d => d.dailyReturned,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["dailyReturned"] }),
            filterAll: true
          }
        ]}
        defaultPageSize={10}
        className="-striped -highlight"
        getTrProps={onRowClick}
      />
    );
  }
}
DatePickedByPin.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DatePickedByPin);
