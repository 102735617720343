import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DownloadIcon from "assets/img/DownloadIcon.png";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import Axios from "axios";
import { API_CONFIG } from "../../../config/config.jsx";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import DateRange from "./DateRange.jsx";
import moment from "moment";
import "./../../../assets/css/userDetailReport.css";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  title: {
    fontSize: 14
  },
  downloadStyle: {
    width: 20,
    height: 20,
    marginLeft: "80%",
    cursor: "pointer",
    marginBottom: "2px"
  }
});
const createWorkbook = (records, showReport) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "SheetJS Tutorial",
    Subject: "Test",
    Author: "Red Stapler",
    CreatedDate: new Date(2019, 12, 19)
  };
  if (showReport) {
    wb.SheetNames.push("User Details By Stores");
    const data = records.data.map(obj => [
      obj.pincode,
      obj.storeName,
      obj.totalUser,
      obj.standardUser,
      obj.premiumUsers
    ]);
    const ws_data = [
      ["Pincode", "Store Name", "Total User", "Standard User", "Premium User"],
      ...data
    ];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["User Details By Stores"] = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    return wbout;
  } else {
    wb.SheetNames.push("User Details By Stores");
    const data = records.data.map(obj => [
      obj.pincode,
      obj.storeName,
      obj.totalUsers,
      obj.standardUser,
      obj.premiumUser
    ]);
    const ws_data = [
      ["Pincode", "Store Name", "Total User", "Standard User", "Premium User"],
      ...data
    ];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["User Details By Stores"] = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    return wbout;
  }
};
const s2ab = s => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};
class IncomeReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickUpData: [],
      standardUserData: [],
      showReport: false,
      startDate: new Date(),
      endDate: new Date(),
      loading: false
    };
    this.reactTable = React.createRef();
  }
  handleExcel = () => {
    const records = this.reactTable.getResolvedState();
    saveAs(
      new Blob([s2ab(createWorkbook(records, this.state.showReport))], {
        type: "application/octet-stream"
      }),
      "test.xlsx"
    );
  };
  showReport = () => {
    this.setState({
      showReport: true
    });
  };
  showDefaultReport = showDefaultReport => {
    this.setState({
      showReport: showDefaultReport
    });
  };
  fetchData = () => {
    const userId = sessionStorage.getItem("plugoUserId");
    this.setState({ loading: true });
    const date = new Date();
    let startDate = new Date(date.getFullYear(), date.getMonth(), 2)
      .toISOString()
      .slice(0, 10);
    let endDate = new Date().toISOString().slice(0, 10);

    Axios.get(
      `${
        API_CONFIG.PLUGO_REPORTS_API_HOST
      }/report/stores/${userId}?startDate=${startDate}&endDate=${endDate}`
    ).then(res => {
      this.setState({
        standardUserData: res.data
      });
      this.setState({ loading: false });
    });
  };
  fetchStandardReportData = (startDate, endDate, showReport) => {
    this.setState(
      {
        loading: true,
        showReport: showReport
      },
      () => {
        const userId = sessionStorage.getItem("plugoUserId");
        Axios.get(
          `${
            API_CONFIG.PLUGO_REPORTS_API_HOST
          }/report/stores/${userId}?startDate=${startDate}&endDate=${endDate}`
        ).then(res => {
          this.setState({
            standardUserData: res.data,
            loading: false
          });
        });
      }
    );
  };
  render() {
    const { classes } = this.props;
    let pickUpData = this.state.pickUpData.map((row, index) => {
      return {
        pincode: row.pincode,
        storeName: row.storeName,
        totalUser: row.totalUsers,
        standardUser: row.standardUsers,
        premiumUsers: row.premiumUsers
      };
    });
    let standardUserData = this.state.standardUserData.map((row, index) => {
      return {
        pincode: row.pincode,
        storeName: row.storeName,
        totalUser: row.totalUsers,
        standardUser: row.standardUsers,
        premiumUsers: row.premiumUsers
      };
    });
    return (
      <div>
        <Card>
          <CardHeader color="success">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4 className={classes.cardTitleWhite}>User Details By Stores</h4>
              <img
                onClick={this.handleExcel}
                title="Download as Excel"
                className={classes.downloadStyle}
                src={DownloadIcon}
              />
            </div>
          </CardHeader>
          <CardBody>
            <DateRange
              showReport={this.showReport}
              showDefaultReport={this.showDefaultReport}
              fetchStandardReportData={this.fetchStandardReportData}
            />
            {this.state.loading ? <CircularProgress /> : null}
            <ReactTable
              data={standardUserData}
              onFetchData={this.fetchData}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center" },
                  Header: "Pincode",
                  minWidth: 10,
                  id: "pincode",
                  accessor: d => d.pincode,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["pincode"] }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center" },
                  Header: "Store Name",
                  minWidth: 10,
                  id: "storeName",
                  accessor: d => d.storeName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["storeName"] }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center" },
                  Header: "Total User",
                  minWidth: 10,
                  id: "totalUser",
                  accessor: d => d.totalUser,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["totalUser"] }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center" },
                  Header: "Standard User",
                  minWidth: 10,
                  id: "standardUser",
                  accessor: d => d.standardUser,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["standardUser"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center" },
                  Header: "Premium User",
                  minWidth: 10,
                  id: "premiumUsers",
                  accessor: d => d.premiumUsers,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["premiumUsers"]
                    }),
                  filterAll: true
                }
              ]}
              className="-striped -highlight"
              ref={r => (this.reactTable = r)}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
IncomeReport.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  city: state.city.city,
  pincodes: state.city.pincodes
});
export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(withStyles(styles)(IncomeReport));
