import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import reducers from './reducers'
import Routes from './routes/index';

import "assets/css/material-dashboard-react.css?v=1.6.0";

const history = createBrowserHistory();
window.isHyd = false;

const componentEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, {}, componentEnhancers(applyMiddleware(ReduxThunk)))


const jsx = (
  <Provider store={store}>
    <div>
      <Router history={history}>
        <Routes />
      </Router>
    </div>
  </Provider>
)

let hasRendered = false
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
  }
}

ReactDOM.render(<div>Loading...</div>, document.getElementById('root'));

let token = sessionStorage.getItem('plugoAuthKey');
let userId = sessionStorage.getItem('plugoUserId');
if (token) {
  window.isHyd = userId ===  '48cc1a08-2f8d-4c68-fa21-42a4079b3f56'
  store.dispatch({
    type: 'TOKEN_LOGIN',
    payload: {
      token
    }
  })
  renderApp();
  if (history.location.pathname === '/login') {
    history.push('/admin');
  }
} else {
  renderApp();
  history.push(history.location.pathname)
}
