import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DownloadIcon from "assets/img/DownloadIcon.png";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import ReactTable from "react-table";
import Axios from "axios";
import { API_CONFIG } from "../../../../config/config.jsx";
import matchSorter from "match-sorter";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 1000
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  title: {
    fontSize: 14
  },
  downloadStyle: {
    width: 20,
    height: 20,
    marginLeft: 775,
    cursor: "pointer",
    marginBottom: "2px"
  }
});

const createWorkbook = (records, showDateReport) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "SheetJS Tutorial",
    Subject: "Test",
    Author: "Red Stapler",
    CreatedDate: new Date(2019, 12, 19)
  };
  wb.SheetNames.push("Rental Report");
  const data = records.data.map(obj => [
    obj.storeName,
    obj.dispensed,
    obj.returned
  ]);
  const ws_data = [["Store Name", "Dispensed", "Returned"], ...data];
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Rental Report"] = ws;
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  return wbout;
};
const s2ab = s => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};
class TransactionReportByStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: "",
      selectedPincodes: [],
      pincode: "",
      cityOpen: false,
      pinOpen: false,
      showTable: false,
      storeData: [],
      dateReportData: []
    };
    this.reactTable = React.createRef();
  }
  pinHandleChange = event => {
    this.setState(
      {
        pincode: event.target.value
      },
      () => this.getTransactionReportByStoreData()
    );
  };
  getTransactionReportByStoreData = () => {
    const userId = sessionStorage.getItem("plugoUserId");
    let startDate = "";
    let endDate = "";
    const date = new Date();
    if (this.props.startDate == "" || this.props.endDate == "") {
      startDate = new Date(date.getFullYear(), date.getMonth(), 2)
        .toISOString()
        .slice(0, 10);
      endDate = new Date().toISOString().slice(0, 10);
    } else {
      startDate = this.props.startDate;
      endDate = this.props.endDate;
    }

    Axios.get(
      `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/transactions/${
        this.props.city
      }/${
        this.state.pincode
      }/store/${userId}?startDate=${startDate}&endDate=${endDate}`
    ).then(res => {
      this.setState({
        dateReportData: res.data,
        showTable: true
      });
    });
  };
  componentWillReceiveProps = nextProps => {
    if (nextProps.selectedPin && nextProps.city === this.props.city) {
      this.setState(
        {
          pincode: nextProps.selectedPin
        },
        () => this.getTransactionReportByStoreData()
      );
    } else {
      this.setState({
        pincode: "",
        showTable: false
      });
    }
  };
  pinHandleOpen = () => {
    this.setState({ pinOpen: true });
  };
  pinHandleClose = () => {
    this.setState({ pinOpen: false });
  };
  handleExcel = () => {
    const records = this.reactTable.getResolvedState();
    saveAs(
      new Blob([s2ab(createWorkbook(records, true))], {
        type: "application/octet-stream"
      }),
      "test.xlsx"
    );
  };

  getDateReport = () => {
    let dateReport = this.state.dateReportData.map((row, index) => {
      return {
        storeName: row.name,
        dispensed: row.despensed,
        returned: row.returned
      };
    });
    return (
      <div>
        <ReactTable
          data={dateReport}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              headerStyle: {
                fontSize: "16px",
                height: "40px",
                paddingTop: "10px"
              },
              style: { textAlign: "center" },
              Header: "Store Name",
              id: "storeName",
              accessor: d => d.storeName,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["storeName"] }),
              filterAll: true
            },
            {
              headerStyle: {
                fontSize: "16px",
                height: "40px",
                paddingTop: "10px"
              },
              style: { textAlign: "center" },
              Header: "Dispensed",
              id: "dispensed",
              accessor: d => d.dispensed,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["dispensed"] }),
              filterAll: true
            },
            {
              headerStyle: {
                fontSize: "16px",
                height: "40px",
                paddingTop: "10px"
              },
              style: { textAlign: "center" },
              Header: "Returned",
              id: "returned",
              accessor: d => d.returned,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["returned"] }),
              filterAll: true
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
          ref={r => (this.reactTable = r)}
        />
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    let menuPincodes = this.props.pincodes.map(menuPincode => (
      <MenuItem key={menuPincode} value={menuPincode}>
        {menuPincode}
      </MenuItem>
    ));
    return (
      <div>
        <Card>
          <CardHeader color="success">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4 className={classes.cardTitleWhite}>
                Power Bank Rental Report By Store
              </h4>
              <img
                onClick={this.handleExcel}
                title="Download as Excel"
                className={classes.downloadStyle}
                src={DownloadIcon}
              />
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <form
                  className={classes.form}
                  autoComplete="off"
                  style={{ marginLeft: "45%" }}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="demo-controlled-open-select">
                      Pincode
                    </InputLabel>
                    <Select
                      open={this.state.pinOpen}
                      onClose={this.pinHandleClose}
                      onOpen={this.pinHandleOpen}
                      value={this.state.pincode}
                      onChange={this.pinHandleChange}
                      inputProps={{
                        name: "pincode",
                        id: "demo-controlled-open-select"
                      }}
                    >
                      {menuPincodes}
                    </Select>
                  </FormControl>
                </form>
              </GridItem>
            </GridContainer>

            {this.state.showTable ? this.getDateReport() : ""}
          </CardBody>
        </Card>
      </div>
    );
  }
}
TransactionReportByStore.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TransactionReportByStore);
