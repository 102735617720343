import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import StoreReport from "./StoreReport.jsx";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 1000
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});
class BoxInventoryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeNames: [],
      city: "",
      selectedPincodes: [],
      pincodes: [],
      pincode: "",
      cityOpen: false,
      pinOpen: false,
      showTable: false
    };
  }
  pinHandleChange = event => {
    this.setState({
      pincode: event.target.value,
      showTable: true
    });
  };
  // cityHandleChange = event => {
  //   let selectedCity = this.state.cityWithPins.find(selectCity => {
  //     return selectCity.City === event.target.value
  //   });
  //   this.setState({
  //     city: event.target.value,
  //     selectedPincodes: selectedCity.Pincodes
  //   });
  // };

  componentWillReceiveProps = nextProps => {
    // this.setState({
    //   pincode: '',
    //   showTable: false,
    // });

    if (nextProps.selectedPin && nextProps.city === this.props.city) {
      this.setState({
        pincode: nextProps.selectedPin,
        showTable: true
      });
    } else {
      this.setState({
        pincode: "",
        showTable: false
      });
    }
  };
  pinHandleOpen = () => {
    this.setState({ pinOpen: true });
  };
  pinHandleClose = () => {
    this.setState({ pinOpen: false });
  };
  cityHandleClose = () => {
    this.setState({ cityOpen: false });
  };
  cityHandleOpen = () => {
    this.setState({ cityOpen: true });
  };

  render() {
    const { classes } = this.props;
    let menuPincodes = this.props.pincodes.map(menuPincode => (
      <MenuItem key={menuPincode} value={menuPincode}>
        {menuPincode}
      </MenuItem>
    ));
    return (
      <div>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Store Report</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <form
                  className={classes.form}
                  autoComplete="off"
                  style={{ marginLeft: "45%" }}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="demo-controlled-open-select">
                      Pincode
                    </InputLabel>
                    <Select
                      open={this.state.pinOpen}
                      onClose={this.pinHandleClose}
                      onOpen={this.pinHandleOpen}
                      value={this.state.pincode}
                      onChange={this.pinHandleChange}
                      inputProps={{
                        name: "pincode",
                        id: "demo-controlled-open-select"
                      }}
                    >
                      {menuPincodes}
                    </Select>
                  </FormControl>
                </form>
              </GridItem>
            </GridContainer>

            {this.state.showTable ? (
              <StoreReport pincode={this.state.pincode} />
            ) : (
              ""
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}
BoxInventoryReport.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BoxInventoryReport);
