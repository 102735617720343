import React, { Component } from "react";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { withStyles } from "@material-ui/core/styles";
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from "axios";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import matchSorter from "match-sorter";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

class MerchantRevenueReport extends Component {
  state = {
    files: [],
    filenames: [],
    activefile: "",
    index: [],
    monthnames: [],
    check: [
      {
        name: "No Files",
        month: "",
        link: "",
        index: 1
      }
    ],
    filterfile: [],
    isLoading: false
  };

  currentfile = val => {
    var elem = document.createElement("a");
    elem.setAttribute("href", val);
    elem.setAttribute("download", "download");
    elem.click();
  };

  componentWillMount() {
    this.fetchMerchantRevenue();
  }

  componentDidMount() {
    setTimeout(() => {
      var get = document.getElementById("acdr");
      if (get != null) {
        get.click();
      }
    }, 1000);
  }

  sortByMonth(arr) {
    var months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12
    };

    arr.sort(function(a, b) {
      const aString = a.split("-");
      const bString = b.split("-");
      return months[aString[0]] - months[bString[0]];
    });

    arr.sort(function(a, b) {
      const aString = a.split("-");
      const bString = b.split("-");
      return aString[1] - bString[1];
    });
  }

  fetchMerchantRevenue = () => {
    var userid = sessionStorage.getItem("plugoUserId");
    this.setState({ isLoading: true });
    Axios.get(
      `${
        API_CONFIG.PLUGO_REPORTS_API_HOST
      }/report/user/merchant/revenue/${userid}/`
    ).then(res => {
      res.data.map((elem, index) => {
        var getname = elem.split("/");
        var flag = false;
        const month = getname[getname.length - 1].split("_")[1].toUpperCase();
        const year = getname[getname.length - 1].split("_")[2].split(".")[0];
        const months = month + "-" + year;

        var getfilename = getname[getname.length - 1].split("_")[0];
        if (this.state.monthnames[this.state.monthnames.length - 1] != months) {
          for (var a = 0; a < this.state.monthnames.length; a++) {
            if (this.state.monthnames[a] == months) {
              flag = true;
            }
          }
          if (flag == false) {
            this.setState({
              monthnames: [...this.state.monthnames, months]
            });
          }
        }

        this.setState(
          {
            filterfile: [
              ...this.state.filterfile,
              {
                name: getfilename,
                month: months,
                link: (
                  <button
                    className="btn btn-success"
                    onClick={() => this.currentfile(elem)}
                  >
                    Download Report
                  </button>
                )
              }
            ],
            files: [...this.state.files, { file: elem }]
          },
          this.sortByMonth(this.state.monthnames)
        );
        this.setState({ isLoading: false });
      });
    });
  };

  filter_month = month => {
    let arr = [];
    let new_arr = this.state.filterfile.map(elem => {
      if (elem.month == month) {
        arr.push(elem);
      }
    });
    this.setState({
      filenames: arr
    });

    // setTimeout(() => {
    //   console.log(this.state)
    // }, 2000);
  };

  render() {
    const columns = [
      {
        Header: "Name",
        accessor: d => d.name,
        id: "name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true
      },
      {
        Header: "Month",
        accessor: d => d.month,
        id: "month",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["month"] }),
        filterAll: true
      },
      {
        Header: "File",
        accessor: d => d.link,
        id: "link",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["link"] }),
        filterAll: true
      }
    ];
    let acc = (
      <Accordion
        allowZeroExpanded="true"
        className="-striped -highlight text-center"
      >
        {this.state.monthnames.map(elem => {
          return (
            <AccordionItem uuid={elem.index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Card className="shadow-sm">
                    <button
                      id="acdr"
                      className="col-xl-12 btn text-left"
                      onClick={() => this.filter_month(elem)}
                    >
                      {elem}
                    </button>
                  </Card>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ReactTable
                  className="-striped -highlight text-center"
                  defaultPageSize={5}
                  columns={columns}
                  data={this.state.filenames}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                />
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    );

    const { classes } = this.props;
    if (this.state.isLoading) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <div>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Merchant Revenue</h4>
          </CardHeader>
          {acc}
        </Card>
      </div>
    );
  }
}

// export default MerchantRevenueReport;
MerchantRevenueReport.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MerchantRevenueReport);
