import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Button, Table } from 'reactstrap';

var Moment = require("moment");

const styles = theme => ({
    divStyle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-end",
        fontSize: "12px"
    }
});
class UserCoupons extends React.Component {
    state = {
        showTransaction: false,
        showText: true
    };
    render() {
        const { classes, userCoupons } = this.props;
        const getTableData = () => {
            return (
                this.props.userCoupons.map(d =>
                    <tr key={d.id}>
                        <td>{d.name}</td>
                        <td>{d.code}</td>
                        {process.env.REACT_APP_ACTIVE_REGION === "europe" ?
                            <>
                                <td>{d.value}</td>
                                <td>{d.type}</td>
                                <td>{d.duration}</td>
                            </>
                            : ""}
                    </tr>
                )


            )
        }
        return (
            <div>
                {this.props.userCoupons.length !== 0 ?
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Code</th>
                                {process.env.REACT_APP_ACTIVE_REGION === "europe" ?
                                    <>
                                        <th>Value</th>
                                        <th>Type</th>
                                        <th>Hours Left</th>
                                    </>
                                    : ""}
                            </tr>
                        </thead>
                        <tbody>
                            {getTableData()}
                        </tbody>
                    </Table>
                    : ""}
                <Divider />

            </div>
        );
    }
}

UserCoupons.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserCoupons);
