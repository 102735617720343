import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Axios from 'axios';
import { API_CONFIG } from "../../../config/config.jsx";
import ReactTable from "react-table";
import matchSorter from 'match-sorter';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Popover from '@material-ui/core/Popover';
import { Input, Button, InputGroup} from 'reactstrap';
import moment from 'moment';

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "0px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
});
class RentalHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rentalHistoryData: [],
            // text: ' To ',
            open: false,
            startDate: moment().subtract(30, 'days').toDate(),
            endDate: new Date(),
            showDefaultReport: false,
            openPopOver: false,
        };
    }
    handleSelect = (ranges) => {
        console.log(ranges.selection);
        this.setState({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            // dateValue: moment(ranges.selection.startDate).format('MMM-DD-YYYY') + this.state.text + moment(ranges.selection.endDate).format('MMM-DD-YYYY')
        });
    }
    handleClick = (event) => {
        console.log(event);
        this.setState({
            open: !this.state.open,
            openPopOver: event.currentTarget
        });
    }
    handleClose = () => {
        this.setState({
            openPopOver: false
        })
    };
    handleApply= () => {
        this.setState({
            openPopOver: false
        },()=>{this.fetchData()})
        // const startDate = moment(this.state.startDate).format('DD-MM-YYYY');
        // const endDate = moment(this.state.endDate).format('DD-MM-YYYY');
        // this.props.fetchStandardReportData(startDate, endDate, true);
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');
        const startDate = moment(this.state.startDate).format('DD.MM.YYYY');
        const endDate = moment(this.state.endDate).format('DD.MM.YYYY');

        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/rentals?startTime=${startDate}&endTime=${endDate}&userId=${userId}`
        )
            .then(res => {
                console.log(res);
                this.setState({
                    rentalHistoryData: res.data
                });
            });
    }
    // }
    render() {
        console.log(this.state.startDate);
        const { classes } = this.props;
        const selectionRange = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            key: 'selection',
        }
        let rentalHistoryData = this.state.rentalHistoryData.map((row, index) => {
            return {
                mobile: row.mobile,
                rentStoreName: row.rentStoreName,
                rentStorePincode: row.rentStorePincode,
                returnStoreName: row.returnStoreName,
                returnStorePincode: row.returnStorePincode,
                startTime: moment(row.rentStartTime).format('LLL'),
                endTime: moment(row.rentEndTime).format('LLL'),
                couponCode: row.couponCode,
                paymentStatus: row.paymentStatus,
            }
        });
        return (
            <div>
                <Card>
                    <CardHeader color="success" >
                        <h4 className={classes.cardTitleWhite}>Rental History</h4>
                    </CardHeader>
                    <CardBody>
                        <div style={{ marginBottom: "10px" }}>
                            <InputGroup style={{ width: "50%" }}>
                                <Input
                                    value={moment(this.state.startDate).format('DD.MM.YYYY') + " To " + moment(this.state.endDate).format('DD.MM.YYYY')}
                                    onClick={this.handleClick}
                                    autoComplete="off"
                                    // placeholder="Select Date Range"
                                />
                            </InputGroup>
                            <Popover
                                open={this.state.openPopOver}
                                // anchorEl={anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div>
                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={this.handleSelect}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        minDate={moment().subtract(2, 'months').startOf('month').toDate()}
                                        direction="horizontal"
                                        ranges={[selectionRange]}
                                    />
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" style={{ float: "right", margin: "2px 10px" }} onClick={this.handleApply}>Apply</Button>
                                </div>
                            </Popover>
                        </div>
                        <ReactTable
                            data={rentalHistoryData}
                            // onFetchData={this.fetchData}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}

                            columns={[
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Mobile",
                                    id: "mobile",
                                    accessor: d => d.mobile,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["mobile"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Rent Store Name",
                                    id: "rentStoreName",
                                    accessor: d => d.rentStoreName,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["rentStoreName"] }),
                                    filterAll: true
                                },

                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Rent Store Pincode",
                                    id: "rentStorePincode",
                                    accessor: d => d.rentStorePincode,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["rentStorePincode"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Return Store Name",
                                    id: "returnStoreName",
                                    accessor: d => d.returnStoreName,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["returnStoreName"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Return Store Pincode",
                                    accessor: "returnStorePincode",
                                    id: "returnStorePincode",
                                    accessor: d => d.returnStorePincode,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["returnStorePincode"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 11,
                                    Header: "Start Time",
                                    id: "startTime",
                                    accessor: d => d.startTime,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["startTime"] }),
                                    filterAll: true,
                                    sortMethod: (a, b) => {
                                        const a1 = moment(a, 'LLL');
                                        const b1 = moment(b, 'LLL');
                                        if (a1.isBefore(b1))
                                            return 1;
                                        else if (a1.isAfter(b1))
                                            return -1;
                                        else
                                            return 0;
                                    }
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 11,
                                    Header: "End Time",
                                    id: "endTime",
                                    accessor: d => d.endTime,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["endTime"] }),
                                    filterAll: true,
                                    sortMethod: (a, b) => {
                                        const a1 = moment(a, 'LLL');
                                        const b1 = moment(b, 'LLL');
                                        if (a1.isBefore(b1))
                                            return 1;
                                        else if (a1.isAfter(b1))
                                            return -1;
                                        else
                                            return 0;
                                    }
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Coupon Code",
                                    accessor: "couponCode",
                                    id: "couponCode",
                                    accessor: d => d.couponCode,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["couponCode"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    minWidth: 10,
                                    Header: "Payment Status",
                                    accessor: "paymentStatus",
                                    id: "paymentStatus",
                                    accessor: d => d.paymentStatus,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["paymentStatus"] }),
                                    filterAll: true
                                }
                            ]}
                            // defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }

}
RentalHistory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RentalHistory);