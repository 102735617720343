import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from "@material-ui/core";

// core components
import AddButton from './AddButton';
import CouponRulesTable from './CouponRulesTable.jsx';
import AddCouponRule from "./AddCouponRule.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx";
import { API_CONFIG } from "../../config/config.jsx";
import Axios from 'axios';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      marginLeft: "10px"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "5px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    marginLeft: "10px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};
class CouponRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      couponRule: {
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        pincode: '',
        city: '',
      },
      couponRules: [],
      open: false
    }
  }
  componentDidMount() {
    this.getCouponRulesData();
  }
  getCouponRulesData = () => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    Axios.get(`${API_CONFIG.HOST}/coupons/${this.props.selectedCouponId}/rules?size=1000`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        this.setState({
          couponRules: res.data.content
        });
      })
  }
  openAddCouponRuleDailog = () => {
    this.setState({
      open: true
    });
  }
  handleClose = () => {
    this.setState({
      open: false
    });
  }
  onSubmit = (couponRule) => {
    if (couponRule) {
      this.setState({
        open: false,
      })
    }
    this.getCouponRulesData();
  }

  handleDelete = (couponRuleId) => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    const couponRules = [...this.state.couponRules];

    const deletedCouponRules = couponRules.filter(couponRule => {
      return couponRule.id !== couponRuleId
    });

    Axios.delete(`${API_CONFIG.HOST}/coupons/${this.props.selectedCouponId}/rules/${couponRuleId}`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "X-USER-ID": userId
        }
      })
      .then(res => {
        this.setState({
          couponRules: deletedCouponRules
        });
      });
  }

  handleActive = (event, couponRuleId) => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    const couponRules = [...this.state.couponRules];

    const updatedCouponRule = couponRules.find(couponRule => {
      return couponRule.id === couponRuleId;
    });

    updatedCouponRule.active = event.target.checked;

    Axios.put(`${API_CONFIG.HOST}/coupons/${this.props.selectedCouponId}/rules/${couponRuleId}`,
      updatedCouponRule, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          couponRules: couponRules
        });
      })
  }
  render() {
    const { classes } = this.props;
    const headers = [
      { id: 'startDate', label: 'Start date' },
      { id: 'endDate', label: 'End date' },
      { id: 'startTime', label: 'Start time' },
      { id: 'endTime', label: 'End time' },
      { id: 'pincode', label: 'Pincode' },
      { id: 'city', label: 'City' },
      { id: 'userId', label: 'User Id' },
      { id: 'storeId', label: 'Store Id' },
      { id: 'active', label: 'Active' },
      { id: 'action', label: 'Action' },
    ]
    const style = {
      float: 'right',
      position: 'relative',
      top: -53
    }
    const rows = this.state.couponRules;

    return (
      <div className="coupons">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader style={{ padding: 0, height: "75px" }} color="success">
                <h4 className={classes.cardTitleWhite}>Manage Coupon({this.props.couponName})</h4>
                <p className={classes.cardCategoryWhite}>
                  Manage Rules for coupon
                </p>
                <div style={style}><AddButton onClick={this.openAddCouponRuleDailog} /></div>
              </CardHeader>
              <CardBody>
                <CouponRulesTable headers={headers} rows={rows} handleActive={this.handleActive} handleDelete={this.handleDelete} />
                <Dialog
                  onClose={this.handleClose}
                  open={this.state.open}
                  maxWidth='md'
                  // minWidth='400px'
                  aria-labelledby="simple-dialog-title"
                >
                  <DialogTitle id="simple-dialog-title">Add a Rule</DialogTitle>
                  <DialogContent>
                    <AddCouponRule selectedCouponId={this.props.selectedCouponId} submitClose={this.onSubmit} ></AddCouponRule>
                  </DialogContent>
                </Dialog>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
CouponRules.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CouponRules);