import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from 'axios';
import {dateFormat} from '../../../Constant.jsx';

var Moment = require("moment");

const styles = theme => ({
    divStyle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-end",
        fontSize: "12px"
    }
});
class RentalHistory extends React.Component {
    state = {
        showRental: false,
        showText: true,
        pickUpStoreName: '',
        dropStoreName: '',
        PowerbankDeviceId: ''
    };
    componentDidMount() {
        const rentStoreId = this.props.rentalUserHistory.rentStoreId;
        const returnStoreId = this.props.rentalUserHistory.returnStoreId;
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/stores/${rentStoreId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    pickUpStoreName: res.data.name
                });
            });
        Axios.get(`${API_CONFIG.HOST}/stores/${returnStoreId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    dropStoreName: res.data.name
                });
            });
        this.getPBDeviceId();
    }
    getPBDeviceId = () => {
        const powerBankId = this.props.rentalUserHistory.powerbankId;
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/powerbanks/deviceid/${powerBankId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                console.log(res);
                this.setState({
                    PowerbankDeviceId: res.data.deviceId
                });
            });
    }
    componentDidUpdate = (prevProps) => {
        if(this.props.rentalUserHistory.userId !== prevProps.rentalUserHistory.userId) {
        const token = sessionStorage.getItem('plugoAuthKey');
        const rentStoreId = this.props.rentalUserHistory.rentStoreId;
        const returnStoreId = this.props.rentalUserHistory.returnStoreId;
        Axios.get(`${API_CONFIG.HOST}/stores/${rentStoreId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    pickUpStoreName: res.data.name
                });
            });
        Axios.get(`${API_CONFIG.HOST}/stores/${returnStoreId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                this.setState({
                    dropStoreName: res.data.name
                });
            });
        const powerBankId = this.props.rentalUserHistory.powerbankId;
        Axios.get(`${API_CONFIG.HOST}/powerbanks/deviceid/${powerBankId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                console.log(res);
                this.setState({
                    PowerbankDeviceId: res.data.deviceId
                });
            });
        }
    }

    showRental = () => {
        this.setState({
            showRental: !this.state.showRental,
            showText: !this.state.showText
        })
    }
    timeDiff = (startTime, endTime) => {
        var startDate = new Date(startTime);
        var endDate = new Date(endTime);
        var diff = endDate - startDate;
        var hours = Math.floor(diff / 3.6e6);
        var minutes = Math.floor((diff % 3.6e6) / 6e4);
        var seconds = Math.floor((diff % 6e4) / 1000);
        return `${hours} : ${minutes} : ${seconds}`
    }
    render() {
        const { classes, rentalUserHistory } = this.props;
        const time = new Date(rentalUserHistory.startTime);
        const rentalStartTime = time.toLocaleTimeString();
        return (
            <div>
                <div style={{
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px"
                }}>
                    <div>
                        <span>{this.timeDiff(rentalUserHistory.startTime, rentalUserHistory.endTime)}</span>
                        <div><p style={{ fontSize: '12px' }}>{rentalStartTime}</p></div>
                    </div>
                    <div>
                        <div>{Moment(rentalUserHistory.createdAt).format(dateFormat)}</div>
                        <button style={{ display: 'block', borderRadius: "50px", border: '1px solid #eee', backgroundColor: '#28a745', color: "white" }} onClick={this.showRental}>{this.state.showText ? 'More' : 'Less'}</button>
                    </div>
                </div>
                {this.state.showRental ?
                    <ul style={{ paddingLeft: "10px" }}>
                        <li>PowerBank Id: {this.state.PowerbankDeviceId} </li>
                        <li>PickUp Store: {this.state.pickUpStoreName}</li>
                        <li>Drop Store: {this.state.dropStoreName}</li>
                        <li>Coupon: {rentalUserHistory.couponCode}</li>
                    </ul>
                    : ''}
                <Divider />
            </div>
        );
    }
}

RentalHistory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RentalHistory);
