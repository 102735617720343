// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import UserGroup from "@material-ui/icons/Group";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import SubscriptionsIcon from '@material-ui/icons/CardMembership';
import Store from "@material-ui/icons/Store";
import { Redirect, Route, Router } from 'react-router-dom';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import AddSalesLead from "views/AddSalesLead/AddSalesLead.jsx";
import Subscription from "views/Subscription/Subscription.jsx";
import Reports from "views/Reports/ReportTabs.jsx";
import DevTools from "views/DevTools/DevToolsTabs/DevToolsTabs.jsx";
import TeleSupport from "views/TeleSupport/TeleSupportTabs/TeleSupportTabs.jsx";
import AdminCoupons from "views/AdminCoupons/AdminCoupons.jsx";
import StoreGroup from "views/StoreGroup/StoreGroup.jsx";
import PartnerRequest from "views/PartnerRequest/PartnerRequest.jsx";
import UserGroups from "views/UserGroups/UserGroups.jsx";
import { User } from "./User";


const getRoutes = () => {

  let dashboardRoutes = []

  if( process.env.REACT_APP_ACTIVE_REGION == "europe" )
  {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isViewDashboard() 
      },
      {
        path: "/reports",
        name: "Reports",
        icon: "content_paste",
        component: Reports,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isViewRentals() || User.isViewInventory() || User.isViewStoreUser() || User.isViewDefaultCustomer() || User.isViewBoxStatus() || User.isViewRevenue()

      },
      {
        path: "/devTools",
        name: "Dev Tools",
        icon: Store,
        component: DevTools,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isCreateStore() || User.isUpdateBoxes() || User.isUpdateStore() || User.isCreateStoreOperatingHours()
      },
      {
        path: "/partner-request",
        name: "Partner Request",
        icon: Person,
        component: PartnerRequest,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isReadPartnerRequest()
      },
      {
        path: "/tele-support",
        name: "Tele-Support",
        icon: LibraryBooks,
        component: TeleSupport,
        layout: "/admin",
        show: User.isSuperAdmin()|| User.isReadUser() || User.isReadStores()
        
      },
      {
        path: "/coupons",
        name: "Coupons",
        icon: BubbleChart,
        component: AdminCoupons,
        layout: "/admin",
        show: User.isSuperAdmin()  || User.isCreateCoupons() || User.isReadCoupons()
      },
    
    ];
  }
  else
  {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isViewDashboard() 
      },
      {
        path: "/reports",
        name: "Reports",
        icon: "content_paste",
        component: Reports,
        layout: "/admin",
        show:  User.isSuperAdmin() || User.isViewRentals() || User.isViewInventory() || User.isViewStoreUser() || User.isViewDefaultCustomer() || User.isViewBoxStatus() || User.isViewRevenue()
        
      },
      {
        path: "/tele-support",
        name: "Tele-Support",
        icon: LibraryBooks,
        component: TeleSupport,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isUserAdmin()
        
      },
      {
        path: "/coupons",
        name: "Coupons",
        icon: BubbleChart,
        component: AdminCoupons,
        layout: "/admin",
        show: User.isSuperAdmin()  || (User.isCreateCoupons() && User.isReadCoupons())
      },
      {
        path: "/subscription",
        name: "Subscription",
        icon: SubscriptionsIcon,
        component: Subscription,
        layout: "/admin",
        show: User.isSuperAdmin() 
      },
      {
        path: "/store-group",
        name: "Store Group",
        icon: Store,
        component: StoreGroup,
        layout: "/admin",
        show:  User.isSuperAdmin() || User.isCreateStoreGroup()
      },
      {
        path: "/devTools",
        name: "Dev Tools",
        icon: Store,
        component: DevTools,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isCreateStore() || User.isUpdateBoxes() || User.isUpdateStore() || User.isCreateStoreOperatingHours()
      }, 
      {
        path: "/partner-request",
        name: "Partner Request",
        icon: Person,
        component: PartnerRequest,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isReadPartnerRequest()
      }, 
      {
        path: "/user-groups",
        name: "User Groups",
        icon: UserGroup,
        component: UserGroups,
        layout: "/admin",
        show: User.isSuperAdmin() || User.isCreateAccount() || User.isReadAccount()
      }, 
    ];
  }
   
  return dashboardRoutes.filter(route => route.show === true);

}


export default getRoutes