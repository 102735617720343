import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import DownloadIcon from 'assets/img/DownloadIcon.png';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Axios from 'axios';
import { API_CONFIG } from "../../config/config.jsx";
import ReactTable from "react-table";
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Tooltip from '@material-ui/core/Tooltip';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#424242',
        color: 'white',
        maxWidth: 800,
        opacity: 0.5,
        margintop: -300,
        textSizeAdjust: "auto",
        fontSize: theme.typography.pxToRem(17),
        border: '1px solid green',
    },
}))(Tooltip);


const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    hoverStyle: {
        cursor: "pointer",
    },
    title: {
        fontSize: 14,
    },
    downloadStyle: {
        width: 20,
        height: 20,
        marginLeft: "85%",
        cursor: "pointer",
        marginBottom: "2px"
    },
});
const createWorkbook = (records) => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2019, 12, 19)
    };

    wb.SheetNames.push("Partner Request Table");
    const data = records.data.map(obj => [obj.businessName, obj.name, obj.mobile, obj.email, obj.comment]);
    const ws_data = [['Business Name', 'Name', 'Mobile No', 'Email', 'Comment'], ...data];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Partner Request Table"] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    return wbout;
}
const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}

class PartnerRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerReqData: [],
            commentflag: false
        }
        this.reactTable = React.createRef();
    }
    handleExcel = () => {
        const records = this.reactTable.getResolvedState();
        console.log(records);
        saveAs(new Blob([s2ab(createWorkbook(records))], { type: "application/octet-stream" }), 'test.xlsx');
    }
    commentFlag = () => {
        this.setState({
            commentflag: false
        })
    }
    componentDidMount() {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.get(`${API_CONFIG.HOST}/partners/request`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        }
        )
            .then(res => {
                console.log(res);
                this.setState({
                    partnerReqData: res.data
                });
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
    }
    render() {
        const { classes } = this.props;
        let partnersData = this.state.partnerReqData.map((row, index) => {
            return {
                businessName: row.businessName,
                name: row.name,
                mobile: row.mobile,
                email: row.email,
                date: row.updatedAt.split('T')[0],
                comment: row.comment
            }
        });
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <Card>
                    <CardHeader color="success" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h4 className={classes.cardTitleWhite}>Partner Request</h4>
                            <img onClick={this.handleExcel} title="Download as Excel" className={classes.downloadStyle} src={DownloadIcon}></img>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={partnersData}
                            onFetchData={this.fetchData}
                            columns={[

                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px', fontWeight: '400' },
                                    style: { textAlign: "center" },
                                    Header: "Business Name",
                                    accessor: "businessName",
                                    width: 230,
                                    Cell: row => <Tooltip title={row.value}>
                                        <span className={classes.hoverStyle} >{row.value}</span>
                                    </Tooltip>
                                },

                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '400' },
                                    style: { textAlign: "center" },
                                    Header: "Name",
                                    accessor: "name",
                                    width: 160,
                                    Cell: row => <Tooltip title={row.value}>
                                        <span className={classes.hoverStyle} >{row.value}</span>
                                    </Tooltip>
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '400' },
                                    style: { textAlign: "center" },
                                    Header: "Mobile No",
                                    accessor: "mobile",
                                    width: 150,
                                    Cell: row => <Tooltip title={row.value}>
                                        <span className={classes.hoverStyle} >{row.value}</span>
                                    </Tooltip>
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '400' },
                                    style: { textAlign: "center" },
                                    Header: "Date",
                                    accessor: "date",
                                    width: 120,
                                    Cell: row => <Tooltip title={row.value}>
                                        <span className={classes.hoverStyle} >{row.value}</span>
                                    </Tooltip>
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '400' },
                                    style: { textAlign: "center" },
                                    Header: "Email",
                                    accessor: "email",
                                    width: 240,
                                    Cell: row => <Tooltip title={row.value}>
                                        <span className={classes.hoverStyle} >{row.value}</span>
                                    </Tooltip>
                                },
                                {
                                    headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '400' },
                                    style: { textAlign: "left" },
                                    Header: "Comment",
                                    accessor: "comment",
                                    Cell: row => <HtmlTooltip
                                        title={
                                            <div style={{ width: 550 }}>
                                                <div >
                                                    <p style={{ fontSize: 12, fontWeight: "normal", boxSizing: 10 }}>{row.value}</p>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <span className={classes.hoverStyle} >{row.value}</span>
                                    </HtmlTooltip>
                                },
                            ]}
                            className="-striped -highlight"
                            ref={(r) => this.reactTable = r}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

PartnerRequest.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PartnerRequest)