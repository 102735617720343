import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Dialog from "@material-ui/core/Dialog";
import CardBody from "components/Card/CardBody.jsx";
import { API_CONFIG } from "./../../config/config.jsx";
import Axios from 'axios';
import AsyncSelect from 'react-select/async';
import {
    Input,
    Button,
    FormGroup,
    Label,
    InputGroup
} from 'reactstrap';

var Moment = require("moment");
const imgStyle = {
    width: 50,
    height: 45
};
const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "0px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardHeaderStyle: {
        zIndex: "0 !important"
    },
    selectStyle: {
        width: 500
    },
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    divStyle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-around"
    },
    cardCategory: {
        marginBottom: "2px"
    }
});
class Subscription extends React.Component {
    state = {
        mobileNo: '',
        month: '1',
        userName: '',
        openDialog: false

    };
    onChangehandler = (e) => {
        this.setState({ mobileNo: e.target.value })
    }
    onSelectChange = (e) => {
        this.setState({ month: e.target.value })
    }
    onChangeSearchText = (inputValue) => {
        this.setState({
            searchText: inputValue
        })
    };

    userHandleChange = (event) => {
        console.log(event);
        this.setState({
            mobileNo: event.mobile,
            userName: event.firstName
        });
    }
    userLoadOptions = (inputValue) => {
        return this.getUserData(inputValue);
    };
    getUserData = async (inputValue) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        const result = await Axios.get(`${API_CONFIG.HOST}/users/search?mobile=${inputValue}&lastName=${inputValue}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        });
        return result.data.map(data => ({
            value: data.id,
            label: data.mobile + " - " + data.firstName,
            firstName: data.firstName,
            mobile: data.mobile,
            email: data.email,
            referralCode: data.referralCode
        }));
    }
    handleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        const userSubscription = {
            mobile: this.state.mobileNo,
            months: this.state.month,
        }
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.post(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/payment/subscribe/${this.state.mobileNo}`, userSubscription, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                this.setState({
                    mobileNo: '',
                    month: '1',
                    openDialog: false
                })
                var options = {
                    place: 'tc',
                    message: " " + "Submit Successfully",
                    type: 'success',
                    icon: 'far fa-check-circle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
                // window.location.reload(false);
            });
    };


    render() {
        console.log(this.state.mobileNo)
        const { classes } = this.props;
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <Card>
                    <CardHeader color="success">
                        <h4 className={classes.cardTitleWhite}>Free Subscription</h4>
                    </CardHeader>
                    <CardBody>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.userLoadOptions}
                            onChange={this.userHandleChange}
                            defaultOptions
                            placeholder="Search User..."
                            className={classes.selectStyle}
                            onInputChange={this.onChangeSearchText}
                        />
                        <br />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <FormGroup>
                                    <Label for="examplePassword">Mobile No.</Label>
                                    <InputGroup>
                                        <Input
                                            onChange={this.onChangehandler}
                                            id="MobileNo"
                                            autoComplete="off"
                                            value={this.state.mobileNo}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <FormGroup>
                                    <Label for="exampleSelect">Select Month</Label>
                                    <Input type="select" name="select" onChange={this.onSelectChange} id="exampleSelect">
                                        <option value='1'>1 Month</option>
                                        <option value='3'>3 Month</option>
                                        <option value='12'>12 Month</option>
                                    </Input>
                                </FormGroup>
                            </GridItem>
                        </GridContainer>
                        <Button variant="contained" disabled={!this.state.mobileNo} color="primary" onClick={this.handleDialog} className={classes.button}>
                            Submit
                        </Button>
                    </CardBody>
                </Card>
                <Dialog open={this.state.openDialog} onClose={() => this.setState({openDialog: false})} maxWidth="lg">
                    <div style={{ padding: "40px 25px" }}>
                        {/* {this.state.deleteflag ? ( */}
                        <div className="row col-xl-12  mb-3 ">
                            <div className="col-xl-12 text-center">
                                <b className="mt-2">
                                    Are you sure you want to give{" "} subscription to {" "}
                                    <span className="text-primary">
                                        {this.state.userName}: {this.state.mobileNo}
                                    </span>{" "}
                                </b>
                            </div>
                            <div className="col-xl-12 mt-2 ml-4 row justify-content-center d-flex">
                                <button
                                    className="btn btn-success col-xl-3 mr-2 "
                                    onClick={this.handleSubmit}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn btn-danger col-xl-3"
                                    onClick={() =>
                                        this.setState({
                                            openDialog: false
                                        })
                                    }
                                >
                                    No
                                </button>
                            </div>
                        </div>
                        {/* ) : (
                <React.Fragment>{boxes}</React.Fragment>
              )} */}
                    </div>
                </Dialog>
            </div>
        );
    }
}

Subscription.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Subscription);
