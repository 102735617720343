import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { API_CONFIG } from "../../config/config.jsx";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

const styles = theme => ({});

class StoreReport extends React.Component {
  state = {
    storeNames: []
  };
  fetchData = () => {
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");

    Axios.get(
      `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/${
        this.props.pincode
      }/stores/${userId}`
    ).then(res => {
      this.setState({
        storeNames: res.data
      });
    });
  };
  componentWillReceiveProps = nextProps => {
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");
    if (this.props.pincode === nextProps.pincode) {
      return;
    }

    Axios.get(
      `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/${
        nextProps.pincode
      }/stores/${userId}`
    ).then(res => {
      this.setState({
        storeNames: res.data
      });
    });
  };
  render() {
    const { classes } = this.props;
    let storeData = this.state.storeNames.map((row, index) => {
      return {
        storeName: row.name,
        noOfBoxes: row.noOfBoxes,
        noOfPowerBanks: row.noOfPowerbanks,
        readyToRentPowerbanks: row.readyToRentPowerbanks,
        faultyPowerbanks: row.faultyPowerbanks
      };
    });
    return (
      <div>
        <ReactTable
          data={storeData}
          onFetchData={this.fetchData}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              headerStyle: {
                fontSize: "16px",
                height: "40px",
                paddingTop: "10px"
              },
              style: { textAlign: "center" },
              Header: "Store Name",
              id: "storeName",
              accessor: d => d.storeName,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["storeName"] }),
              filterAll: true
            },

            {
              headerStyle: { fontSize: "16px", paddingTop: "10px" },
              style: { textAlign: "center" },
              Header: "No of Boxes",
              id: "noOfBoxes",
              accessor: d => d.noOfBoxes,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["noOfBoxes"] }),
              filterAll: true
            },
            {
              headerStyle: { fontSize: "16px", paddingTop: "10px" },
              style: { textAlign: "center" },
              Header: "No of Power Banks",
              id: "noOfPowerBanks",
              accessor: d => d.noOfPowerBanks,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["noOfPowerBanks"] }),
              filterAll: true
            },
            {
              headerStyle: { fontSize: "16px", paddingTop: "10px" },
              style: { textAlign: "center" },
              Header: "Available",
              id: "readyToRentPowerbanks",
              accessor: d => d.readyToRentPowerbanks,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["readyToRentPowerbanks"]
                }),
              filterAll: true
            },
            {
              headerStyle: { fontSize: "16px", paddingTop: "10px" },
              style: { textAlign: "center" },
              Header: "Faulty",
              id: "faultyPowerbanks",
              accessor: d => d.faultyPowerbanks,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["faultyPowerbanks"] }),
              filterAll: true
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
StoreReport.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StoreReport);
