import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import TextField from '@material-ui/core/TextField';
import { Button, Checkbox } from '@material-ui/core';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Axios from 'axios';
import { API_CONFIG } from "../../config/config.jsx";

const styles = theme => ({
    textField: {
        width: "200px"
    }
});
class CreateAccount extends React.Component {
    state = {
        name: '',
        domainName: '',
        amount: '',
        interval: '',
        restricted: false,
        memberLimit: '',
        floatingLicenceEnabled: false

    };
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    onChangeRestricted = (event) => {
        this.setState({
            restricted: !this.state.restricted
        })
    }
    handleMemberChange = (event) => {
        this.setState({
            memberLimit: event.target.value
        })
    }
    handleFloatingLicenceEnabledChange = () => {
        this.setState({
            floatingLicenceEnabled: !this.state.floatingLicenceEnabled
        })
    }
    submit = event => {
        event.preventDefault();
        const accountData = {
            name: this.state.name,
            domainName: this.state.domainName,
            plan: {
                amount: this.state.amount,
                interval: this.state.interval
            },
            restricted: this.state.restricted,
            memberLimit: this.state.memberLimit,
            isFloatingLicenceEnabled: this.state.floatingLicenceEnabled

        }
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.post(`${API_CONFIG.HOST}/accounts`, accountData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                console.log(res);
                this.setState({
                    name: '',
                    domainName: '',
                    amount: '',
                    interval: '',
                    restricted: '',
                    memberLimit: '',
                    floatingLicenceEnabled: ''
                });
                window.location.reload(false);
                var options = {
                    place: 'tc',
                    message: " " + "Created Successfully",
                    type: 'success',
                    icon: 'far fa-check-circle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })

    };
    render() {
        const buttonStyle = {
            position: 'relative',
            marginLeft: '40%',
            marginTop: '5%'
        };

        const { classes } = this.props;

        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <form className={classes.container} onSubmit={this.submit} >
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <TextField
                                        required
                                        id="outlined-name"
                                        label="Name"
                                        value={this.state.name}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('name')}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <TextField
                                        required
                                        id="outlined-domainName"
                                        label="Domain Name"
                                        value={this.state.domainName}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('domainName')}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <TextField
                                        id="outlined-amount"
                                        label="Amount"
                                        type="number"
                                        value={this.state.amount}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('amount')}
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <TextField
                                        required
                                        id="outlined-interval"
                                        label="Interval(No of Month)"
                                        value={this.state.interval}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('interval')}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <div style={{ marginTop: "30px" }}>
                                        <label style={{ fontSize: "16px", color: "#3c4858", fontWeight: 200 }}>Restricted</label>
                                        <Checkbox
                                            checked={this.state.restricted}
                                            value={this.state.restricted}
                                            onChange={this.onChangeRestricted}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                            style={{ color: "#3f51b5" }}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <TextField
                                        required={this.state.restricted}
                                        id="outlined-memberLimit"
                                        label="Member Limit"
                                        value={this.state.memberLimit}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleMemberChange}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <div style={{ marginTop: "25px" }}>
                                        <label style={{ fontSize: "16px", color: "#3c4858", fontWeight: 200 }}>Floating Licence Enabled</label>
                                        <Checkbox
                                            checked={this.state.floatingLicenceEnabled}
                                            value={this.state.floatingLicenceEnabled}
                                            onChange={this.handleFloatingLicenceEnabledChange}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                            style={{ color: "#3f51b5" }}
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <div style={buttonStyle}>
                                <Button type="submit" variant="contained" size="large" className={classes.button}>
                                    Create
                                </Button>
                            </div>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

}
CreateAccount.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateAccount);