import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from '@material-ui/core/Switch';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from "@material-ui/core";
import { Checkbox } from '@material-ui/core';
// core components
import OpertingHourIcon from "assets/img/watchIcon.png";
import UpdateOperatingHours from "./UpdateOperatingHour.jsx";
import UploadImage from "./UploadImage.jsx";
import UpdateStore from "./UpdateStore.jsx";
import NotificationAlert from "react-notification-alert";
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from 'axios';
import Secure from '../../../components/Secure.jsx';
import UpdateBox from "./UpdateBox.jsx";
// import "../../../assets/css/editUpdateStore.css"
import Tooltip from "@material-ui/core/Tooltip";
import boxicon from "../../../assets/img/delivery.png";

const style = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    imgStyle: {
        width: 35,
        height: 35,
        cursor: "pointer"
    },
    fileStyle: {
        width: "130px"
    },
    pStyle: {
        // marginTop: "20px",
    }
});

class UpdateEditStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            storeData: [],
            stores: [],
            storeId: '',
            open: false,
            openUploadDialog: false,
            reviewApplicable: false,
            boxes:[],
            drawerflag:false,     
            storeName: ''      
        }
    }
    openBoxDialog = (id, storeName) => {
        this.setState({
            storeName: storeName,
            drawerflag: true,
            storeId: id,
        })
    }
    drawerdisplay() {
      this.setState({
        drawerflag: !this.state.drawerflag,
       
      });
    }

    handleOpen = (id) => {
        this.setState({
            open: true,
            storeId: id

        });
    }
    handleClose = () => {
        this.setState({
            open: false
        });
    }
    handleOpenUploadDialog = (id) => {
        this.setState({
            openUploadDialog: true,
            storeId: id
        });
    }
    handleCloseUploadDialog = () => {
        this.setState({
            openUploadDialog: false
        });
    }
    handleOpenEditDialog = (storesData) => {
        this.setState({
            openEditDialog: true,
            stores: storesData
        });
    }
    handleCloseEditDialog = () => {
        this.setState({
            openEditDialog: false
        });
        this.getStores();
    }
    onChangeVisible = () => {
        this.setState({
            isVisible: !this.state.isVisible
        })
    }
    onReviewApplicableChange = () => {
        this.setState({
            reviewApplicable: !this.state.reviewApplicable
        })
    }
    onChangeFileHandler = (event, storeId) => {
        this.setState({
            excelFile: event.target.files[0]
        })
    }
    componentDidMount() {
        this.getStores();
    }
    getStores = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        Axios.get(`${API_CONFIG.HOST}/store/all`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        })
            .then(res => {
                const mobileNo = sessionStorage.getItem('plugoUserMobileNo');
                // if(mobileNo.startsWith('+31')){
                //     const data = res.data.filter(d => {
                //         return d.pincode === '4431 NK'
                //     })
                //     this.setState({
                //         storeData: data
                //     })
                //     return;
                // }

                this.setState({
                    storeData: res.data,
                })
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
    }
    onSubmit = () => {
        this.setState({
            open: false
        })
    }
    render() {
        const { classes } = this.props;
        // const mobileNo = sessionStorage.getItem('plugoUserMobileNo');
        // console.log(mobileNo.startsWith('+31'));
        // if(mobileNo.startsWith('+31')){
        //     const data = this.state.storeData.filter(d => {
        //         return d.pincode === '4431 NK'
        //     }).map(data => {
        //         return {
        //             id: data.id,
        //             name: data.name,
        //             address: data.address,
        //             mobile: data.mobile,
        //             floor: data.floor,
        //             city: data.city,
        //             pincode: data.pincode,
        //             state: data.state,
        //             latitude: data.lat,
        //             longitude: data.lng,
        //             business: data.business,
        //             isVisible: data.isVisible,
        //             reviewApplicable: data.reviewApplicable,
        //             operatingHour: data.operatingHour,
        //             isEnableMobileNumber: data.enableMobileNumber
    
        //         }
        //     })
        // }
        const data = this.state.storeData.map(data => {
            return {
                id: data.id,
                name: data.name,
                address: data.address,
                mobile: data.mobile,
                floor: data.floor,
                city: data.city,
                pincode: data.pincode,
                state: data.state,
                latitude: data.lat,
                longitude: data.lng,
                business: data.business,
                isVisible: data.isVisible,
                reviewApplicable: data.reviewApplicable,
                operatingHour: data.operatingHour,
                isEnableMobileNumber: data.enableMobileNumber

            }
        })
        return (
            <div>
                <br />
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <MaterialTable
                    title="Update Store and Operating Hours"
                    columns={[
                        {title: "View Boxes",field: "id",render : rowData => ( <Tooltip title="Click to see boxes" placement="top">
                                         <a onClick={() => this.openBoxDialog(rowData.id, rowData.name)}>
                                          <img
                                              src={boxicon}
                                              style={{ width: "35px", height: "35px" }}
                                            />
                                          </a>
                                        </Tooltip>
                                      )
                                    },
                        { title: 'Name', field: 'name' },
                        { title: 'Address', field: 'address', render: rowData =><span>{rowData.address} <a onClick={() => this.handleOpenEditDialog(rowData)} style={{ cursor: "pointer", color: "blue" }} >Edit</a></span> },
                        { title: 'Business', field: 'business', },
                        { title: 'Visible', field: 'isVisible', type: 'boolean', render: rowData => <Switch checked={rowData.isVisible} onChange={this.onChangeVisible} color="primary" /> },
                        { title: 'Review Applicable', field: 'reviewApplicable', type: 'boolean', render: rowData => <Checkbox checked={rowData.reviewApplicable} onChange={this.onReviewApplicableChange} color="primary" /> },
                        { title: 'Update Operating Hours', field: 'operatingHour', render: rowData => Secure("CREATE_STORE_OPERATING_HOURS", <img onClick={() => this.handleOpen(rowData.id)} className={classes.imgStyle} src={OpertingHourIcon}></img>) },
                        { title: 'Upload Image', field: 'uploadImage', render: rowData => Secure("UPLOAD_S3", <a onClick={() => this.handleOpenUploadDialog(rowData.id)} style={{ cursor: "pointer", color: "blue" }} >Upload</a>) }
                    ]}
                    data={data}
                    editable={{
                        // onRowAdd: newData =>
                        //     new Promise((resolve, reject) => {
                        //         setTimeout(() => {
                        //             resolve()
                        //         }, 1000)
                        //     }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    {
                                        const storeData = [...this.state.storeData];
                                        const index = storeData.findIndex(data => {
                                            return data.id === newData.id
                                        });
                                        console.log(oldData);
                                        const editStoreData = {
                                            id: newData.id,
                                            name: newData.name,
                                            mobile: newData.mobile,
                                            address: newData.address,
                                            city: newData.city,
                                            floor: newData.floor,
                                            pincode: newData.pincode,
                                            state: newData.state,
                                            latitude: newData.lat,
                                            longitude: newData.lng,
                                            business: newData.business,
                                            website: newData.website,
                                            isVisible: newData.isVisible,
                                            reviewApplicable: newData.reviewApplicable,
                                            isEnableMobileNumber: newData.enableMobileNumber

                                        }
                                        const token = sessionStorage.getItem('plugoAuthKey');
                                        const userId = sessionStorage.getItem('plugoUserId');
                                        Axios.put(`${API_CONFIG.HOST}/stores/${oldData.id}/update`, editStoreData, {
                                            headers: {
                                                "Authorization": `Bearer ${token}`,
                                                "X-USER-ID": userId
                                            }
                                        })
                                            .then(res => {
                                                storeData[index] = editStoreData;
                                                this.setState({ storeData }, () => resolve());

                                                var options = {
                                                    place: 'tc',
                                                    message: " " + "Updated Successfully",
                                                    type: 'success',
                                                    icon: 'far fa-check-circle',
                                                    autoDismiss: 4
                                                }
                                                if (this.notification && this.notification.state.notifyTR.length < 1) {
                                                    this.notification.notificationAlert(options)
                                                }
                                            })
                                            .catch(error => {
                                                this.setState({ loading: false })
                                                const options = {
                                                    place: 'tc',
                                                    message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
                                                    type: 'danger',
                                                    icon: 'fas fa-exclamation-triangle',
                                                    autoDismiss: 4
                                                }
                                                if (this.notification && this.notification.state.notifyTR.length < 1) {
                                                    this.notification.notificationAlert(options)
                                                }
                                            })

                                    }
                                    resolve()
                                }, 1000)
                            }),
                    }}
                />
                <Dialog onClose={this.handleClose} open={this.state.open} aria-labelledby="simple-dialog-title">
                    <DialogTitle id="simple-dialog-title" style={{ textAlign: "center", marginBottom: "-20px" }}>Update Operating Hours</DialogTitle>
                    <DialogContent>
                        <UpdateOperatingHours storeId={this.state.storeId} submitClose={this.onSubmit} />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleCloseUploadDialog} open={this.state.openUploadDialog} maxWidth="md" aria-labelledby="simple-dialog-title">
                    <DialogContent>
                        <UploadImage submitClose={this.handleCloseUploadDialog} storeId={this.state.storeId} />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleCloseEditDialog} open={this.state.openEditDialog}  maxWidth="lg" aria-labelledby="simple-dialog-title">
                    <DialogTitle id="simple-dialog-title">Update store</DialogTitle>
                    <DialogContent>
                        <UpdateStore submitClose={this.handleCloseEditDialog} storeData={this.state.stores} />
                    </DialogContent>
                </Dialog>
                {/* ///////////Show Boxes////////// */}
                {this.state.drawerflag ? 
                <UpdateBox drawerflag={this.state.drawerflag} openBoxDialog={this.openBoxDialog} storeId={this.state.storeId} storeName={this.state.storeName} drawerdisplay={()=>this.drawerdisplay()}/>
                : "" }
            </div>

        );
    }

}
const mapStateToProps = state => ({ authorities: state.auth.authorities })
export default connect(mapStateToProps, null)(withStyles(style)(UpdateEditStore));
