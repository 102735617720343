import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { API_CONFIG } from "./../../config/config.jsx";
import Axios from 'axios';


const styles = theme => ({
    textField: {
        width: "150px"
    }
});
const google = window.google;

class UpdatePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: this.props.userAccountData.original.amount,
            interval: this.props.userAccountData.original.interval,
        };

    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    submit = event => {
        event.preventDefault();
        if (this.props.userAccountData.original.amount) {
            const token = sessionStorage.getItem('plugoAuthKey');
            const plan = {
                amount: this.state.amount,
                interval: this.state.interval
            }
            Axios.put(`${API_CONFIG.HOST}/accounts/${this.props.accountId}/plan`, plan, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })
                .then(res => {
                    this.props.submitClose();
                })
        }
        else {
            const plan = {
                amount: this.state.amount,
                interval: this.state.interval
            }
            const token = sessionStorage.getItem('plugoAuthKey');
            Axios.post(`${API_CONFIG.HOST}/accounts/${this.props.accountId}/plan`, plan, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })
                .then(res => {
                   this.props.submitClose();
                })
        }
    };

    render() {
        const { classes } = this.props;
        console.log(this.props.userAccountData);
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <form className={classes.container} onSubmit={this.submit}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-name"
                                        label="Amount"
                                        value={this.state.amount}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('amount')}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-address"
                                        label="Interval"
                                        value={this.state.interval}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange('interval')}
                                    />
                                </GridItem>
                            </GridContainer>
                            <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                                <Button type="submit" variant="contained" color="primary" onClick={this.handleNext} >
                                    Update
                                </Button>
                            </div>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

UpdatePlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpdatePlan);
