import React from 'react';
import PropTypes, { element } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import accountingIcon from "assets/img/accountingIcon.png";
import CustomerDefaultIcon from "assets/img/premiumUserIcon.png";
import increaseIcon from "assets/img/increaseIcon.png";
import RentalIcon from "assets/img/plugoboxImg.png";
import BoxInventoryReport from "./BoxInventoryReport.jsx";
import BoxStatusReport from "./BoxStatusReport/BoxStatusReport.jsx";
import RevenueReport from "./RevenueReport.jsx";
import InvoiceReport from './InvoiceReport/InvoiceReport.jsx'
import SalesLeadReport from "./SalesLeadReport.jsx";
// import SecurityDepositReport from "./SecurityDepositReport/SecurityDepositReport.jsx";
import IncomeReport from "./IncomeReport/IncomeReport.jsx";
import PowerBankTransactionReport from "./PowerBankTransactionReport/TransactionReportByCity.jsx";
import CustomerDefaultReport from "./CustomerDefaultReport/CustomerDefaultReport.jsx";
// import { User } from '../../User';
import Secure from '../../components/Secure.jsx';
import MerchantRevenueReport from './MerchantRevenueReport/MerchantRevenueReport';
import RentalHistory from './RentalHistory/RentalHistory.jsx';
import { connect } from "react-redux";

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  imgStyle: {
    width: 30,
    height: 35
  }
});

class ReportTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      europeflag: false,
      activetab: '',
      activetabbody: [],
      india: [
        {
          value: 0,
          secure: 'VIEW_RENTALS',
          component: 'PowerBankTransactionReport'
        },
        {
          value: 1,
          secure: 'VIEW_STORE_USER',
          component: 'IncomeReport'
        },
        {
          value: 2,
          secure: 'VIEW_BOX_STATUS',
          component: 'BoxStatusReport'
        },
        {
          value: 3,
          secure: 'VIEW_INVENTORY',
          component: 'BoxInventoryReport'
        },
        {
          value: 4,
          secure: 'VIEW_DEFAULT_CUSTOMER',
          component: 'CustomerDefaultReport'
        },
        {
          value: 5,
          secure: 'VIEW_REVENUE',
          component: 'RevenueReport'
        }
        // ,
        // {
        //   value:6,
        //   secure:'VIEW_BOX_STATUS',
        //   component:'PowerBankTransactionReport'
        // }
      ],
      permissions_tab: [
        {
          Security: 'Refresh',
          Label: 'Refresh',
          SRC: RentalIcon
        },
      ]
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    })
  }

  componentWillMount() {
    if (process.env.REACT_APP_ACTIVE_REGION == "europe") {
      this.setState({
        europeflag: true,
        permissions_tab: this.props.tab_permission.permissions_europe
      })
    }
    else {
      this.setState({
        europeflag: false,
        permissions_tab: this.props.tab_permission.permissions_india
      })
    }
  }

  componentDidMount() {
    const { classes } = this.props;

    let tabbody = this.state.india.map(elem => {
      { this.state.value === 0 && Secure("VIEW_RENTALS", <TabContainer><PowerBankTransactionReport /></TabContainer>) }
      return this.state.value === elem.value && Secure(elem.secure, <TabContainer><elem.component /></TabContainer>)
    })

    let tabs = this.state.permissions_tab.map((elem) => {

      return Secure(elem.Security, <Tab label={elem.Label} icon={<img className={classes.imgStyle} alt={elem.Label} src={elem.SRC}></img>} />)
    })

    this.setState({
      activetab: tabs,
      activetabbody: tabbody
    })


  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            {this.state.activetab}
          </Tabs>
        </AppBar>
        {this.state.value === 0 && Secure("VIEW_RENTALS", <TabContainer><PowerBankTransactionReport /></TabContainer>)}
        {/* {this.state.value === 1 && <TabContainer><SecurityDepositReport /></TabContainer>} */}
        {this.state.value === 1 && Secure("VIEW_STORE_USER", <TabContainer><IncomeReport /></TabContainer>)}
        {this.state.value === 2 && Secure("VIEW_BOX_STATUS", <TabContainer><BoxStatusReport /></TabContainer>)}
        {this.state.value === 3 && Secure("VIEW_INVENTORY", <TabContainer><BoxInventoryReport /></TabContainer>)}
        {this.state.value === 4 && Secure("VIEW_DEFAULT_CUSTOMER", <TabContainer><CustomerDefaultReport /></TabContainer>)}
        {this.state.europeflag ? (this.state.value === 5 && Secure("SUPER_ADMIN", <TabContainer><InvoiceReport /></TabContainer>)) : (this.state.value === 5 && Secure("VIEW_REVENUE", <TabContainer><RevenueReport /></TabContainer>))}
        {!this.state.europeflag ? (this.state.value === 6 && <TabContainer><MerchantRevenueReport /></TabContainer>) : (this.state.value === 6 && <TabContainer><RentalHistory /></TabContainer>)}
        {/* {this.state.value === 4 && <TabContainer><SalesLeadReport /></TabContainer>} */}
      </div>
    );
  }
}

ReportTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return ({
    tab_permission: state.tab_permission
  })
}

export default connect(
  mapStateToProps, null
)(withStyles(styles)(ReportTabs))