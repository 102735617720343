import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {dateFormat} from '../../../Constant.jsx';

var Moment = require("moment");

const styles = theme => ({
    divStyle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-end",
        fontSize: "12px"
    }
});
class UserTransaction extends React.Component {
    state = {
        showTransaction: false,
        showText: true
    };
    showTransaction = () => {
        this.setState({
            showTransaction: !this.state.showTransaction,
            showText: !this.state.showText
        })
    }
    getSign = (transactionType) => {
        if(transactionType === "SUBSCRIPTION" || transactionType === "DEBIT" || transactionType === "REFUND" || transactionType === "REFUND_SECURITY" || transactionType === "IGST" || transactionType === "CGST" || transactionType === "SGST" || transactionType === "UPGRADE_SUBSCRIPTION" || transactionType === "LOST_DEVICE_CHARGES"){
            return "-₹"
        }
        if(transactionType === "SECURITY"){
            return "₹"
        }
        return "+₹"
    }
    getTransactionSign = (transactionListType) => {

        if(transactionListType === "SUBSCRIPTION" || transactionListType === "DEBIT" || transactionListType === "REFUND" || transactionListType ==="REFUND_SECURITY" || transactionListType === "IGST" || transactionListType === "CGST" || transactionListType === "SGST" || transactionListType === "UPGRADE_SUBSCRIPTION" || transactionListType === "LOST_DEVICE_CHARGES"){
            return "-₹"
        }
        if(transactionListType === "SECURITY" ){
            return "₹"
        }
        return "+₹"
    }
    getColor = (transactionType) => {
        if(transactionType === "SUBSCRIPTION" || transactionType === "DEBIT" || transactionType === "REFUND"|| transactionType === "REFUND_SECURITY" || transactionType === "IGST" || transactionType === "CGST" || transactionType === "SGST" || transactionType === "UPGRADE_SUBSCRIPTION" || transactionType === "LOST_DEVICE_CHARGES"){
            return "red"
        }
        if(transactionType === "SECURITY"){
            return "gray"
        }
        return "green"
    }
    render() {
        const { classes, transaction } = this.props;
        return (
            <div>
                <div style={{
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px"
                }}>
                    <div>
                        <span>{Moment(transaction.createdDate).format(dateFormat)}</span>
                        <div><p style={{ fontSize: '12px' }}>{transaction.type}</p></div>
                    </div>
                    <div style={{width:"20%"}}>
                        <div style={{ color: this.getColor(transaction.type), fontWeight: 'bold', fontSize: '18px' }}>{this.getSign(transaction.type) + transaction.amount.toFixed(2)}</div>
                        {transaction.type !== "CREDIT" ?
                            <button style={{ display: 'block', borderRadius: "50px", border: '1px solid #eee', backgroundColor: '#28a745',color:"white" }} onClick={this.showTransaction}>{ this.state.showText ? 'More' : 'Less' }</button>
                            : ''}
                    </div>
                </div>
                {this.state.showTransaction ?
                    <ul>
                        {transaction.transactionList.map(txnList => (
                            <li style={{ listStyle: 'none', textAlign: 'right' }}><div className={classes.divStyle}><div style={{marginRight:"10px"}}>{txnList.description + ":"}</div><div>{this.getTransactionSign(txnList.type) + txnList.amount}</div></div></li>
                        ))}
                    </ul> : ''}
                <Divider />
            </div>
        );
    }
}

UserTransaction.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserTransaction);
