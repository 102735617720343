import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import NotificationAlert from "react-notification-alert";
import { API_CONFIG } from "../../config/config.jsx";
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import { Checkbox } from '@material-ui/core';
import { Button, Table, InputGroup, InputGroupAddon, InputGroupText, Input, FormGroup, Label, } from 'reactstrap';

const styles = theme => ({
    tableStyle: {
        overflowY: 'scroll',
        height: '300px',
        display: 'block',
        border: '1px solid #808080',
        marginTop: '3px'
    },
    thStyle: {
        width: '100px',
        position: 'fixed',
        background: 'white',
    },
    tdStyle: {
        width: '400px'
    }
});

class ViewMembers extends React.Component {
    state = {
        excelFile: '',
        member: '',
        memberType: '',
        memberShipType: false,
        members: []
    };
    componentDidMount() {
        this.getMembers();
    }
    getMembers = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/accounts/${this.props.accountId}/members`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(res => {
                console.log(res);
                this.setState({
                    members: res.data,
                });
            })
    }
    onChangeHandler = event => {
        console.log(event.target.files[0]);
        this.setState({
            excelFile: event.target.files[0]
        })
    }
    handleChange = (event) => {
        if (this.verifyMemberType(event.target.value)) {
            this.setState({ member: event.target.value, memberType: 'MOBILE' })
        }
        else {
            this.setState({ member: event.target.value, memberType: 'EMAIL' })
        }
    }
    verifyMemberType = (value) => {
        var mobileNoRex = /^\d{10}$/;
        if (mobileNoRex.test(value)) {
            return true;
        }
        return false;
    }
    onChangeTpyeHandler = () => {
        this.setState({
            memberShipType: !this.state.memberShipType
        })
    }
    handleAddMember = () => {
        const membershipType = this.state.memberShipType ? 'ACCOUNT_ADMIN' : 'ACCOUNT_MEMBER'
        const memberData = {
            member: this.state.member,
            memberType: this.state.memberType,
            membershipType: membershipType
        }
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.post(`${API_CONFIG.HOST}/accounts/${this.props.accountId}/member`, memberData, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                this.setState({
                    member: '',
                    memberShipType: false
                }, this.getMembers());
                var options = {
                    place: 'tc',
                    message: " " + "Added Successfully",
                    type: 'success',
                    icon: 'far fa-check-circle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            });
    }
    onSubmit = () => {
        const data = new FormData();
        data.append('file', this.state.excelFile)
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.post(`${API_CONFIG.HOST}/accounts/${this.props.accountId}/members/upload`, data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res);
                this.props.submitClose(res);
                this.setState({
                    excelFile: ''
                })
            })
            .catch(error => {
                this.setState({ loading: false })
                const options = {
                    place: 'tc',
                    message: `  ${error.response ? error.response.data : 'Something went wrong'}`,
                    type: 'danger',
                    icon: 'fas fa-exclamation-triangle',
                    autoDismiss: 4
                }
                if (this.notification && this.notification.state.notifyTR.length < 1) {
                    this.notification.notificationAlert(options)
                }
            });
    }
    render() {
        const { classes } = this.props;
        let memberData = this.state.members.map((row, index) => {
            return {
                member: row.member,
                memberShipType: row.membershipType,
                active: row.active,
                subscribed: row.isSubscribed,
                registered: row.isRegistered,
            }
        });
        return (
            <div style={{ minWidth: "900px" }}>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row", marginTop: "10px",marginBottom:"8px" }}>
                    <InputGroup style={{ width: "320px", }}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>+91</InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handleChange} value={this.state.member} placeholder="Enter Mobile or Email" />
                    </InputGroup>
                    <FormGroup check style={{ marginLeft: "15px" }}>
                        <Label check>
                            <Input type="checkbox" onChange={this.onChangeTpyeHandler} value={this.state.memberShipType} />{' '}
                              Admin
                         </Label>
                    </FormGroup>
                    <Button onClick={this.handleAddMember} style={{ marginLeft: "15px" }} variant="contained" color="primary" >Add</Button>
                </div>
                <ReactTable
                    data={memberData}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    columns={[
                        {
                            headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px', fontWeight: '500' },
                            style: { textAlign: "center" },
                            Header: "Member",
                            id: "member",
                            accessor: d => d.member,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["member"] }),
                            filterAll: true
                        },
                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                            style: { textAlign: "center" },
                            Header: "Membership Type",
                            id: "memberShipType",
                            accessor: d => d.memberShipType,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["memberShipType"] }),
                            filterAll: true
                        },
                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                            style: { textAlign: "center" },
                            Header: "Active",
                            id: "active",
                            accessor: d => d.active,
                            Cell: row => (
                                <Checkbox checked={row.original.active} color="primary" />),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["active"] }),
                            filterAll: true
                        },
                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                            style: { textAlign: "center" },
                            Header: "Subscribed",
                            id: "subscribed",
                            accessor: d => d.subscribed,
                            Cell: row => (
                                <Checkbox checked={row.original.subscribed} color="primary" />),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["subscribed"] }),
                            filterAll: true
                        },
                        {
                            headerStyle: { fontSize: '16px', paddingTop: '10px', fontWeight: '500' },
                            style: { textAlign: "center" },
                            Header: "Registered",
                            id: "registered",
                            accessor: d => d.registered,
                            Cell: row => (
                                <Checkbox checked={row.original.registered} color="primary" />),
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["registered"] }),
                            filterAll: true
                        }
                    ]}
                    defaultPageSize={5}
                    className="-striped -highlight"
                />
                {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                {/* <Table className={classes.tableStyle}>
                        <tbody>
                            {this.state.members.map(d =>
                                <tr>
                                    <td className={classes.tdStyle} key={d.id}>{d.member}</td>
                                </tr>
                            )}

                        </tbody>
                    </Table> */}
                <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                    <input type="file" name="file" onChange={this.onChangeHandler} />
                    <Button onClick={this.onSubmit} variant="contained" color="primary" >Add Member</Button>
                </div>
                {/* </div> */}
            </div>
        );
    }

}
ViewMembers.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewMembers);