import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { Input, Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import moment from 'moment';

class DateRanges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ' To ',
            open: false,
            startDate: new Date(),
            endDate: new Date(),
            showDefaultReport: false,
            openPopOver: false,
            dateValue: 'Select Date Range',
        }
    }
    handleSelect = (ranges) => {
        console.log(ranges.selection);
        this.setState({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            dateValue: moment(ranges.selection.startDate).format('MMM-DD-YYYY') + this.state.text + moment(ranges.selection.endDate).format('MMM-DD-YYYY')
        });
    }
    handleClick = (event) => {
        this.setState({
            open: !this.state.open,
            openPopOver: event.currentTarget
        });
    }
    handleClose = () => {
        this.setState({
            openPopOver: false
        })
    };
    handleClearAll = () => {
        this.setState({
            open: !this.state.open,
            startDate: new Date(),
            endDate: new Date(),
            dateValue: 'Select Date Range'
        })
        this.props.showDefaultReport(this.state.showDefaultReport)
    }
    handleStandardReport = () => {
        this.setState({
            openPopOver: false
        })
        const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        this.props.fetchStandardReportData(startDate, endDate, true);
    }
    render() {
        const { classes } = this.props;
        // const minMonth = moment(this.state.startDate).month()+1
        const selectionRange = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            key: 'selection',
        }
        return (
            <div style={{ marginBottom: "10px" }}>
                <InputGroup style={{ width: "50%" }}>
                    <Input
                        value={this.state.dateValue}
                        onClick={this.handleClick}
                        autoComplete="off"
                        placeholder="Select Date Range"
                    />
                    <InputGroupAddon addonType="append">
                        <InputGroupText><i className='fa fa-times' onClick={this.handleClearAll} title="Clear All" style={{ marginRight: "10px", color: "red", fontSize: "larger", cursor: "pointer" }}></i></InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
                <Popover
                    open={this.state.openPopOver}
                    // anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div>
                        <DateRange
                            editableDateInputs={true}
                            onChange={this.handleSelect}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            minDate={moment().subtract(2, 'months').startOf('month').toDate()}
                            direction="horizontal"
                            ranges={[selectionRange]}
                        />
                    </div>
                    <div>
                        <Button variant="contained" color="primary" style={{ float: "right", margin: "2px 10px" }} onClick={this.handleStandardReport}>Apply</Button>
                    </div>
                </Popover>
            </div>
        );
    }

}

export default DateRanges
