import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotificationAlert from "react-notification-alert";
import Icon from "@material-ui/core/Icon";
import UserIcon from "assets/img/userLogo.png";
import Divider from '@material-ui/core/Divider';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Typography from '@material-ui/core/Typography';
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import UserTransaction from "./UserTransaction.jsx";
import RentalHistory from "./RentalHistory.jsx";
import UserCoupons from "./UserCoupons.jsx"
import UserActiveRental from "./UserActiveRental.jsx";
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from 'axios';
import AsyncSelect from 'react-select/async';
import { Label } from 'reactstrap';

var Moment = require("moment");
const imgStyle = {
    width: 50,
    height: 45
};
const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "0px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardHeaderStyle: {
        zIndex: "0 !important"
    },
    selectStyle: {
        width: 400
    },
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    divStyle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-around"
    },
    cardCategory: {
        marginBottom: "2px"
    }
});
class SearchUser extends React.Component {
    state = {
        storeId: '',
        userId: '',
        userProfileData: {},
        transactionData: [],
        rentalHistory: [],
        userCoupons: [],
        userWallets: [],
        userActiveSession: [],
        userActiveData: [],
        userPlan: [],
        userWalletBalance: '',
        showTransaction: false,
        userType: null,
    };
    onChangeSearchText = (inputValue) => {
        this.setState({
            searchText: inputValue
        })
    };
    showTransaction = () => {
        this.setState({
            showTransaction: !this.state.showTransaction
        })
    }
    userHandleChange = (event) => {
        console.log(event.value);
        this.setState({
            userId: event.value,
            userProfileData: event
        });
        this.getUserTransaction(event.value);
        this.getUserActiveSession(event.value);
        this.getUserRentals(event.value);
        this.getUserCoupons(event.value);
        this.getUserWallet(event.value);
        this.getUserPlans(event.value);
    }
    userLoadOptions = (inputValue) => {
        return this.getUserData(inputValue);
    };
    getUserData = async (inputValue) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        const result = await Axios.get(`${API_CONFIG.HOST}/users/search?mobile=${inputValue}&lastName=${inputValue}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-USER-ID": userId
            }
        });
        const mobileNo = sessionStorage.getItem('plugoUserMobileNo');
        // if(mobileNo.startsWith('+31')){
        //     const data = result.data.filter(d => {
        //         return d.pincode === '4431 NK'
        //     })
        //     result= data
        // }
        if(mobileNo.startsWith('+31')){
            const data = await result.data.filter(d => {
                return d.mobile.startsWith('+31')
            });
            result['data'] = data
        }
        
        return result.data.map(data => ({
            value: data.id,
            label: data.mobile + " - " + data.firstName,
            firstName: data.firstName,
            mobile: data.mobile,
            email: data.email,
            referralCode: data.referralCode
        }));
    }
    getUserTransaction = (userId) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        // const ui = "b302cd5b-dddf-4a0a-8916-d13bfb927ab2"
        Axios.get(`${API_CONFIG.HOST}/users/${userId}/transactions/consolidated`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                const removedList = res.data.splice(5);
                this.setState({
                    transactionData: res.data
                });

            })
            .catch(error => {
                this.setState({
                    transactionData: []
                })
            })
    }
    getUserRentals = (userId) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/rentals/history?userId=${userId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                const removedList = res.data.content.splice(5);
                this.setState({
                    rentalHistory: [...res.data.content]
                });

            });
    }
    getUserCoupons = (userId) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/coupons/valid?userId=${userId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                this.setState({
                    userCoupons: res.data
                });

            });
    }
    getUserWallet = (userId) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/users/${userId}/wallets`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                const userWalletBalance = res.data.totalCredit - res.data.totalDebit + res.data.totalDiscount
                const fixedVlue = userWalletBalance.toFixed(2)
                this.setState({
                    userWalletBalance: fixedVlue,
                    userWallets: res.data,
                    userType: res.data.subscribedUser ? "Subscribed" : "Standard"
                });

            });
    }
    getUserActiveSession = (userId) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/rentals/active?userId=${userId}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                console.log(res.data);
                if(res.data.length > 0)
                {
                    
                }
                this.setState({
                    userActiveData: res.data
                });

            });
    }
    getUserPlans = (userId) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        Axios.get(`${API_CONFIG.HOST}/users/${userId}/subscriptions`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                console.log(res);
                this.setState({
                    userPlan: res.data
                });

            });
    }
    render() {
        console.log(this.state.userId)
        const { classes } = this.props;
        return (
            <div>
                <div className="rna-container">
                    <NotificationAlert ref={(c) => { this.notification = c }} />
                </div>
                <Card>
                    <CardHeader color="success">
                        <h4 className={classes.cardTitleWhite}>User View</h4>
                    </CardHeader>
                    <CardBody>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.userLoadOptions}
                            onChange={this.userHandleChange}
                            defaultOptions
                            placeholder="Select User..."
                            className={classes.selectStyle}
                            onInputChange={this.onChangeSearchText}
                        />
                        <br />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <Card>
                                    <CardHeader color="warning" stats icon className={classes.cardHeaderStyle}>
                                        <CardIcon color="danger" style={{ borderRadius: "50px", marginLeft: "40%" }}>
                                            <img src={UserIcon} style={imgStyle} alt="Total User"></img>
                                        </CardIcon>
                                    </CardHeader>
                                    <CardBody>
                                        <p className={classes.cardCategory}>Plan Name: &nbsp; <span style={{ fontWeight: "bold", color: "green" }}>{this.state.userType}</span></p>
                                        <p className={classes.cardCategory}>User Name: &nbsp; {this.state.userProfileData.firstName}</p>
                                        <p className={classes.cardCategory}>Mobile No: &nbsp; {this.state.userProfileData.mobile}</p>
                                        <p className={classes.cardCategory}>Email: &nbsp; {this.state.userProfileData.email}</p>
                                        <p className={classes.cardCategory}>Referral Code: &nbsp; {this.state.userProfileData.referralCode}</p>
                                    </CardBody>
                                </Card>
                                <div>
                                    <Card>
                                        <CardHeader color="warning" style={{ height: "50px" }} className={classes.cardHeaderStyle}>
                                            <h5 style={{ color: "black", fontWeight: "400" }}>Wallet Balance</h5>
                                        </CardHeader>
                                        <CardBody>
                                            {/* <Typography variant="h5" component="h5" style={{ fontSize: "19px" }}> Wallet Balance</Typography> */}
                                            {/* <label style={{ textAlign: "center", fontSize: "15px" }}>{this.state.userType}</label> */}
                                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "-9px" }}>
                                                <p>Security Deposit:&nbsp;&nbsp;</p><p style={{ fontWeight: "bold" }}>{this.state.userWallets.totalSecurityDeposit}</p>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "-9px" }}>
                                                <p>Account Balance:&nbsp;&nbsp;</p><p style={{ fontWeight: "bold" }}>{this.state.userWalletBalance}</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={5}>
                                <Card>
                                    <CardHeader color="warning" style={{ height: "50px" }} className={classes.cardHeaderStyle}>
                                        <h5 style={{ color: "black", fontWeight: "400" }}>Transaction History</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            {this.state.transactionData.map(transaction => (
                                                <UserTransaction transaction={transaction} />
                                            )
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card>
                                    <CardHeader color="warning" style={{ height: "50px" }} >
                                             <div className = "d-flex row justify-content-between ml-2 ">      
                                                <h4 style={{ color: "black", fontWeight: "400" }} >Rental History</h4>
                                            </div>     
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            {this.state.userActiveData.length !== 0 ?
                                                <UserActiveRental userId={this.state.userId} userActiveData={this.state.userActiveData} />
                                                : ""}
                                            {this.state.rentalHistory.map((rentalUserHistory, i) => (
                                                <RentalHistory key={i} rentalUserHistory={rentalUserHistory} userId={this.state.userId}/>
                                            )
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                                <Card>
                                    <CardHeader color="warning" style={{ height: "50px" }}>
                                        <h5 style={{ color: "black", fontWeight: "400" }}>Coupons</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <UserCoupons userCoupons={this.state.userCoupons} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={5}>
                            {process.env.REACT_APP_ACTIVE_REGION !== "europe" ? 
                                <Card>
                                    <CardHeader color="warning" style={{ height: "50px" }}>
                                        <h5 style={{ color: "black", fontWeight: "400" }}>Plan Detail</h5>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.userPlan.length !== 0 ?
                                            <div style={{textAlign:"center"}}>
                                                <div>{this.state.userPlan[0].plan.name}</div>
                                                <div style={{color:"green",fontWeight:"bold"}}>{"₹" + this.state.userPlan[0].plan.amount}</div>
                                                <div>Valid up to {Moment(this.state.userPlan[0].endAt).format('LL')}</div>
                                            </div>
                                            : ""}
                                    </CardBody>
                                </Card>
                                : ""}
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

SearchUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchUser);
